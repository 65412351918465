import {find} from "lodash";

export const PRODUCTS = [
    {
        id: 'RESERVE',
        name: '우연한 디너 예약금',
        price: 10000,
    },
    {
        id: 'ADD-FORK',
        name: '우연한 디너 1회 참석권',
        price: 40000,
    },
    {
        id: 'ADD-3FORK',
        name: '우연한 디너 3회 참석권',
        price: 75000,
    },
    {
        id: 'ADD-12FORK',
        name: '우연한 디너 12회 참석권',
        price: 240000,
    },
    {
        id: 'ADD-FORK-BETA',
        name: '우연한 디너 1회 참석권',
        price: 20000,
    },
    {
        id: 'ADD-3FORK-BETA',
        name: '우연한 디너 3회 참석권',
        price: 35000,
    },
    {
        id: 'ADD-12FORK-BETA',
        name: '우연한 디너 12회 참석권',
        price: 120000,
    },
];

export const getProduct = (productId) => {
    return find(PRODUCTS, p => p.id === productId);
}
