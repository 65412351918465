import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "moment/locale/ko";
import queryString from "query-string";
import {Drawer} from "@mui/material";
import {Header} from "../../layout/Header";
import {filter, shuffle} from "lodash";
import {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_PARTY_DETAIL, GET_QUESTION} from "../../query/partyQuery";
import {flatEntity} from "../../toolbox/query";

export const QuestionPage = ({}) => {
    const location = useLocation();
    const [questions, setQuestions] = useState(null);
    const [phase, setPhase] = useState(0);
    const [questionCount, setQuestionCount] = useState(0);
    const [questionList, setQuestionList] = useState(null);

    const {data, refetch} = useQuery(GET_QUESTION, {});

    useEffect(() => {
        if (data?.article?.data) {
            const payload = flatEntity(data.article.data);
            let questions = payload.text.split('\n');
            questions = filter(questions, q => q.trim());
            setQuestionList(questions.map(q => q.trim()));
        }
    }, [data]);

    useEffect(() => {
        if (questionList) {
            setQuestions(shuffle(questionList));
        }
    }, [phase, questionList]);

    return <Drawer open={true} PaperProps={{
        style: {
            maxHeight: '100%',
            height: '100%',
        },
    }}>
        <PageWrap>
            <Header fullscreen={true} />
            {phase === 0 && <>
                <div className="page">
                    <h1 className="title">질문으로 만들어가는 이야기</h1>
                    <p className="description">
                        서로의 이야기를 나누며 더 가까워져요!<br/>가벼운 질문들부터 시작해, 마음을 여는 대화를 이어가 보세요!<br/>준비되셨나요? 그럼 시작해 볼까요?
                    </p>
                    <div className="btn" onClick={() => setPhase(1)}>시작하기</div>
                </div>
            </>}
            {questions && <>
                {phase === 1 && <>
                    {questions[questionCount] ? <>

                            <div className="page">
                                <h2 className="question">{questions[questionCount]}</h2>
                                <div style={{display: 'flex', gap: '12px'}}>
                                    {questionCount > 0 && <div className="btn" onClick={() => setQuestionCount(questionCount - 1)}>이전 질문</div>}
                                    <div className="btn" onClick={() => setQuestionCount(questionCount + 1)}>다음 질문</div>
                                </div>
                            </div>
                        </>:
                        <>
                            <div className="page">
                                <h2 className="end-message">모든 질문을 다 봤어요!</h2>
                                <div style={{display: 'flex', gap: '12px'}}>
                                    {questionCount > 0 && <div className="btn" onClick={() => setQuestionCount(questionCount - 1)}>이전 질문</div>}
                                    <div className="btn" onClick={() => {
                                        setQuestions(shuffle(questionList));
                                        setQuestionCount(0);
                                        setPhase(0)
                                    }}>처음으로</div>
                                </div>
                            </div>
                        </>}
                </>}
            </>}
        </PageWrap>
    </Drawer>
}
const PageWrap = styled.div`
  box-sizing: border-box;
  font-family: "Elice Digital Baeum", sans-serif;
  height: 100%;
  width: 100vw;
  position: relative;
  //height: 100vh;
  
  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
    word-break: keep-all;
  };

  .title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;

    //@media only screen and (max-width: 767px) {
    //  font-size: 2.5rem;
    //}
  }

  .description {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #666;
  }

  .question {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 30px;
    color: #333;
  }

  .end-message {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
  }

  .btn {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .btn:hover {
    background-color: #45a049;
  }
  
`