import styled from "styled-components";

export const MyButton = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s ease;
  text-align: center;

  &:hover {
    background: #2980b9;
  }
`;


export const PrimaryButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border: none;
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`