import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {filter, find, isEmpty, sortBy} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {commaNum, genderToString} from "../../toolbox/format";
import moment from "moment-timezone";
import {authAction} from "../../redux/authReducer";
import {userAction} from "../../redux/userReducer";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {parseJwt} from "../../toolbox/jwt";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {GET_PARTY_DETAIL, GET_RESTAURANTS} from "../../query/partyQuery";
import {flatEntity} from "../../toolbox/query";
import {getUpcomingMeet, getNextMeetTimes} from "../../toolbox/time";
import {UserProfile} from "../components/UserProfile";
import {Party, PartyReq} from "../components/Party";
import {InviteDialog} from "./InviteDialog";
import {getSubscription, getSubscriptionCoupon} from "../../toolbox/entity";
import qs from "query-string";


// const RESTAURANTS = ["라브리크 서울", "아올 강남", "띠엘로", "중식당표량", "후추포인트 강남"]

export const PartyListPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;

    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [updateUser] = useMutation(UPDATE_USER);
    const [restaurants, setRestaurants] = useState(null);
    const [invite, setInvite] = useState(false);

    const {data: restaurantsData, refetch} = useQuery(GET_RESTAURANTS, {
        fetchPolicy: "cache-first",
    });

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (parsed && parsed.invite) {
            setInvite(true);
        }
    }, []);

    useEffect(() => {
        if (restaurantsData?.restaurants?.data) {
            const payload = flatEntity(restaurantsData?.restaurants.data);
            setRestaurants(payload);
        }
    }, [restaurantsData]);


    let partyCount = 0;
    if (userInfo?.tickets) {
        partyCount = filter(userInfo.tickets, t => !t.member).length;
    }

    const getPastMeet = (partyMembers) => {
        return sortBy(filter(partyMembers, pm => !pm.party.building && moment.tz(pm.party.meetedAt, 'Asia/Seoul').add(2, 'hours') < moment.tz('Asia/Seoul')).map(pm => ({partyMember: pm, party: pm.party})), p => p.party.meetedAt).reverse();
    }

    const getFutureMeet = (partyMembers) => {
        return sortBy(filter(partyMembers, pm => moment.tz(pm.party.meetedAt, 'Asia/Seoul').add(2, 'hours') >= moment.tz('Asia/Seoul')).map(pm => ({partyMember: pm, party: pm.party})), p => p.party.meetedAt).reverse();
    }

    const getBuildingMeet = (partyMembers) => {
        return sortBy(filter(partyMembers, pm => pm.party.building).map(pm => ({partyMember: pm, party: pm.party})), p => p.party.meetedAt).reverse();
    }

    return (
        <Container>
            {/*<TabContainer>*/}
            {/*    <Tab isActive={activeTab === 'public'} onClick={() => setActiveTab('public')}>*/}
            {/*        공개 프로필 {activeTab === 'public'? "":"보기"}*/}
            {/*    </Tab>*/}
            {/*    <Tab isDisable={privateDisable} isActive={activeTab === 'private'} onClick={() => !privateDisable && setActiveTab('private')}>*/}
            {/*        선택 공개 프로필 {activeTab === 'private'? "":"보기"}*/}
            {/*    </Tab>*/}
            {/*</TabContainer>*/}
            {/*<ProfileSection>*/}
            {/*    <ProfileImage src={userInfo?.profileImage?.url} alt="Profile" />*/}
            {/*    <ProfileInfo>*/}
            {/*        <Nickname>{userInfo?.nickname}</Nickname>*/}
            {/*        <Gender>성별: {userInfo?.gender? genderToString(userInfo.gender): '미설정'}</Gender>*/}
            {/*        <PhoneNumber>연락처: {userInfo?.phone? userInfo?.phone: '미설정'}</PhoneNumber>*/}
            {/*        <SNSLink>SNS정보: {userInfo?.snsLink? userInfo?.snsLink: '미설정'}</SNSLink>*/}
            {/*        {userInfo?.profile?.소개 && <Bio>*/}
            {/*            <p>{userInfo?.profile?.소개}</p>*/}
            {/*        </Bio>}*/}
            {/*        <MembershipInfo>남은 디너 매칭 : {userInfo?.partyCount}회</MembershipInfo>*/}
            {/*        <Link to="/purchase" className="purchase-button">디너 횟수 추가하기</Link>*/}
            {/*        /!*<Bio>*!/*/}
            {/*        /!*    소개*!/*/}
            {/*        /!*    <p>{userInfo?.profile?.소개 ? userInfo?.profile?.소개:'내용없음'}</p>*!/*/}
            {/*        /!*</Bio>*!/*/}
            {/*    </ProfileInfo>*/}
            {/*    <Link to="/profile_edit">프로필 수정</Link>*/}
            {/*</ProfileSection>*/}
            <MeetingsSection>


                {/*<div style={{marginBottom: '24px'}}>*/}
                {/*    모임 참석자들이 만남 전 기대감과 호기심을 가질 수 있게 적어주시면 좋습니다.*/}
                {/*</div>*/}
                <div style={{textAlign: 'center', marginBottom: '32px'}}>
                    {userInfo?.id && <UserProfile u={userInfo} />}
                </div>
                <MeetingTitle>예정된 디너모임</MeetingTitle>
                <MeetingList>
                    {getFutureMeet(userInfo?.partyMembers).length === 0 && <><div>
                        예정된 모임이 없습니다.
                    </div>
                        <Line style={{marginTop: '16px'}} />
                    </>}
                    {getFutureMeet(userInfo?.partyMembers).map(p => <>
                        <Party p={p.party} m={p.partyMember} />
                    </>)}
                </MeetingList>
                <MeetingTitle>완료된 디너모임</MeetingTitle>
                <MeetingList>
                    {getPastMeet(userInfo?.partyMembers).length === 0 && <div>
                        완료된 모임이 없습니다.
                    </div>}
                    {getPastMeet(userInfo?.partyMembers).map(p => <>
                        <Party p={p.party} m={p.partyMember} />
                    </>)}
                </MeetingList>
            </MeetingsSection>
            {/*<LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>*/}
            <InviteDialog open={invite} onClose={() => setInvite(false)} />
        </Container>
    );
};

const Line = styled.div`
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
`
// Styled-Components를 사용하여 스타일 정의
const Container = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  color: #333;
  min-height: 100vh;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background-color: ${({ isDisable, isActive }) => (isDisable? '#cccccc': isActive ? '#007bff' : '#fff')};
  color: ${({ isDisable, isActive }) => (isDisable? '#999999': isActive ? '#fff' : '#000')};
  border: ${({ isDisable }) => (isDisable? '#999999': '1px solid #007bff')};
  cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ isDisable, isActive }) => (isDisable? '': isActive ? '#0056b3' : '#e0e0e0')};
  }
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
  
  > :last-child {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
      background-color: #0056b3;
    }

    @media only screen and (min-width: 768px) {
      margin-top: 0;
      margin-left: auto;
    }
  }
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const Bio = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 8px;
  p {
    margin: 0;
    white-space: pre-wrap; /* 여러 줄의 텍스트를 지원하기 위해 추가 */
  }
`;

const ProfileInfo = styled.div`
  text-align: center;

  @media only screen and (min-width: 768px) {
    text-align: left;
  }
`;

const Nickname = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Gender = styled.p`
  font-size: 1rem;
`;

const PhoneNumber = styled.p`
  font-size: 1rem;
`;

const SNSLink = styled.p`
  font-size: 1rem;
`;

const MembershipInfo = styled.p`
  font-size: 1rem;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  > .purchase-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    border: none;
    padding: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

const MeetingsSection = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 440px;



  > .purchase-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #218838;
    }
  }
`;

const LogoutButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; 

  &:hover {
    background-color: #c82333;
  }
`;

const MeetingTitle = styled.h2`
  margin-top: 24px;
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const MeetingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MeetingItem = styled.div`
  font-size: 1rem;
  padding: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #007bff;
  }

  //&:last-child {
  //  border-bottom: none;
  //}
`;
