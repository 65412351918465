import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import {useEffect} from "react";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {useDispatch, useSelector} from "react-redux";
import {paymentIdToOrderId} from "../../toolbox/format";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {useMutation} from "@apollo/client";
import {isEmpty} from "lodash";
import {loadingAction} from "../../redux/loadingReducer";

export const PaymentSuccessPage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const paymentKey = parsed.paymentKey;
    const orderId = parsed.orderId;
    const amount = parsed.amount;
    const param = parsed.param;

    const [updateUser] = useMutation(UPDATE_USER);
    useEffect(() => {
        if (!paymentKey) {
            return;
        }
        dispatch(loadingAction.loading(true));
        axios.post(SERVER_ADDRESS + '/api/tosses/execute', {
            paymentKey: paymentKey,
            amount: amount,
            orderId: orderId,
            param: param? JSON.parse(param): null,
        }).then((res) => {
            if (res.data === 'DONE') {
                const splitted = orderId.split('_');
                const productId = splitted[1];
                if (productId === 'RESERVE') {
                    window.fbq('track', 'Schedule');
                }
                window.fbq('track', 'Purchase', {content_name: productId, value: Number(amount), currency: 'KRW'});
                window.fbq('trackCustom', productId, {value: Number(amount), currency: 'KRW'});
                if (!isEmpty(tokenInfo)) {
                    const payload = {
                        refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                        variables: {
                            id: tokenInfo.id,
                            data: {
                                // autoEnter: userInfo.autoEnter,
                            }
                        }
                    };
                    updateUser(payload).then(() => {
                        dispatch(loadingAction.loading(false));
                        navigate('/my');
                    });
                } else {
                    dispatch(loadingAction.loading(false));
                    navigate('/my');
                }
            } else {
                dispatch(loadingAction.loading(false));
                alert(JSON.stringify(res.data))
                navigate('/my');
            }
        });
    }, [paymentKey]);

    return <_PaymentSuccessPage>
        요청을 처리하는 중 입니다.
    </_PaymentSuccessPage>
}

const _PaymentSuccessPage = styled.div`
  text-align: center;
  padding: 20px;
`
