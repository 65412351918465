import {filter, find, sortBy} from "lodash";
import moment from "moment-timezone";

export const getSubscription = (userInfo) => {
    if (!userInfo) {
        return null;
    }
    let nowMoment = moment.tz('Asia/Seoul');
    let now = nowMoment.format('YYYY-MM-DD');
    let subscription = find(userInfo.subscriptions, (s) => now >= s.startDate && now <= s.endDate);
    if (subscription) {
        return subscription;
    }
    return null;
};

export const filterAvailableCoupon = (userInfo) => {
    if (!userInfo) {
        return [];
    }
    let nowMoment = moment.tz('Asia/Seoul');
    let now = nowMoment.format('YYYY-MM-DD');
    let coupons = filter(userInfo.coupons, (c) => moment.tz(c.expiresAt? c.expiresAt: nowMoment.clone().add(100, 'year'), 'Asia/Seoul') >= nowMoment);
    coupons = filter(coupons, (c) => !c.used);
    return coupons;
}

export const getPartyTicketCoupon = (userInfo) => {
    let coupons = filterAvailableCoupon(userInfo);
    coupons = filter(coupons, (c) => c.couponType === 'PARTY_TICKET');
    if (coupons.length === 0) {
        return null;
    }
    let sorted = sortBy(coupons, (c) => c.expiresAt? moment.tz(c.expiresAt, 'Asia/Seoul'): moment.tz('Asia/Seoul').add(100, 'years'));
    return sorted[0];
}

export const getSubscriptionCoupon = (userInfo) => {
    let coupons = filterAvailableCoupon(userInfo);
    coupons = filter(coupons, (c) => c.couponType === 'SUBSCRIPTION' || c.couponType === 'PARTY_TICKET');
    if (coupons.length === 0) {
        return null;
    }
    let sorted = sortBy(coupons, (c) => c.expiresAt? moment.tz(c.expiresAt, 'Asia/Seoul'): moment.tz('Asia/Seoul').add(100, 'years'));
    return sorted[0];
}

export const getSubscriptionStartCoupon = (userInfo) => {
    let coupons = filterAvailableCoupon(userInfo);
    coupons = filter(coupons, (c) => c.couponType === 'SUBSCRIPTION');
    if (coupons.length === 0) {
        return null;
    }
    let sorted = sortBy(coupons, (c) => c.expiresAt? moment.tz(c.expiresAt, 'Asia/Seoul'): moment.tz('Asia/Seoul').add(100, 'years'));
    return sorted[0];
}
