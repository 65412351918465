import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import {useEffect} from "react";

export const CardFailPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;

    useEffect(() => {
        if (parsed.message) {
            alert(parsed.message);
            navigate(-1);
        }
    }, [parsed])

    return <_CardFailPage>
        요청을 처리하는 중 입니다.
    </_CardFailPage>
}

const _CardFailPage = styled.div`
  text-align: center;
  padding: 20px;
`
