import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    storage: {},
};

export const sessionGuardSlice = createSlice({
    name: 'sessionGuard',
    initialState,
    reducers: {
        update: (state, action) => {
            state.storage[action.payload.key] = action.payload.value;
            return state;
        },
        delete: (state, action) => {
            delete state.storage[action.payload.key];
            return state;
        }
    }
})

export let sessionGuardAction = sessionGuardSlice.actions;
export let sessionGuardReducer = sessionGuardSlice.reducer;

