import {loadTossPayments} from "@tosspayments/payment-sdk";
import {SERVER_ADDRESS, TOSS_CLIENT_KEY, TOSS_SUB_CLIENT_KEY} from "../../index";
import { v4 } from 'uuid';
import {getProduct} from "../../toolbox/product";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {isEmpty} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import {getSubscription, getSubscriptionCoupon} from "../../toolbox/entity";
import axios from "axios";
import {loadingAction} from "../../redux/loadingReducer";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {useMutation} from "@apollo/client";
import {PrimaryButton} from "../components/CommonComponents";

export const SubscriptionPage = ({}) => {
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [agree, setAgree] = useState(false);
    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            alert("로그인이 필요합니다.")
            const to = getLoginLink(location);
            navigate(to, {replace: true});
        }
    }, [tokenInfo]);

    async function onClickAddCard() {
        if (!isEmpty(tokenInfo)) {
            let prev = location.pathname + "?" + encodeURIComponent(location.search.substr(1)) + encodeURIComponent(location.hash);
            const tossPayments = await loadTossPayments(TOSS_SUB_CLIENT_KEY);
            let customerKey = String(tokenInfo.id);
            if (customerKey.length === 1) {
                customerKey = `0${customerKey}`;
            }
            await tossPayments.requestBillingAuth('카드', {
                customerKey,
                successUrl: window.location.origin + '/card_success' + `?returnUrl=${prev}`,
                failUrl: window.location.origin + '/card_fail',
            }).then((value) => {
                console.log(value);
            }).catch((reason => {
                console.log(reason);
            }));
        } else {
            console.log('로그인 정보가 없습니다');
        }
    }

    async function handlePayment(productId) {
        if (!agree) {
            alert('주문 내용에 동의해야합니다.')
            return;
        }
        let subscription = getSubscription(userInfo);
        if (subscription) {
            alert('이미 구독이 되어있습니다.');
            return;
        }
        if (!userInfo.card) {
            alert('결제 카드를 먼저 등록해야합니다.');
            await onClickAddCard();
            return;
        }

        let coupon = getSubscriptionCoupon(userInfo);
        if (coupon && window.confirm(`사용 가능한 쿠폰이 있습니다. 사용하시겠습니까?\n(${coupon.name})`)) {
            dispatch(loadingAction.loading(true));
            const response = await axios.post(SERVER_ADDRESS + '/api/coupons/useCoupon', {
                couponId: coupon.id
            });
            if (response.data.result === 'success') {
                const payload = {
                    refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                    variables: {
                        id: tokenInfo.id,
                        data: {
                            // autoEnter: userInfo.autoEnter,
                        }
                    }
                };
                updateUser(payload).then(() => {
                    alert(response.data.msg);
                    dispatch(loadingAction.loading(false));
                    navigate('/my');
                });
            } else {
                dispatch(loadingAction.loading(false));
            }
            return;
        }
        if (window.confirm('20,000원이 결제됩니다. 계속하시겠습니까?')) {
            dispatch(loadingAction.loading(true));
            const response = await axios.post(SERVER_ADDRESS + '/api/subscriptions/startSubscription', {});
            if (response.data.result === 'success') {
                const payload = {
                    refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                    variables: {
                        id: tokenInfo.id,
                        data: {
                            // autoEnter: userInfo.autoEnter,
                        }
                    }
                };
                updateUser(payload).then(() => {
                    alert(response.data.msg);
                    dispatch(loadingAction.loading(false));
                    navigate('/my');
                });
            } else {
                dispatch(loadingAction.loading(false));
            }
        }
    }

    return (
        <Container>
            {/*<Header>우연한 디너 멤버십</Header>*/}
            {/*<p>우연한 디너모임에 참석하는 티켓은 구독 기간 30일마다 1개씩 지급되며, 식대는 각자 주문한 메뉴를 현장에서 더치페이로 결제합니다.</p>*/}
            {/*<p style={{marginTop: '-8px'}}>(메뉴 당 2만원 내외, 주류 포함 시 증가할 수 있음.)</p>*/}
            {/*<p>디너모임은 매주 목요일 오후 7시 강남역 인근 식당에서 4~6인으로 진행됩니다.</p>*/}
            {/*<div>*/}
            {/*    <Link to="/profile_edit?type=public">*/}
            {/*        <img src="/Group 29.png" />*/}
            {/*    </Link>*/}
            {/*</div>*/}
            <PlanCard>
                <div className="header">
                    <div>우연한 디너 멤버십 (30일)</div>
                    <div>20,000원</div>
                </div>
                <div className="description">
                    <p>· 가입 즉시 디너 참석권 1개가 지급</p>
                    <p>· 가입 이후 30일이 지날때마다 디너 참석권 1개 지급</p>
                    <p>· 멤버십 기간 동안 참석권으로 참석 신청 가능</p>
                </div>
            </PlanCard>

            <div>
                <input type="checkbox" onChange={(e) => {
                    setAgree(e.target.checked);
                }} /> 주문 내용과 구독 안내 내용에 동의합니다.
            </div>
            <div className="desc">
                <h3>멤버십 결제 안내</h3>
                <p>· 가입 즉시 모임 참가 신청이 가능합니다.</p>
                <p>· 기간이 종료되기전에 등록된 결제 수단으로 자동 결제되는 정기구독 상품입니다.</p>
                <p>· 정기 구독 해지는 마이 페이지 > 구독 정보에서 가능합니다.</p>
                <p>· 기타 문의는 화면 우측 하단의 톡상담을 이용해주세요.</p>
            </div>
            <PrimaryButton onClick={handlePayment}>결제하기</PrimaryButton>
        </Container>
    );
}

const Container = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  color: ${({ theme }) => theme.colors.textColor};
  height: 100vh;
  gap: 24px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  
  > .desc {
    color: rgb(153, 153, 153);
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 14px;
  }
`;

const PlanCard = styled.div`
  width: 100%;
  // background-color: ${({ theme }) => theme.colors.lightGray};
  // border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 8px;
  //padding: 20px;
  margin: 10px;

  .header {
    font-size: 18px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    color: white;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  
  .description {
    padding: 10px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.darkGray};
  }
  
  //&:hover {
  //  transform: scale(1.05);
  //}
`;
