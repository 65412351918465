import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import {useMutation} from "@apollo/client";
import {useEffect} from "react";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {useSelector} from "react-redux";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";

export const CardSuccessPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;

    const customerKey = parsed?.customerKey;
    const authKey = parsed?.authKey;
    const returnUrl = parsed?.returnUrl;

    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);

    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        if (!authKey || !userInfo) {
            return;
        }
        axios.post(SERVER_ADDRESS + '/api/credit-cards/process', {
            customerKey: customerKey,
            authKey: authKey,
        }).then((res) => {
            console.log(res.data);
            if (res.data.status === 'DONE') {
                const payload = {
                    refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                    variables: {
                        id: tokenInfo.id,
                        data: {
                            // autoEnter: userInfo.autoEnter,
                        }
                    }
                };
                updateUser(payload).then(() => {
                    alert('결제 카드가 등록되었습니다.');
                    navigate(returnUrl);
                });
            } else {
                alert(res.data.e);
                navigate(returnUrl);
            }
        });
    }, [authKey, userInfo]);

    return <_CardSuccessPage>
        요청을 처리하는 중 입니다.
    </_CardSuccessPage>
}

const _CardSuccessPage = styled.div`
  text-align: center;
  padding: 20px;
`
