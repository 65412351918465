import styled, {css} from "styled-components";
import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {Link} from "react-router-dom";
import {UserProfile} from "./UserProfile";
import {useSelector} from "react-redux";

export const RecommendUsers = ({title, style}) => {
    const [recommends, setRecommends] = useState(null);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/party-members/getRecommend', {
        }).then((res) => {
            if (res.data) {
                if (res.data.length > 3) {
                    res.data.length = 3;
                }
                setRecommends(res.data);
            }
        });
    }, [tokenInfo]);

    return <_Wrap style={style}>
        <div className="recommend-title">
            <h3>{title ?? '프로필 둘러보기'}</h3>
            <Link to="/my/bookmark_more">더 보기</Link>
        </div>
        <div className="attendees">
            <div className="attendee-list">
                {recommends && recommends.map(user =>
                    <UserProfile u={user} />
                )}
                {(recommends && recommends.length === 0) && <div>
                    추천 멤버가 없습니다. 다음에 다시 시도해주세요.
                </div>}
            </div>
        </div>
    </_Wrap>
}

const _Wrap = styled.div`
  background: white;
  padding: 20px 20px 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  //width: 98%;

  .recommend {
  }

  .recommend-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 579px;

    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;


    :first-child {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    :nth-child(2) {
      margin-left: auto;
    }
  }

  .recommend-title a {
    text-decoration: underline;
    color: blue;
  }

  .attendees {
    margin-left: auto;
    margin-right: auto;
    max-width: 579px;
  }

  .attendees h3 {
    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .attendee-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`
