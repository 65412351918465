import styled from "styled-components";
import {useLayoutEffect, useRef, useState} from "react";
import {useWindowSize} from "react-use";

const _SmallPicture = ({className, src, style}) => {
    const size = useWindowSize();
    const imgWrap = useRef();
    const img = useRef();
    const [imageRatio, setImageRatio] = useState(null);
    const [imgClassName, setImgClassName] = useState('');

    useLayoutEffect(() => {
        if (!imgClassName) {
            const ratio = img?.current?.width / img?.current?.height;
            setImageRatio(ratio);
        }
    }, [img?.current?.width]);

    useLayoutEffect(() => {
        if (imageRatio) {
            const containerRatio = imgWrap?.current?.clientWidth / imgWrap?.current?.clientHeight;
            const name = imageRatio > containerRatio ? 'height-fix': 'width-fix';
            setImgClassName(name);
        }
    }, [imageRatio, size])

    return <div className={className} ref={imgWrap} style={style}>
        <img className={imgClassName} ref={img} src={src} />
        {/*<>{children}</>*/}
    </div>
};
export const SmallPicture = styled(_SmallPicture)`
  position: relative;
  box-sizing: border-box;
  border-radius: 50%;
  //border: 1px solid #DDDDDD;
  //border-radius: 8px;
  //margin-right: 8px;
  overflow: hidden;
  width: 60px;
  height: 60px;
  
  > img {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  > .width-fix {
    width: 100%;
  }
  > .height-fix {
    height: 100%;
  }
`
