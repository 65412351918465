import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {filter, find, isEmpty, sortBy} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {genderToString} from "../../toolbox/format";
import moment from "moment-timezone";
import {authAction} from "../../redux/authReducer";
import {userAction} from "../../redux/userReducer";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {parseJwt} from "../../toolbox/jwt";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {GET_PARTY_DETAIL, GET_RESTAURANTS} from "../../query/partyQuery";
import {flatEntity} from "../../toolbox/query";
import {getUpcomingMeet, getNextMeetTimes} from "../../toolbox/time";
import {UserProfile} from "../components/UserProfile";
import {Party, PartyReq} from "../components/Party";

export const MyProfilePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        } else {
            navigate("/user/" + tokenInfo.id, {replace: true});
        }
    }, [tokenInfo]);

    return <div></div>
};
