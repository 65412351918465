import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {Link, useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {GET_PARTY_MEMBER} from "../../query/partyQuery";
import {useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import {genderToString} from "../../toolbox/format";
import {FormComponent} from "../components/FormComponent";
import {loadingAction} from "../../redux/loadingReducer";


const Profile = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  text-align: center;

  .profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .profile-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .nickname {
    font-size: 2em;
    margin: 10px 0;
    color: #4a4a4a;
  }

  .gender {
    font-size: 1.2em;
    color: #777;
  }

  .edit-profile-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s ease;
  }

  .edit-profile-button:hover {
    background: #2980b9;
  }

  .profile-info {
    text-align: left;
  }

  .profile-info h3 {
    font-size: 1.5em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .profile-info ul {
    list-style: none;
    padding: 0;
  }

  .profile-info li {
    background: #f3f3f3;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    white-space: pre-wrap;
  }
`
const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
    //font-family: 'Arial', sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 32px;
    color: #333;
    background: #f5f5f5;
    display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
    //height: 100vh;
`
export const PartyMemberPage = () => {
    const params = useParams();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const memberId = params?.id;
    const [partyMember, setPartyMember] = useState(null);

    const [activeTab, setActiveTab] = useState('public');
    const [privateDisable, setPrivateDisable] = useState(true);

    const {data: queryData} = useQuery(GET_PARTY_MEMBER, {
        fetchPolicy: "cache-first",
        skip: memberId === undefined,
        variables: {id: memberId}
    });

    useEffect(() => {
        if (queryData?.partyMember?.data) {
            const payload = flatEntity(queryData.partyMember.data);
            setPartyMember(payload);
            // dispatch(loadingAction.loading(false));
        } else {
            // dispatch(loadingAction.loading(true));
        }
    }, [queryData]);

    if (!partyMember) {
        return <div>

        </div>
    }

    const profileKeys = ['소개'];

    const isMy = (userId) => {
        if (userId === tokenInfo?.id) {
            return true;
        }
        return false;
    }

    return <PageWrap>

        <TabContainer>
            <Tab isActive={activeTab === 'public'} onClick={() => setActiveTab('public')}>
                공개 프로필
            </Tab>
            <Tab isActive={activeTab === 'private'} onClick={() => setActiveTab('private')}>
                비공개 프로필
            </Tab>
        </TabContainer>
        <Profile>
            <div className="profile-header">
                <img src={partyMember.user.profileImage?.url ?? "/defaultProfile2.png"} alt="프로필 사진" className="profile-img" />
                    <h2 className="nickname">{partyMember.user.nickname}</h2>
                {isMy(partyMember.user.id) && <Link to={"/profile_edit"}>
                    <span className="edit-profile-button">프로필 수정</span>
                </Link>}
            </div>
            <div className="profile-info">
                <ul>
                    {partyMember.user.profile && profileKeys.map((key) => partyMember.user.profile[key]? <li><strong>{key}{key === '소개'? '\n': ': '}</strong>{partyMember.user.profile[key]}</li>: null)}
                    <li><strong>성별:</strong> {genderToString(partyMember.user.gender)}</li>
                    {/*<li><strong>받은 좋아요:</strong> {partyMember.user.likeReceives.length}</li>*/}
                    <li><strong>참여한 모임:</strong> {partyMember.user.partyMembers.length}</li>
                </ul>
            </div>
        </Profile>

        {/*<div style={{width: '200px', height: '200px'}}>*/}
        {/*    <_CircleImage src={partyMember.user.profileImage?.url ?? "/logo192.png"} />*/}
        {/*</div>*/}
        {/*<div>닉네임 : {partyMember.user.nickname}</div>*/}
        {/*<div>성별 : {genderToString(partyMember.user.gender)}</div>*/}
        {/*<div>받은 좋아요 수 : {partyMember.user.likeReceives.length}개</div>*/}
        {/*<div>참여한 모임 수 : {partyMember.user.partyMembers.length}개</div>*/}
        {/*<div style={{marginTop: '16px'}}/>*/}
        {/*{partyMember.user.profile && profileKeys.map((key) => partyMember.user.profile[key]? <div>{key} : {partyMember.user.profile[key]}</div>: null)}*/}
    </PageWrap>
}

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background-color: ${({ isActive }) => (isActive ? '#007bff' : '#fff')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#000')};
  border: 1px solid #007bff;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? '#0056b3' : '#e0e0e0')};
  }
`;