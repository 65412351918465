import moment from "moment-timezone";

export const calcTimeLeft = (date) => {
    let m = moment.tz(date, 'Asia/Seoul');
    let n = moment.tz('Asia/Seoul');
    let diff = m.diff(n);
    let duration = moment.duration(diff);
    return `${duration.hours()}시간 ${duration.minutes()}분`;
}

export const calcAge = (birthDate) => {
    const now = moment.tz('Asia/Seoul');
    const birth = moment(birthDate, "YYYY-MM-DD");
    return now.diff(birth, 'years');
}

export const calcTimeDiffTxt = (date) => {
    let m = moment.tz(date, 'Asia/Seoul');
    let n = moment.tz('Asia/Seoul');
    let diff = n.diff(m, 'weeks');
    if (diff) {
        return `${diff}주 전`;
    }
    diff = n.diff(m, 'days');
    if (diff) {
        return `${diff}일 전`;
    }
    diff = n.diff(m, 'hours');
    if (diff) {
        return `${diff}시간 전`;
    }
    diff = n.diff(m, 'minutes');
    if (diff) {
        return `${diff}분 전`;
    }
    return '방금 전';
}

export const getNextMeetTimes = () => {
    const result = [];
    let start = calcMeetTime(getNextFixTime());
    for (let a of [1, 2, 3, 4]) {
        result.push(start.clone());
        start.add(1, 'week');
    }
    return result;
}

export const getUpcomingMeet = () => {
    const nextFix = getNextFixTime();
    return calcMeetTime(nextFix);
}

export const calcFixTime = (meetTime) => {
    const fix = moment.tz(meetTime, 'Asia/Seoul');
    fix.subtract(3, 'days');
    return fix;
}

export const calcMeetTime = (fixTime) => {
    const meet = moment.tz(fixTime, 'Asia/Seoul');
    meet.add(3, 'days');
    return meet;
}

export const getNextFixTime = () => {
    const now = moment.tz('Asia/Seoul');
    const fixDay = moment.tz('Asia/Seoul').day(1).hour(19).minutes(0).seconds(0).millisecond(0);
    if (fixDay < now) {
        fixDay.add(1, 'week');
    }
    return fixDay;
}