import styled, {css} from "styled-components";
import {genderToString, toPhoneFormat} from "../../toolbox/format";
import {isAdmin, openAddress} from "../../toolbox/logic";
import moment from "moment-timezone";
import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {loadingAction} from "../../redux/loadingReducer";
import {useDispatch, useSelector} from "react-redux";
import {useMutation} from "@apollo/client";
import {DELETE_COMMENT} from "../../query/mutationQuery";
import {Link, useLocation} from "react-router-dom";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {TinyProfile, UserProfile} from "./UserProfile";
import {calcAge, calcFixTime, calcTimeDiffTxt, getNextFixTime, getUpcomingMeet} from "../../toolbox/time";
import {find} from "lodash";

export const PartyReq = ({meetedAt, requested, handleEnter, handleExit, isHost, partyType, partyId, canCustom, handleCustom}) => {
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const location = useLocation();
    const [timeLeft, setTimeLeft] = useState(null);
    const [genderTxt, setGenderTxt] = useState(null);
    const [estimates, setEstimates] = useState(null);

    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/parties/info', {
            meetedAt: meetedAt,
            partyType: partyType,
        }).then((res) => {
            if (res.data) {
                if (res.data.genderNeed && !requested) {
                    if (res.data.genderNeed.gender === 'MALE') {
                        setGenderTxt(`${genderToString(res.data.genderNeed.gender)} 멤버가 부족해요!! 실시간 성비 [남${res.data.genderNeed.ratio}: 여${100 - res.data.genderNeed.ratio}]`);
                    } else if (res.data.genderNeed.gender === 'FEMALE') {
                        setGenderTxt(`${genderToString(res.data.genderNeed.gender)} 멤버가 부족해요!! 실시간 성비 [여${res.data.genderNeed.ratio}: 남${100 - res.data.genderNeed.ratio}]`);
                    }
                } else {
                    setGenderTxt(null);
                }
                if (res.data.estimates) {
                    setEstimates(res.data.estimates);
                }
            }
        });
    }, [requested]);

    useEffect(() => {
        const timer = setInterval(() => {
            renderTimeLeft();
        }, 1000);
        renderTimeLeft();
    }, [meetedAt]);


    const renderTimeLeft = () => {
        if (meetedAt.toISOString() === getUpcomingMeet().toISOString()) {
            const now = moment.tz('Asia/Seoul');
            const fix = getNextFixTime();
            if (now < fix) {
                let diff = fix.diff(now);
                let duration = moment.duration(diff);

                const days = Math.floor(duration.asDays());
                const hours = duration.hours();
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                if (days > 6) {
                    setTimeLeft(null);
                } else {
                    const formattedDays = days ? `${days}일 ` : '';
                    const formattedHours = (days || hours) ? `${hours}시간 ` : '';
                    const formattedMinutes = (days || hours || minutes) ? `${minutes}분 ` : '';
                    const formattedSeconds = (days || hours || minutes || seconds) ? `${seconds}초 ` : '';

                    const left = `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`.trim();
                    setTimeLeft(left);
                }
            } else {
                setTimeLeft(null);
            }
        }
    }

    return <_Party>
        {/*<div className="header">*/}
        {/*    {partyType !== "ONLINE" && <div className="meetedAt">{moment.tz(meetedAt, 'Asia/Seoul').format('M월 D일(dd) A hh:mm ')}</div>}*/}
            {/*<div className="status">{moment.tz(meetedAt, 'Asia/Seoul').subtract(3, 'days').format('M. D. (dd) A hh:mm')} 신청 마감 {timeLeft}</div>*/}
            {/*{requested? <_Button disabled={true}>신청 완료</_Button>:<_Button onClick={handleClick}>참석 신청</_Button>}*/}
        {/*</div>*/}
        <div>
            <div>장소 : <span className="status">
                {partyType === 'ONLINE' && '온라인 디너모임'}
                {partyType !== 'ONLINE' && '강남역 근처'}
            </span>
            </div>
        </div>
        <div>
            <div>인원 : <span className="status">
                {partyType === 'ONLINE' && '2~6인'}
                {partyType !== 'ONLINE' && '4~6인'}
            </span>
            </div>
        </div>
        
        {timeLeft && <div>
            <div>신청 마감까지 : <span className="status">{timeLeft}</span></div>
        </div>}

        {(timeLeft && estimates && estimates.length !== 0) && <div style={{marginBottom: '12px'}}>
            <div>신청한 지인들</div>
            <div style={{marginTop: '6px', display: 'flex', width: '100%', flexWrap: 'wrap', columnGap: '6px', rowGap: '12px'}}>
            {estimates.map(e => <TinyProfile u={e} />)}
            </div>
        </div>}

        {/*{genderTxt && <div>*/}
        {/*    <div><span className="genderTxt">{genderTxt}</span></div>*/}
        {/*</div>}*/}

        <div className="actions">
            {requested? <>
                {(partyType === 'CUSTOM' && isHost === true) && <Link to={"/party_modify/" + partyId}>
                    <_Button>내 모임 구성하기</_Button>
                </Link>}
                {(partyType === 'PUBLIC' && isHost === true) && <Link to={"/party_modify/" + partyId}>
                    <_Button>내 모임 보기</_Button>
                </Link>}
                {(partyType === 'PUBLIC' && isHost === false) && <Link to={"/party_modify/" + partyId}>
                    <_Button>모임 보기</_Button>
                </Link>}
                <_Button onClick={handleExit} red={true}>취소하기</_Button>
            </>:<>
                {canCustom && <_Button onClick={handleCustom}>내가 모임 만들기</_Button>}
                <_Button onClick={handleEnter}>참석 신청</_Button>
            </>}
        </div>
    </_Party>
}

export const Party = ({p, m}) => {
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const [updateUser] = useMutation(UPDATE_USER);

    const getStatus = () => {
        if (p.building) {
            return "신청완료";
        }
        let now = moment.tz('Asia/Seoul');
        if (now > moment.tz(p.meetedAt, 'Asia/Seoul').add(2, 'hours')) {
            return "모임완료";
        }
        if (now > moment.tz(p.meetedAt, 'Asia/Seoul')) {
            return "모임중";
        }
        return "참석확정";
    }

    const canCancel = () => {
        return p.building && moment.tz(p.meetedAt, 'Asia/Seoul').toISOString() >= getUpcomingMeet().toISOString();
    }

    const handleExit = async () => {
        if (window.confirm("모임 참가를 취소하시겠습니까?")) {
            dispatch(loadingAction.loading(true));
            await axios.post(SERVER_ADDRESS + '/api/parties/exit', {
                partyId: p.id,
            });
            const payload = {
                refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                variables: {
                    id: tokenInfo.id,
                    data: {
                        // autoEnter: userInfo.autoEnter,
                    }
                }
            };
            await updateUser(payload);
            dispatch(loadingAction.loading(false));
        }
    }

    return <_Party>
        <div className="header">
            <div className="meetedAt">{moment.tz(p.meetedAt, 'Asia/Seoul').format('M월 D일(dd) A hh:mm ')}</div>
            <div className="status">{getStatus()}</div>
        </div>
        <div className="content">
            <div>{p.restaurantName ? p.restaurantName: "모임 확정시 알림을 보내드려요"}</div>
        </div>
        {canCancel() && <div>
            <div>취소가능기한 : <span className="status">{calcFixTime(moment.tz(p.meetedAt, 'Asia/Seoul')).format('M월 D일 HH:mm')}</span></div>
        </div>}
        <div className="actions">
            {getStatus() === '모임완료' && <Link to={"/party_after/" + p.id + '?review=true'}>
                {m.review? <_Button>평가 수정하기</_Button>:  <_Button red={true}>평가하기</_Button>}
            </Link>}
            {p.building ? (p.partyType === 'CUSTOM' ?
                    <Link to={`/party_modify/${p.id}`}><_Button>내 모임 구성하기</_Button></Link>:
                    p.partyType === 'PUBLIC'?
                        <Link to={`/party_modify/${p.id}`}><_Button>내 모임 보기</_Button></Link>:
                        <_Button disabled={true}>상세정보</_Button>):
                <Link to={getStatus() === '모임완료'? `/party_after/${p.id}`: `/party_detail/${p.id}`}><_Button>상세정보</_Button></Link>}
            {canCancel() && <_Button onClick={handleExit} red={true}>취소하기</_Button>}
        </div>
        {/*<div className="seperate" />*/}
    </_Party>
}

export const PartyAdmin = ({p}) => {
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const getStatus = () => {
        if (p.building) {
            return "확정대기";
        }
        let now = moment.tz('Asia/Seoul');
        if (now > moment.tz(p.meetedAt, 'Asia/Seoul')) {
            return "모임완료";
        }
        return "모임확정";
    }

    const getMemberContents = (m) => {
        const getUser = (id) => {
            return find(p.members, m => m.user.id === id).user;
        }
        if (getStatus() === '모임완료') {
            if (m.review) {
                let result = [];
                for (let key in m.review) {
                    const u = getUser(key);
                    result.push(`${u.nickname}: ${m.review[key]}`);
                }
                return `평가완료 (${result.join(', ')})`;
            }
        }
    }

    const getMemberAge = (m) => {
        if (m.user.birth) {
            return calcAge(m.user.birth);
        }
        return null;
    }

    return <_Party>
        <div className="header">
            <div className="meetedAt">{moment.tz(p.meetedAt, 'Asia/Seoul').format('M월 D일(dd) A hh:mm ')}</div>
            <div className="status">{getStatus()}</div>
        </div>
        <div className="content">
            <div>{p.restaurantName ? p.restaurantName: "모임이 확정되기 전입니다"} ({p.partyType})</div>
        </div>
        <div style={{display: 'flex', flexDirection:'column', gap: '16px'}}>
            {p.members.map(m => <UserProfile u={m.user} showGender={true}>
                만 {getMemberAge(m)}세<br />
                {calcTimeDiffTxt(m.createdAt)} 신청<br/>
                {m.user.phone}<br/>
                {m.toss && <>예약금 결제 : {m.toss.status} {m.toss.status === 'DONE' && <span style={{cursor: 'pointer'}} onClick={() => {
                    if (window.confirm('결제 취소하시겠습니까?')) {
                        dispatch(loadingAction.loading(true));
                        axios.post(SERVER_ADDRESS + '/api/tosses/cancel', {
                            tossId: m.toss.id,
                        }).then((res) => {
                            console.log(res);
                            alert('결제가 취소되었습니다.')
                            dispatch(loadingAction.loading(false));
                        });
                    }
                }}>[결제 취소하기]</span>} </>}
                {getMemberContents(m)}
            </UserProfile>)}
        </div>
        {/*<div className="actions">*/}
        {/*    {getStatus() === '모임완료' && <Link to={"/party_after/" + p.id}>*/}
        {/*        {m.review? <_Button>내 평가보기</_Button>:  <_Button red={true}>평가하기</_Button>}*/}
        {/*    </Link>}*/}
        {/*    {p.building && <_Button onClick={handleExit}>취소하기</_Button>}*/}
        {/*    {p.building ? <_Button disabled={true}>상세정보</_Button>: <Link to={getStatus() === '모임완료'? `/party_after/${p.id}`: `/party_detail/${p.id}`}><_Button>상세정보</_Button></Link>}*/}
        {/*</div>*/}
        <div className="seperate" />
    </_Party>
}

const _PartyReq = styled.div`
  font-size: 1em;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  white-space: pre-wrap;
  word-break: keep-all;
`
const _Party = styled.div`
  border-radius: 8px;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.2);
  font-size: 1em;
  padding: 8px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .genderTxt {
    color: red;
    font-weight: 700;
  }
  
  .meetedAt {
  }

  .status {
    font-weight: 700;
  }
  
  .content {
    font-weight: 700;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  
  .seperate {
    margin-top: 28px;
    margin-bottom: 28px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
  }
`

const _Button = styled.div`
  cursor: pointer;
  border-radius: 6px;
  padding: 2px 10px;
  color: black;
  border: 1px solid #808080;
  font-weight: 300;
  white-space: nowrap;

  ${(props) => props.red && css`
    color: red;
    border: 1px solid red;
    
  `}
  ${(props) => props.disabled && css`
    color: #808080;
    border: 1px solid #808080;
    background-color: #dcdcdc;
    cursor: not-allowed;
  `}
`
