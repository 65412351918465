import {gql} from "@apollo/client";

export const CREATE_COMMENT = gql`
mutation createComment ($data: CommentInput!) {
  createComment(data: $data) {
    data {
      id
    }
  }
}
`;

export const DELETE_COMMENT = gql`
mutation deleteComment ($id: ID!) {
  deleteComment(id: $id) {
    data {
      id
    }
  }
}
`;

export const UPDATE_PARTY_MEMBER = gql`
mutation updatePartyMember ($id: ID!, $data: PartyMemberInput!) {
  updatePartyMember(id: $id, data: $data) {
    data {
      id
    }
  }
}
`;