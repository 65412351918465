import React from "react";
import styled from "styled-components";

const Loading = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingIndicator = ({ ...props }) => {
    return (
        <_LoadingIndicator>
            <div className="spinner" />
        </_LoadingIndicator>
    );
};

const _LoadingIndicator = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it overlays everything */
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //height: 100vh;

  .spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
export default LoadingIndicator;
