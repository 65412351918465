import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {GET_PARTY_DETAIL, GET_RESTAURANT, GET_RESTAURANTS} from "../../query/partyQuery";
import {useMutation, useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import moment from "moment-timezone";
import {loadingAction} from "../../redux/loadingReducer";
import {genderToString, 비속어체크} from "../../toolbox/format";
import {useForm} from "../../toolbox/hook";
import {CREATE_COMMENT, DELETE_COMMENT} from "../../query/mutationQuery";
import {isEmpty} from "lodash";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {getUpcomingMeet} from "../../toolbox/time";

const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  //font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px 12px;
  color: #333;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: keep-all;
  gap: 18px;
  //height: 100vh;

  .meeting-details {
    background: white;
    padding: 20px 20px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }

  .meeting-info {
    text-align: center;
    margin-bottom: 20px;
  }

  .meeting-info h2 {
    font-size: 1.5em;
    color: #4a4a4a;
  }

  .meeting-info p {
    font-size: 1em;
  }

  .attendees {
    text-align: center;
  }

  .attendees h3 {
    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .attendee-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .attendee {
    background: #f3f3f3;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 120px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .attendee-list a {
    display: flex;
  }

  .attendee img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .attendee p {
    margin: 0;
    font-size: 1em;
    color: #333;
    white-space: pre-wrap;
    word-break: break-all;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .host {
    border: 2px solid #3498db;
  }

  .host-label {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #3498db;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 0.8em;
  }

  #comments-section {
    text-align: left;

    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }
  
  #comments-list {
    //max-height: 200px;
    //overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .comment {
    display: flex;
    align-items: center;
    background: #f3f3f3;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
  }

  .comment-profile-pic {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
  }

  .comment-content {
    flex: 1;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0.6rem 0.6rem 0.1rem;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    word-wrap: break-word; /* 긴 단어가 영역을 벗어나지 않도록 함 */
  }

  .comment-date {
    margin-top: 0.2rem;
    font-size: 0.8em;
    color: #777;
    align-self: flex-end;
  }
  
  .comment-delete {
    position: absolute;
    top: 18px;
    right: 16px;
    background: transparent;
    font-size: 0.8em;
    color: #999;
    cursor: pointer;
    border: 1px solid black;
    padding: 2px 6px;
  }

  .comment-delete:hover {
    color: #e74c3c;
  }

  #comment-form {
    display: flex;
    flex-direction: column;
  }

  #user-name, #user-profile-pic, #comment-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  #comment-submit {
    padding: 10px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  #comment-submit:hover {
    background: #2980b9;
  }
`
export const RestaurantPage = () => {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const restaurantId = params?.id;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const [restaurant, setRestaurant] = useState(null);

    const {form: commentForm, onChange} = useForm({
        body: '',
    });

    const [updateUser] = useMutation(UPDATE_USER);
    const {data: restaurantsData, refetch} = useQuery(GET_RESTAURANT, {
        variables: {id: restaurantId},
        fetchPolicy: "cache-first",
    });

    useEffect(() => {
        if (restaurantsData?.restaurant?.data) {
            const payload = flatEntity(restaurantsData?.restaurant.data);
            setRestaurant(payload);
        }
    }, [restaurantsData]);

    // const onClickComment = () => {
    //     let body = commentForm.body;
    //     if (isEmpty(tokenInfo)) {
    //         alert('로그인이 필요합니다.');
    //         return;
    //     }
    //     if (비속어체크(body)) {
    //         alert('부적절한 단어가 포함되어있습니다.');
    //         return;
    //     }
    //
    //     dispatch(loadingAction.loading(true));
    //     createComment({
    //         variables: {
    //             data: {
    //                 body: body,
    //                 user: tokenInfo.id,
    //                 party: partyId,
    //             },
    //         },
    //         onCompleted: data => {
    //             dispatch(loadingAction.loading(false));
    //             onChange({id: 'body', value: ''});
    //             refetch();
    //         }
    //     });
    // }
    //
    // const onClickCommentDelete = (commentId) => {
    //     if (window.confirm("선택한 댓글을 삭제하시겠습니까?")) {
    //         dispatch(loadingAction.loading(true));
    //         deleteComment({variables: { id: commentId }}).then(res => {
    //             dispatch(loadingAction.loading(false));
    //             refetch();
    //         });
    //     }
    // }

    return <PageWrap>
        <div className="meeting-details">
            <h1>{restaurant?.name}</h1>
            <div style={{margin: '20px 0'}}>
                <ReactMarkdown>{restaurant?.article}</ReactMarkdown>
            </div>
            {/*<ReactMarkdown remarkPlugins={[remarkGfm]} children={restaurant?.article?.replace(/\n/gi, "&nbsp;\n\n")}/>*/}
            {/*<div className="meeting-info">*/}
            {/*    <h2>식당 정보</h2>*/}
            {/*    <p><strong>모임 시간:</strong> {moment.tz(partyDetail.meetedAt, 'Asia/Seoul').format('M월 D일 HH:mm')} {timeLeft? `(${timeLeft} 남음)`: ''}</p>*/}
            {/*    <p><strong>모임 장소:</strong> {partyDetail.restaurantName} <a href={"https://map.naver.com/p/search/" + encodeURIComponent(partyDetail.restaurantName)} rel="noopener noreferrer" target="_blank">[네이버 지도]</a></p>*/}
            {/*</div>*/}
            {/*<div className="attendees">*/}
            {/*    <h3>참석자</h3>*/}
            {/*    <div className="attendee-list">*/}
            {/*        {partyDetail.members.map(member =>*/}
            {/*            <Link to={"/user/" + member.user.id}>*/}
            {/*                <div className={`attendee ${member.isHost && 'host'}`}>*/}
            {/*                    <img src={member.user?.profileImage?.url ?? '/logo192.png'} />*/}
            {/*                    <p>{member.user.nickname} / {genderToString(member.user.gender)}</p>*/}
            {/*                    {member.isHost && <span className="host-label">호스트</span>}*/}
            {/*                </div>*/}
            {/*            </Link>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*{calcUpcomingMeet().format('M월 D일 A hh:mm ')} 모임*/}
        </div>
        {/*<div id="comments-section">*/}
        {/*    <h3>댓글 {commentList ? commentList.length: 0}개</h3>*/}
        {/*    <div id="comments-list">*/}
        {/*        {commentList && commentList.map((c, index) => (*/}
        {/*            <div key={`party-comment ${index}`} className="comment">*/}
        {/*                <img src={c.user?.profileImage?.url ?? '/logo192.png'} alt={`${c.user?.nickname}의 프로필`} className="comment-profile-pic" />*/}
        {/*                <div className="comment-content">*/}
        {/*                    <strong>{c.user?.nickname}</strong>*/}
        {/*                    <p>{c.body}</p>*/}
        {/*                    <div className="comment-date">{moment.tz(c.createdAt, 'Asia/Seoul').format('MM.DD HH:mm:ss')}</div>*/}
        {/*                </div>*/}
        {/*                {tokenInfo?.id === c.user.id && <button className="comment-delete" onClick={() => onClickCommentDelete(c.id)}>X</button>}*/}
        {/*            </div>*/}
        {/*        ))}*/}
        {/*    </div>*/}
        {/*    <div id="comment-form">*/}
        {/*        <textarea id="comment-input" value={commentForm.body} onChange={(e) => onChange({id: 'body', value: e.target.value})} placeholder="댓글을 작성하세요..."></textarea>*/}
        {/*        <button id="comment-submit" onClick={onClickComment}>댓글 작성</button>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </PageWrap>
}
