import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {useLocation, useParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import {useForm} from "../../toolbox/hook";
import {GET_ME, GET_USER, UPDATE_USER} from "../../query/userQuery";
import {loadingAction} from "../../redux/loadingReducer";
import {PhotoComponent} from "../components/PhotoComponent";
import {FormComponent} from "../components/FormComponent";
import {RadioComponentSimple} from "../components/RadioComponent";
import {authAction} from "../../redux/authReducer";
import {MyButton, PrimaryButton} from "../components/CommonComponents";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {isEmpty} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";

const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  max-width: 450px;
`
export const ProfileEditPage = () => {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : {};
    const profileType = parsed.type;

    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [loading, setLoading] = useState(false);
    const {form, onChange} = useForm({
        nickname: '',
        profileImage: null,
        profileImagePrivate: null,
        introduce: '',
        introducePrivate: '',
        gender: '',
    });

    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (userInfo) {
            onChange({id: 'nickname', value: userInfo.nickname});
            onChange({id: 'profileImage', value: userInfo.profileImage});
            onChange({id: 'profileImagePrivate', value: userInfo.profileImagePrivate});
            onChange({id: 'introduce', value: userInfo.introduce ?? ''});
            onChange({id: 'introducePrivate', value: userInfo.introducePrivate ?? ''});
            onChange({id: 'gender', value: userInfo.gender});
        }
    }, [userInfo]);



    const submit = async () => {
        if (loading) {
            return;
        }

        let {nickname, gender, profileImage, profileImagePrivate, introduce, introducePrivate, ...profile} = form;


        if (profileType === 'private') {

        } else {
            if (introduce && introduce.length >= 20) {

            } else {
                alert('소개글을 20자 이상으로 입력해주세요.');
                return;
            }
        }

        if (introduce && !introducePrivate) {
            introducePrivate = introduce;
        }

        if (profileImage && !profileImagePrivate) {
            profileImagePrivate = profileImage;
        }

        const payload = {
            refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
            variables: {
                id: tokenInfo.id,
                data: {
                    nickname,
                    introduce,
                    introducePrivate,
                    gender,
                    profileImage: profileImage?.id ?? null,
                    profileImagePrivate: profileImagePrivate?.id ?? null,
                }
            }
        };

        dispatch(loadingAction.loading(true));

        const eventResult = await axios.post(SERVER_ADDRESS + '/api/tickets/checkEvent', {});

        if (eventResult.data === true) {
            alert('베타 이벤트 보상으로 90일 무료 구독권이 지급되었습니다.');
        }

        await updateUser(payload);
        setLoading(false);
        dispatch(loadingAction.loading(false));
        navigate("/my_profile");
    }

    const copyProfile = () => {
        onChange({id: 'introducePrivate', value: form.introduce});
        onChange({id: 'profileImagePrivate', value: form.profileImage});
    }

    // console.log(form);

    // console.log(form.address);

    const desc1 = [
        '기본정보',
        '직업/전공, 거주지/근무지, 취미, mbti, 나의장점 등을 적어주세요.\n',
        '자유소개',
        '내가 좋아하는 것, 인상깊은 경험, 남들이 호기심 가질 만한 것, 나의 흥미로운 사실, 살면서 기뻤을 때, 힘들었을 때 등 자유롭게 적어주세요.',
        // '상대방의 만남 전 기대감과 호감을 받을 수 있는 프로필, 호기심 가질만한 내용을 적어주시면 좋습니다.',
        // 'ex) 건대 근처에 거주하는 30대 개발자입니다.',
        // '겨울에는 스노우보드를 타고, 평소에는 등산도 즐겨합니다.',
    ].join('\n');
    const placeholder1 =
        '기본정보 : ' +
        '직업/전공, 거주지/근무지, 취미, mbti, 나의장점 등을 적어주세요.\n' +
        '자유소개 : ' +
        '내가 좋아하는 것, 인상깊은 경험, 남들이 호기심 가질 만한 것, 나의 흥미로운 사실, 살면서 기뻤을 때, 힘들었을 때 등 자유롭게 적어주세요.';
    const desc2 = [
        '비공개 프로필은 모임 후 내가 \'즐거웠습니다\' 이상의 평가를 한 참가자들에게만 공개됩니다. 내 연락처나 SNS 등을 남겨서 원하는 상대방과 모임 후에도 연락을 이어가보세요. 이외에도, 전체공개 프로필에 적기 어려운 개인적인 정보나 다양한 주제의 내용을 자유롭게 적을 수 있습니다.',
        '\nex) 건대 근처에 거주하는 30대 개발자 홍길동입니다.',
        '겨울에는 스노우보드를 타고, 평소에는 등산도 즐겨합니다.',
        '같이 스노우보드나 등산하러 가실 좋은 친구나 연인을 만나면 좋겠습니다!',
        '인스타 : @ins_snow',
        '휴대폰 : 010-1234-5678',
    ].join('\n');
    const placeholder2 = '비공개 프로필은 모임 후 내가 \'즐거웠습니다\' 이상의 평가를 한 상대방만 볼 수 있어요. 내 연락처나 SNS, 이외에 전체공개 프로필에 적기 어려운 내용을 자유롭게 적어보세요.'

    return <PageWrap>
        <h3>{profileType === 'private'? "비공개 프로필": "전체 공개 프로필"} 수정</h3>
        {profileType === 'private' && <MyButton onClick={copyProfile}>공개 프로필 내용 가져오기</MyButton>}
        <FormComponent title="닉네임" id="nickname" onChange={onChange} form={form} disabled={profileType === 'private'} />
        {profileType === 'private' ?
            <>
                <PhotoComponent title="프로필 사진 (비공개 프로필)" description={"비공개프로필은 모임 후 내가 좋은 평가를 한 분들에게만 공개되므로 얼굴이 나온 사진을 올리셔도 괜찮아요."} id="profileImagePrivate" onChange={onChange} form={form} isSingle={true}/>
                <FormComponent type="textarea" title="소개 (비공개 프로필)" id="introducePrivate" description={desc2} placeholder={placeholder2} onChange={onChange} form={form} rows={5} />
                {/*<FormComponent title="휴대폰" id="phone" onChange={onChange} form={form} />*/}
                {/*<FormComponent title="sns 정보" id="snsLink" onChange={onChange} form={form} />*/}
            </>:<>
                <PhotoComponent title="프로필 사진" id="profileImage" description={"전체공개프로필은 모두에게 공개되므로 얼굴이 정면으로 나온 사진보다는 분위기나 스타일을 보여줄 수 있는 사진으로 추천드려요."} onChange={onChange} form={form} isSingle={true}/>
                <FormComponent type="textarea" title="소개 (20자 이상)" description={desc1} id="introduce" placeholder={placeholder1} onChange={onChange} form={form} rows={5} />
            </>
        }
        <RadioComponentSimple id="gender" title="성별" form={form} onChange={onChange} values={[
            {id: 'MALE', label: '남성'},
            {id: 'FEMALE', label: '여성'},
        ]}/>
        <div style={{display: 'flex', marginTop: '20px', justifyContent: 'center', cursor: 'pointer'}}>
            <PrimaryButton onClick={submit} style={{padding: '6px 12px'}}>수정하기</PrimaryButton>
        </div>
    </PageWrap>
}
