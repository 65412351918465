import {map} from "lodash";

const unpackEntity = (e) => {
    const result = {};
    if (e.id !== undefined) {
        result.id = e.id
    }
    if (e.attributes !== undefined) {
        for (let key in e.attributes) {
            result[key] = flatEntity(e.attributes[key]);
        }
    }
    return result;
}

export const flatEntity = (e) => {
    if (e === null) {
        return null;
    }
    if (typeof(e) === 'object') {
        if (Array.isArray(e)) {
            return map(e, (_e) => flatEntity(_e));
        } else if (e.data !== undefined) {
            if (Array.isArray(e.data)) {
                return map(e.data, (_e) => flatEntity(_e));
            } else if (e.data === null) {
                return null;
            }
            return unpackEntity(e.data);
        } else if (e.__typename && (e.id !== undefined || e.attributes !== undefined)) {
            return unpackEntity(e);
        } else {
            return e;
        }
    } else {
        return e;
    }
}