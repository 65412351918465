import styled, {css} from "styled-components";
import {toPhoneFormat} from "../../toolbox/format";
import {openAddress} from "../../toolbox/logic";
import {useState} from "react";
import {Dialog, Drawer} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";


export const FormComponent = ({id, title, smallTitle, form, onChange, onClick, description, ...props}) => {
    const [open, setOpen] = useState(false);
    const InputComponent = () => {
        if (id === 'phone') {
            return <input {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: toPhoneFormat(e.target.value)})}/>;
        } else if (id === 'address') {
            return <>
                <span className="search" {...props} id={id} onClick={(e) => openAddress((address) => {
                    onChange([
                        {id, value: address},
                    ]);
                })}>
                    <img src="/search.svg"/>{form[id]}
                </span>
            </>;
        }
        switch (props.type) {
            case "textarea":
                return <textarea {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>
        }
        return <input id={id} value={form[id]} {...props} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>;
    }

    return <_FormComponent {...props}>
        {title && <div className={smallTitle? 'small-title': 'title'}>{title}
            {/*{description && <img id="drawerBtn" onClick={() => setOpen(true)} style={{width: '20px', cursor: 'pointer'}} src={"/help-btn.png"} />}*/}
            {description && <HelpBtn contents={description} />}
        </div>}
        {/*{description && <div className="description">{description}</div>}*/}
        <div className="content" onClick={onClick}>
            <div className="text-input">
                {InputComponent()}
                {props.unit && <span className="unit-label">{props.unit}</span>}
            </div>
        </div>
        {description && <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
            <div style={{padding: '32px', whiteSpace: 'pre-wrap', wordBreak: 'keep-all'}}>
                {description}
            </div>
        </Dialog>}
        {/*{description && <Drawer id="drawer" open={open} onClose={() => setOpen(false)}>asdfasdf</Drawer>}*/}
    </_FormComponent>
}

export const HelpBtn = ({title, contents, white}) => {
    const [open, setOpen] = useState(false);
    return <>
        {/*<img id="drawerBtn" onClick={(e) => {*/}
        {/*    e.preventDefault();*/}
        {/*    e.stopPropagation();*/}
        {/*    setOpen(true)*/}
        {/*}} style={{width: '20px', height: '20px', cursor: 'pointer', filter: `invert(${white? '1': '0'})`}} src={"/help-btn.png"} />*/}
        <HelpIcon style={{color: white? 'white': 'black', fontSize: '20px'}} id="drawerBtn" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true)
        }} />
        <Dialog open={open} onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(false);
        }} fullWidth={true}>
            <div onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }} style={{padding: '32px', whiteSpace: 'pre-wrap', wordBreak: 'keep-all'}}>
                {title && <h3 style={{marginBottom: '8px'}}>{title}</h3>}
                {contents}
            </div>
        </Dialog>
    </>
}

export const _FormComponent = styled.div`
  > .small-title {
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    margin: 4px 0;
  }
  > .title {
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin-bottom: 4px;
  }
  
  > .description {
    font-size: 14px;
    color: #666666;
    margin-bottom: 8px;
  }

  > .content {
    padding: 14px;
    border-radius: 8px;
    //border: 1px solid var(--gray-200, #F5F5F5);
    border: 1px solid #E0E0E0;
    //background: var(--gray-100, #FAFAFA);
    background: white;
    > .text-input {
      position: relative;
      input, textarea, .search {
        caret-color: #33CCC1;
        background-color: rgba(0, 0, 0, 0);
        display: block;
        width: 100%;
        box-sizing: border-box;
        border: none;
        
        color: var(--gray-scale-black, #212121);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        resize: none;
        
        ::placeholder {
          color: var(--gray-500, #C9C9C9);
        }
        
        :focus {
          outline: none;
        }
      }
      
      > .unit-label {
        color: var(--gray-scale-black, #212121);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        
        position: absolute;
        top: 50%;
        right: 0; /* 오른쪽 패딩 값 */
        transform: translateY(-50%);  /* 세로 중앙 정렬 */
        pointer-events: none;  /* 마우스 이벤트 무시 */
      }
      
      > .search {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      
      textarea {
        
      }
    }


    ${(props) => props.disabled && css`
      //background: var(--gray-100, #FAFAFA);
      background: #F5F5F5;
      cursor: not-allowed;
      > .text-input {
        cursor: not-allowed;
        input, textarea, .search {
          cursor: not-allowed;
          caret-color: #33CCC1;
          background-color: rgba(0, 0, 0, 0);
          color: var(--gray-scale-black, #212121);
          //color: white;
          ::placeholder {
            color: var(--gray-500, #C9C9C9);
            //color: white;
          }
        }

        > .unit-label {
          color: var(--gray-scale-black, #212121);
        }
      }
    `}
  }
`;