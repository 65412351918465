import styled from "styled-components";
import {Drawer} from "@mui/material";
import {Header} from "./Header";

export const FullScreen = ({children}) => {
    return <Drawer open={true} PaperProps={{
        style: {
            maxHeight: '100%',
            height: '100%',
        },
    }}>
        <PageWrap>
            <Header fullscreen={true} />
            <div className="content">
                {children}
            </div>
        </PageWrap>
    </Drawer>
}

const PageWrap = styled.div`
  box-sizing: border-box;
  font-family: "Elice Digital Baeum", sans-serif;
  height: calc(100% - 77px);

  @media only screen and (max-width: 767px) {
    height: calc(100% - 61px);
  }
  
  width: 100vw;
  .content {
    margin-top: 77px;
    @media only screen and (max-width: 767px) {
      margin-top: 61px;
    }
    height: 100%;
    max-height: 100%;
    box-shadow: 0 2px 36px 0 rgba(0,0,0,.05);
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  };
  
`