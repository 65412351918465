const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
};

const theme = {
    colors: {
        backgroundColor: '#FFFFFF',
        textColor: '#000000',
        borderColor: '#000000',
        lightGray: '#F3F3F3',
        darkGray: '#808080',
        primary: '#3498db',
        primaryDark: '#2980b9',
        secondary: '#28a745',
        secondaryDark: '#218838',
    },
    media: {
        mobileS: `(min-width: ${size.mobileS})`,
        mobileM: `(min-width: ${size.mobileM})`,
        mobileL: `(min-width: ${size.mobileL})`,
        tablet: `(min-width: ${size.tablet})`,
        laptop: `(min-width: ${size.laptop})`,
        laptopL: `(min-width: ${size.laptopL})`,
        desktop: `(min-width: ${size.desktop})`,
        desktopL: `(min-width: ${size.desktop})`
    }
};

export default theme;
