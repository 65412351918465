import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {filter, isEmpty} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {authAction} from "../../redux/authReducer";
import {userAction} from "../../redux/userReducer";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {FormComponent, HelpBtn} from "../components/FormComponent";
import {PrimaryButton} from "../components/CommonComponents";
import {useForm} from "../../toolbox/hook";
import { useCallback } from 'react';
import moment from 'moment-timezone';

export const LeavePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const {form, onChange} = useForm({
        reason: '',
    });

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate('/');
        }
    }, [tokenInfo]);

    const handleLeave = useCallback(async () => {
        if (!form.reason) {
            alert('탈퇴 사유를 입력해주세요');
            return;
        }
        
        const futureMeetings = filter(userInfo?.partyMembers, pm => moment.tz(pm.party.meetedAt, 'Asia/Seoul').toISOString() >= moment.tz('Asia/Seoul').toISOString())
        if (futureMeetings.length > 0) {
            alert('아직 참석하지 않은 모임이 있어 탈퇴가 불가능합니다.');
            return;
        }

        if (window.confirm('정말 탈퇴하시겠습니까?')) {
            dispatch(loadingAction.loading(true));
            await axios.post(SERVER_ADDRESS + '/api/party-members/leave', {
                reason: form.reason,
            });
            dispatch(loadingAction.loading(false));
            alert('탈퇴가 완료되었습니다.');
            dispatch(authAction.logout());
            dispatch(userAction.logout());
            navigate('/');
        }
    }, [form.reason, userInfo?.partyMembers]);

    return (
        <Container>
            <h2>서비스 탈퇴</h2>
            <div style={{width: '100%', maxWidth: '440px', marginTop: '30px', marginBottom: '30px'}}>
                <FormComponent title="탈퇴 사유" id="reason" form={form} onChange={onChange} />
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <LogoutButton onClick={handleLeave}>탈퇴하기</LogoutButton>
            </div>
        </Container>
    );
};

// Styled-Components를 사용하여 스타일 정의
const Container = styled.div`
  font-family: Noto Sans KR, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  color: #333;
`;

const LogoutButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; 

  &:hover {
    background-color: #c82333;
  }
`;
