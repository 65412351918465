import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {GET_PARTY_DETAIL} from "../../query/partyQuery";
import {useMutation, useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import moment from "moment-timezone";
import {loadingAction} from "../../redux/loadingReducer";
import {genderToString, 비속어체크} from "../../toolbox/format";
import {useForm} from "../../toolbox/hook";
import {CREATE_COMMENT, DELETE_COMMENT} from "../../query/mutationQuery";
import {isEmpty} from "lodash";
import {Comment} from "../components/Comment";
import {UserProfile} from "../components/UserProfile";
import {PositiveButton} from "../my/PartyReqPage";
import {getLoginLink} from "../../toolbox/kakao";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import { useMemo } from "react";

const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  //font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px 12px;
  color: #333;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  gap: 18px;
  //height: 100vh;

  .meeting-details {
    background: white;
    padding: 20px 20px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }

  .meeting-info {
    text-align: center;
    margin-bottom: 20px;
  }

  .meeting-info h2 {
    font-size: 1.5em;
    color: #4a4a4a;
  }

  .meeting-info p {
    font-size: 1em;
  }

  .attendees {
    text-align: center;
  }

  .attendees h3 {
    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .attendee-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: center;
    //gap: 20px;
  }

  .guide {
    margin-top: 40px;
    display: flex;
    color: blue;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }
  //.attendee {
  //  background: #f3f3f3;
  //  padding: 10px;
  //  border-radius: 10px;
  //  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  //  text-align: center;
  //  width: 120px;
  //  position: relative;
  //  display: flex;
  //  flex-direction: column;
  //  flex-grow: 1;
  //}
  //
  //.attendee-list a {
  //  display: flex;
  //}
  //
  //.attendee img {
  //  border-radius: 50%;
  //  width: 100px;
  //  height: 100px;
  //  object-fit: cover;
  //  margin-bottom: 10px;
  //}
  //
  //.attendee p {
  //  margin: 0;
  //  font-size: 1em;
  //  color: #333;
  //  white-space: pre-wrap;
  //  word-break: break-all;
  //  flex-grow: 1;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //}
  //
  //.host {
  //  border: 2px solid #3498db;
  //}
  //
  //.host-label {
  //  position: absolute;
  //  top: 5px;
  //  right: 5px;
  //  background: #3498db;
  //  color: white;
  //  padding: 2px 5px;
  //  border-radius: 3px;
  //  font-size: 0.8em;
  //}

  .comments-section {
    text-align: left;

    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }
  
  .comments-list {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .comment-form {
    display: flex;
    flex-direction: column;
  }

  .comment-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .comment-submit {
    padding: 10px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .comment-submit:hover {
    background: #2980b9;
  }
`
export const PartyDetailPage = () => {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const partyId = params?.id;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [partyDetail, setPartyDetail] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const [commentList, setCommentList] = useState(null);
    const commentRef = useRef();

    const {form: commentForm, onChange} = useForm({
        body: '',
    });

    const {data: queryData, refetch} = useQuery(GET_PARTY_DETAIL, {
        fetchPolicy: "cache-first",
        skip: partyId === undefined,
        variables: {id: partyId},
    });

    const chatRoomId = useMemo(() => {
        return partyDetail?.chat?.id;
    }, [partyDetail]);

    const chatRoomSize = useMemo(() => {
        return partyDetail?.chat?.messages?.length;
    }, [partyDetail]);

    useEffect(() => {
        if (partyDetail && location.hash === '#comment') {
            const timer = setTimeout(() => {
                if (commentRef.current) {
                    window.scrollTo({
                        top: commentRef.current.offsetTop,
                        behavior: 'smooth',
                    })
                    commentRef.current.focus();
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [location.hash, partyDetail])

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (queryData?.party?.data) {
            const payload = flatEntity(queryData.party.data);
            setPartyDetail(payload);
            setCommentList(payload.comments);
        }
    }, [queryData]);

    useEffect(() => {
        const timer = setInterval(() => {
            renderTimeLeft();
        }, 1000);
        renderTimeLeft();
    }, [partyDetail]);

    const renderTimeLeft = () => {
        if (partyDetail) {
            const meet = moment.tz(partyDetail.meetedAt, 'Asia/Seoul');
            const now = moment.tz('Asia/Seoul');
            if (now < meet) {
                let diff = meet.diff(now);
                let duration = moment.duration(diff);

                const days = Math.floor(duration.asDays());
                const hours = duration.hours();
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                const formattedDays = days ? `${days}일 ` : '';
                const formattedHours = (days || hours) ? `${hours}시간 ` : '';
                const formattedMinutes = (days || hours || minutes) ? `${minutes}분 ` : '';
                const formattedSeconds = (days || hours || minutes || seconds) ? `${seconds}초 ` : '';

                const left = `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`.trim();
                setTimeLeft(left);
            } else {
                setTimeLeft(null);
            }
        }
    }

    if (!partyDetail) {
        return <div>

        </div>
    }

    const onClickComment = () => {
        let body = commentForm.body;
        if (isEmpty(tokenInfo)) {
            alert('로그인이 필요합니다.');
            return;
        }
        if (비속어체크(body)) {
            alert('부적절한 단어가 포함되어있습니다.');
            return;
        }

        dispatch(loadingAction.loading(true));
        axios.post(SERVER_ADDRESS + '/api/comments/toParty', {
            body: body,
            target: partyId,
        }).then((res) => {
            onChange({id: 'body', value: ''});
            refetch();
            dispatch(loadingAction.loading(false));
        });
    }

    return <PageWrap>
        <div className="meeting-details">
            <div className="meeting-info">
                <h2>모임 정보</h2>
                <p><strong>모임 시간:</strong> {moment.tz(partyDetail.meetedAt, 'Asia/Seoul').format('M월 D일 HH:mm')} {timeLeft? `(${timeLeft} 남음)`: ''}</p>
                <p><strong>모임 장소:</strong> {partyDetail.restaurantName} <a href={partyDetail.placeLink} rel="noopener noreferrer" target="_blank">[오시는 길]</a></p>
            </div>
            <div className="attendees">
                <h3>참석자</h3>
                <div className="attendee-list">
                    {partyDetail.members.map(member =>
                        <UserProfile u={member.user} partyId={partyId} />
                    )}
                </div>
            </div>
            <div className="guide">
                <a href="https://copper-abrosaurus-467.notion.site/e54eb17c1906431793996892aa73089d?pvs=4" rel="noopener noreferrer" target="_blank">
                    <PositiveButton>디너모임 가이드 보러가기</PositiveButton>
                </a>
                <Link to="/question">
                    <PositiveButton>랜덤대화주제 뽑으러 가기</PositiveButton>
                </Link>
                {chatRoomId && <Link to={`/my/chat/${chatRoomId}`}>
                    <PositiveButton>채팅하기</PositiveButton>
                </Link>}
            </div>
        </div>
        <div className="comments-section">
            <h3>댓글 {commentList ? commentList.length: 0}개</h3>
            <div className="comments-list">
                {commentList && commentList.map((c, index) => (
                    <Comment comment={c} canDelete={false} onDelete={() => refetch()} />
                ))}
            </div>
            <div className="comment-form">
                <textarea ref={commentRef} className="comment-input" value={commentForm.body} onChange={(e) => onChange({id: 'body', value: e.target.value})} placeholder="다른 참석자들과 대화를 나눠보세요."></textarea>
                <button className="comment-submit" onClick={onClickComment}>댓글 작성</button>
            </div>
        </div>
    </PageWrap>
}
