import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {createMigrate, persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import {authReducer} from "./authReducer";
import {loadingReducer} from "./loadingReducer";
import {userReducer} from "./userReducer";
import {sessionGuardReducer} from "./sessionGuardReducer";
import {toastReducer} from "./toastReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    loading: loadingReducer,
    toast: toastReducer,
    sessionGuard: sessionGuardReducer,
})

const persistConfig = {
    key: 'dining',
    version: 0,
    storage,
    whitelist: ["auth", "sessionGuard"],
    migrate: createMigrate({
        // 2: (state) => {
        //     // strapi v4로 넘어가면서 id의 형식이 바뀌었으므로 auth 리셋
        //     return {
        //         path: state.path,
        //         guestNoti: state.guestNoti,
        //     }
        // }
    })
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export const persistor = persistStore(store);
