import BadwordFilter from "badwords-ko";

export const genderToString = (gender) => {
    if (gender === 'MALE') {
        return '남성';
    }
    if (gender === 'FEMALE') {
        return '여성';
    }
    return '미설정';
}

export const 비속어체크 = (payload) => {
    const badwordFilter = new BadwordFilter();
    return badwordFilter.isProfane(payload);
}

export const toPhoneFormat = (value) => {
    let result = value.replace(/[^0-9]/g, "");
    result = result.slice(0, 11);
    if (result.length === 10) {
        result = `${result.slice(0,3)}-${result.slice(3,6)}-${result.slice(6,10)}`;
    } else if (result.length > 7) {
        result = `${result.slice(0,3)}-${result.slice(3,7)}-${result.slice(7,11)}`;
    } else if (result.length > 3) {
        result = `${result.slice(0,3)}-${result.slice(3,7)}`;
    }
    return result;
}

export const commaNum = (number) => {
    if (number) {
        return Math.round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return "0"
    }
}