import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {genderToString} from "../../toolbox/format";
import {HelpBtn} from "../components/FormComponent";
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import moment from "moment-timezone";
import {PrimaryButton} from "../components/CommonComponents";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {useMutation} from "@apollo/client";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {filterAvailableCoupon} from "../../toolbox/entity";

const Coupon = ({c}) => {
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const [updateUser] = useMutation(UPDATE_USER);

    const handleUse = async () => {
        const response = await axios.post(SERVER_ADDRESS + '/api/coupons/useCoupon', {
            couponId: c.id,
        });
        if (response.data.result === 'error') {
            alert(response.data.msg);
        } else if (response.data.result === 'success') {
            const payload = {
                refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                variables: {
                    id: tokenInfo.id,
                    data: {
                        // autoEnter: userInfo.autoEnter,
                    }
                }
            };
            await updateUser(payload);
            alert('쿠폰이 정상적으로 사용되었습니다.');
        }
    }
    return <_Coupon>
        <div className="profile">
            <div className="icon">
                <LoyaltyIcon />
            </div>
            <div className="attendee-info">
                <div className="nickname">
                    <div>{c?.name}</div>
                    {/*{score && <div className="score" onClick={onScore}>{score}</div>}*/}
                </div>
                {c?.description && <div>{c.description}</div>}
                {c?.expiresAt && <div style={{display: 'flex', gap: '4px', alignItems: 'center', marginTop: '42x', marginLeft: '4px', color: 'gray', fontSize: '0.8em'}}>
                    <span>사용기한 : {moment.tz(c.expiresAt, 'Asia/Seoul').format('YYYY년 MM월 DD일 HH:mm:ss')}까지</span>
                </div>}
            </div>
            <PrimaryButton onClick={handleUse}>사용하기</PrimaryButton>
        </div>
    </_Coupon>
}

export const CouponPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    // const [coupons, setCoupons] =

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    return <_Wrap>
        <div className="title">
            <h3>쿠폰함</h3>
        </div>
        <div className="coupons">
            <div className="coupon-list">
                {userInfo?.coupons && filterAvailableCoupon(userInfo).map(c =>
                    <Coupon c={c} />
                )}
                {(userInfo?.coupons && filterAvailableCoupon(userInfo).length === 0) && <div style={{textAlign: 'center'}}>
                    보유한 쿠폰이 없습니다.
                </div>}
            </div>
        </div>
    </_Wrap>
};



const _Coupon = styled.div`
  background: rgb(243, 243, 243);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  width: 100%;
  
  
  .icon {
    display: flex;
    align-items: center;
  }
  .profile {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .profile-img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
  }

  .attendee-info {
    flex-grow: 1;
    display: flex;
    gap: 4px;
    flex-direction: column;
    text-align: left;
  }

  .nickname {
    display: flex;
    gap: 4px;
    font-size: 1em;
    font-weight: bold;
    margin-left: 4px;
    color: #333;
    
    .score {
      cursor: pointer;
    }
  }

  .bio {
    font-size: 0.8em;
    display: block;
    display: -webkit-box;
    margin: 5px 0 0;
    padding: 5px; /* 자기소개 문구에 내부 여백 추가 */
    background: rgba(255, 255, 255, 0.8); /* 밝은 배경색 추가 */
    color: #555; /* 텍스트 색상 설정 */
    border-radius: 5px; /* 모서리를 둥글게 설정 */
    white-space: pre-wrap; /* 줄 바꿈 허용 */
    word-break: break-all; /* 단어가 길 경우 줄 바꿈 허용 */
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .host-label {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #3498db;
    color: white;
    padding: 2px 7px;
    border-radius: 8px;
    font-size: 0.8em;
  }
`

const _Wrap = styled.div`
  background: white;
  padding: 20px 20px 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  //width: 98%;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 579px;

    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .coupons {
    margin-left: auto;
    margin-right: auto;
    max-width: 579px;
  }

  .coupon-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`
