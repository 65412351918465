import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styled from "styled-components";
import {Autoplay} from "swiper/modules";
import SwiperCore from "swiper";

SwiperCore.use([Autoplay]);

const ImageSlider = ({ images }) => {
    const extendedImages = [...images, ...images, ...images, ...images];
    return (
        <_SwiperStyle>
            <Swiper
                loop={true}
                autoplay={true}
                centeredSlides={true}
                speed={2000}
                spaceBetween={20}
                slidesPerView={"auto"}
                // pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
            >
                {extendedImages.map((image, index) => (
                    <SwiperSlide key={index}>
                        <a href={image.link} target="_blank">
                            <img src={image.url} alt={`slide-${index}`} />
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>
        </_SwiperStyle>
    );
};


const _SwiperStyle = styled.div`
  > .swiper {
    .swiper-slide {
      width: 297px;
      height: 264px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }


      @media (min-width: 450px) {
        width: 360px;
        height: 320px;
      }
      
      @media (min-width: 768px) {
        width: 495px;
        height: 440px;
      }
    }
  }
`

export default ImageSlider;