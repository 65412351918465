import {Link, useLocation} from "react-router-dom";
import styled from "styled-components";
import {filter, isEmpty} from "lodash";
import {getLoginLink} from "../toolbox/kakao";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useRef, useState} from "react";
import {authAction} from "../redux/authReducer";
import {userAction} from "../redux/userReducer";
import {filterAvailableCoupon} from "../toolbox/entity";
import {Badge} from "@mui/material";
import axios from "axios";
import {NotiPopper} from "./NotiPopper";
import {SERVER_ADDRESS} from "../index";
import {isAdmin} from "../toolbox/logic";


export const Header = ({fullscreen}) => {
    const location = useLocation();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const hasLoggedIn = !isEmpty(tokenInfo);
    const dispatch = useDispatch();


    const [notiOpen, setNotiOpen] = useState(false);
    const [notiRef, setNotiRef] = useState();
    const [chatRooms, setChatRooms] = useState(null);
    const pollInterval = useRef(null);

    const refetch = () => {
        if (hasLoggedIn) {
            axios.post(SERVER_ADDRESS + '/api/chat-rooms/findMyRooms', {}).then((res) => {
                setChatRooms(res.data);
            });
        }
    }

    useEffect(() => {
        refetch();
    }, []);

    const startPolling = useCallback((interval) => {
        clearInterval(pollInterval.current);
        pollInterval.current = setInterval(() => {
            refetch();
        }, interval);
    }, [refetch]);

    const handleVisibilityChange = () => {
        if (document.hidden) {
            startPolling(60000);
        } else {
            document.title = `우연한 디너`;
            startPolling(2000);
        }
    };

    useEffect(() => {
        startPolling(2000);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            clearInterval(pollInterval.current);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [startPolling]);

    const getUnread = (room) => {
        const lastMessage = room?.lastMessage?.sentAt;
        if (lastMessage) {
            const read = room.readAt?.[tokenInfo.id];
            if (read && lastMessage <= read) {
                return false;
            }
            return true;
        }
        return false;
    }


    const getBadgeCount = () => {
        if (hasLoggedIn && chatRooms) {
            return filter(chatRooms, r => getUnread(r)).length;
            // return getNotificationBadgeCount(userInfo);
            // const noti = filterNotifications(userInfo?.notifications);
            // const res = countBy(noti, (n) => n.createdAt > userInfo.notification_readed_at);
            // return res.true;
        }
        return 0;
    }

    return <>
        <_Header isFullScreen={fullscreen}>
        <div className="container">
            <Link to="/">
                <Logo>
                    <img className="logo-icon" src="/logo.png" />
                    {/*<span className="logo-icon">🍴</span>*/}
                    {/*<span className="logo-text">우연한 디너</span>*/}
                </Logo>
            </Link>
            <div style={{display: 'flex'}}>

                <IconWrap onClick={(e) => {
                    setNotiRef(e.target);
                    setNotiOpen((prev) => !prev);
                }}>
                    <Badge className="nav-item_icon" badgeContent={getBadgeCount()} max={9} color={"primary"}>
                        <Icon src="/noti.svg" />
                    </Badge>
                </IconWrap>

                <HamburgerBtn/>
                {/*{isLoggedIn ?*/}
                {/*    <Link to="/my"><HeaderButton>마이 페이지</HeaderButton></Link>:*/}
                {/*    <Link to={getLoginLink(location)}><HeaderButton>로그인</HeaderButton></Link>}*/}
            </div>
        </div>
    </_Header>
        <NotiPopper open={notiOpen} onClose={() => setNotiOpen(() => false)} anchorEl={notiRef} chatRooms={chatRooms}/>
    </>
}

const HamburgerBtn = () => {
    const [open, setOpen] = useState(false);
    return <_HamburgerBtn id="hamburgerBtn" onClick={() => setOpen(true)}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <HamburgerMenu onClose={() => setOpen(false)} open={open}/>
    </_HamburgerBtn>
}

const IconWrap = styled.div`
  width: 60px;
  height: 60px;
  padding: 14px;
  cursor: pointer;
`

const Icon = styled.img.attrs(props => ({
    alt: `Menu`,
}))`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const _HamburgerBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60px;
  height: 60px;
  padding: 14px;
  cursor: pointer;
  .bar {
    height: 3px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.textColor};
    border-radius: 10px;
    pointer-events: none;
  }
`

const HamburgerMenu = ({open, onClose}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const isLoggedIn = !isEmpty(tokenInfo);
    const navRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.id !== 'hamburgerBtn' && event.target.tagName !== 'NAV') {
                onClose();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [open]);

    const handleLogout = () => {
        dispatch(authAction.logout());
        dispatch(userAction.logout());
    }

    return <_HamburgerMenu open={open} ref={navRef}>
        <ul className="nav-list">
            {isLoggedIn ? <>
                <li className="nav-item"><Link to="/about"><div className="item">우연한디너란?</div></Link></li>
                <Line />
                <li className="nav-item"><Link to="/my"><div className="item">디너 모임 신청하기</div></Link></li>
                <li className="nav-item"><Link to="/my/party"><div className="item">나의 디너 모임</div></Link></li>
                <li className="nav-item"><Link to="/my/evade"><div className="item">회피 리스트</div></Link></li>
                <li className="nav-item"><Link to="/my/chat_start"><div className="item">우연한 채팅방</div></Link></li>
                <li className="nav-item"><Link to="/my/chat"><div className="item">나의 채팅방 리스트</div></Link></li>
                <Line />
                <li className="nav-item"><Link to={"/user/" + tokenInfo.id}><div className="item">내 프로필</div></Link></li>
                <li className="nav-item"><Link to="/my/bookmark"><div className="item">북마크 한 프로필</div></Link></li>
                <li className="nav-item"><Link to="/my/bookmark_more"><div className="item">프로필 둘러보기</div></Link></li>
                <Line />
                <li className="nav-item"><Link to="/my/info"><div className="item">마이 페이지</div></Link></li>
                <li className="nav-item"><Link to="/my/coupon"><div className="item">쿠폰함 <_Circle>{filterAvailableCoupon(userInfo).length}</_Circle></div></Link></li>
                <li className="nav-item"><div className="item" onClick={handleLogout}>로그아웃</div></li>
            </>:<>
                <li className="nav-item"><Link to="/about"><div className="item">우연한디너란?</div></Link></li>
                <Line />
                <li className="nav-item"><Link to="/my"><div className="item">디너 모임 신청하기</div></Link></li>
                <li className="nav-item"><Link to="/my/bookmark_more"><div className="item">프로필 둘러보기</div></Link></li>
                <Line />
                <li className="nav-item"><Link to={getLoginLink(location)}><div className="item">로그인</div></Link></li>
                <li className="nav-item"><Link to={getLoginLink(location)}><div className="item">회원가입</div></Link></li>

            </>}
        </ul>
    </_HamburgerMenu>
}

const Line = styled.div`
  margin: 8px 0 8px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
`
const _Circle = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: darkgray;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`
const _HamburgerMenu = styled.nav`
  position: fixed;
  top: 0;
  width: 250px;
  height: 100%;
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  //padding: 2rem;
  padding-top: 10px;
  z-index: 999;
  cursor: auto;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  right: ${({ open }) => (open ? '0' : '-100%')};

  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
  .nav-item {
    .item {
      cursor: pointer;
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    //margin: 12px;
    //margin: 1rem 0;
  }

  .nav-item a {
    color: ${({ theme }) => theme.colors.textColor};
    text-decoration: none;
  }
`

const _Header = styled.div`
  display: block;
  height: 77px;

  @media only screen and (max-width: 767px) {
    height: 61px;
  }
  
  height: ${(props) => (props.isFullScreen ? '0 !important' : undefined)};
  .container {
    z-index: 100;
    width: 100%;
    position: fixed;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    top: 0;

    @media only screen and (max-width: 767px) {
      padding: 0 5px;
    } 
  }
`

const HeaderButton = styled.button`
  font-family: "Elice Digital Baeum", sans-serif;
  cursor: pointer;
  margin: 16px 0;
  display: inline-block;
  //padding: 6px 20px;

  background-color: #FFFFFF; /* 흰색 배경 */
  border: 2px solid #FFFFFF; /* 흰색 테두리 */
  color: #000000;
  // color: ${({ pink }) => (pink ? '#FF69B4' : '#000')};

  border-radius: 20px; /* 둥근 모서리 */
  padding: 5px 15px; /* 버튼 패딩 */
  text-transform: uppercase; /* 대문자 */
  font-weight: bold; /* 텍스트 굵게 */
  transition: background 0.3s ease, color 0.3s ease; /* 전환 효과 */
`;

const Logo = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;

  font-size: 2.5em;
  @media only screen and (max-width: 767px) {
    font-size: 2em;
  }
  @media only screen and (max-width: 470px) {
    font-size: 1.5em;
  }

  .logo-icon {
    padding: 8px;
    width: 200px;
    margin-right: 10px;
    //animation: spin 4s linear infinite;
  }

  // .logo-text {
  //   font-weight: bold;
  //   color: ${({ theme }) => theme.colors.textColor};
  //   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  // }
  //
  // @keyframes spin {
  //   0% {
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     transform: rotate(360deg);
  //   }
  // }
`
