import {useLocation} from "react-router-dom";
import styled, {css} from "styled-components";
import {find} from "lodash";
import {openKakaoChat} from "../toolbox/kakao";

const HIDDEN_PATH = ["/my/chat"]
const _KakaoFloat = ({className}) => {
    const location = useLocation();
    return <div className={className} hidden={find(HIDDEN_PATH, (p) => location.pathname.startsWith(p))} onClick={() => openKakaoChat("consult-btn_open_chat", null)}>
        <img src="/kakaotalk-consult.png" alt="톡상담"/>
    </div>
}

export const KakaoFloat = styled(_KakaoFloat)`
  width: 104px;
  transition: ease-in-out 0.2s;

  position: fixed;
  z-index: 1300;
  right: 16px;
  bottom: 16px;
  
  &:hover {
    cursor: pointer;
    transform: translateY(-4px);
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
