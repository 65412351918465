import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {setContext} from "@apollo/client/link/context";
import {ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache} from "@apollo/client";
import axios from "axios";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux/store";

export const SERVER_ADDRESS = 'https://excitingfriday-a2389954d904.herokuapp.com';
// export const SERVER_ADDRESS = 'http://localhost:1337'

export const TOSS_CLIENT_KEY = "live_ck_oeqRGgYO1r5qYgyzKzprQnN2Eyaz";
export const TOSS_SUB_CLIENT_KEY = "live_ck_Z0RnYX2w532DBAQ0NkPrNeyqApQE";

const setupAxios = (axios, store) => {
    const exceptApi = ['/api/auth'];
    axios.interceptors.request.use(
        config => {
            const {
                auth: {token}
            } = store.getState();

            // 우리 API 서버 호출시에만 authToken을 넣어준다. 예외 API의 경우에는 넣지 않는다. --blackcat
            if (token && config.url.startsWith(SERVER_ADDRESS)) {
                const isExcept = exceptApi.reduce((acc, current) =>
                    acc + config.url.startsWith(SERVER_ADDRESS + current) ? 1 : 0, 0);
                if (!isExcept) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            }
            return config;
        },
        err => Promise.reject(err)
    );
}

const authLink = setContext((_, { headers }) => {
    const token = store.getState().auth.token;
    const tokenInfo = store.getState().auth.tokenInfo;
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const httpLink = createHttpLink({ uri: SERVER_ADDRESS + '/graphql' });

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
    },
    query: {
        fetchPolicy: 'network-only',
    }
}

const client = new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions,
});

setupAxios(axios, store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ApolloProvider>
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
