import styled from "styled-components";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getLoginLink} from "../../toolbox/kakao";
import {useDispatch, useSelector} from "react-redux";
import {Timer} from "./Timer";
import moment from "moment-timezone";
import "moment/locale/ko";
import {useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import {useEffect, useState} from "react";
import {RecommendUsers} from "../components/RecommendUsers";
import {getUpcomingMeet} from "../../toolbox/time";
import RestSwiper from "../components/RestSwiper";
import {GET_RESTAURANTS} from "../../query/partyQuery";
import queryString from "query-string";
import {sessionGuardAction} from "../../redux/sessionGuardReducer";
import {toastAction} from "../../redux/toastReducer";
import {isEmpty} from "lodash";
import ReactMarkdown from "react-markdown";


export const AboutPage = ({}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : {};
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    return <PageWrap>
        <ReactMarkdown children={`## **우연한디너, 정체가 뭐지?**
우디 창업자 참신한시바가 설명해줄게.

우디는 각종 데이팅앱, 취미모임, 와인모임 등을 섭렵한 마스터가 만든 호감형 커뮤니티야.

솔직히 많은 데이팅 앱을 써봤지만, “유료 결제 헛수고”, “프로필 사기”, “이상한 사람” 등 너무 많은 시간과 비용 소모에 현타가 왔어.

취미모임, 와인모임 등도 마찬가지야. 나는 새로운 ‘사람’을 만나고 싶은건데, 취미를 가져야하거나 술을 마셔야하거나 레크레이션을 해야하는 등 부담스러운 모임들 뿐이었지.

모임에 나가도 정작 참석자들끼리 어떤 사람인지도 모르고, 서로에 대한 대화는 거의 하지도 못하고 끝나는 경우가 태반이었어.

“**사람이 주제인 모임**을 만들 수 없을까?” 고민 끝에 우디를 만들었어. 서로 호감을 가질만한 사람들끼리 부담없이 식사하면서 대화에 집중할 수 있는 모임을 말이야.

우디는 시간맞춰 참석만 한다면 소확행 같은 확실한 만남을 보장해줘. 만나서 직접 대화를 나눠봐야 어떤 사람인지 판단이 될거야. 다른 앱은 돈과 노력만 쓰고 헛탕이잖아.

데이트라기보다 여사친, 남사친으로 알아가길 바래. 그리고 모두의 시간은 소중하자나? 서로 호감을 느낄 확률이 높은 사람들끼리 같이 식사할 수 있도록 AI의 도움을 받기로 결정했지.

그럼, 이제 우디에 대해 자세히 소개해줄게.

![참신한시바가 영감을 받은 프렌즈](https://one2-social-dining.s3.ap-northeast-2.amazonaws.com/b_Oi_C_Da_QUI_Bi_QI_69w_H_yphw_Hf_Oyu_F_Se_v99zh_E_Ww_Y58hmhul_K_Dk8i_Md_Fh7df90l_K_Gl35_O_Fjse5f_T_Cvh1lz_Hi_XA_7b65672a67.webp)
참신한시바가 영감을 받은 '프렌즈'
## **매주 목요일 저녁 7시, 강남역**
우연한디너는 매주 목요일 저녁 7시부터 9시까지 강남역 근처 식당에서 진행 돼.

알고리즘을 통해 서로 호감을 가질만한 4~6인을 하나의 디너모임으로 구성해줄거야.

왜 강남역이냐고? 다른 지역을 선택할 수 있는 옵션을 제공하면 알고리즘이 온전히 사람의 구성에 집중할 수 없거든. 예를들어, 나랑 제일 잘 맞는 사람이 다른 지역을 선택하면 불가피하게 차순위인 사람과 매칭되는 문제가 생겨버려.

나중에 유저가 충분히 많아지면 다른 지역도 하나씩 추가될 예정이야!
## **좋아, 어떻게 신청해?**
캘린더를 키고 약속 없는 목요일이 언제인지 찾아봐. 그날 열리는 디너모임에 참석 신청만 누르면 끝. 신청 후 몇일 내로 디너모임이 확정되면, 알림톡을 통해 문자가 발송될거야. ‘나의 디너모임’에서 식당과 참석 멤버들의 프로필을 확인할 수 있어.

모임확정 알림톡을 받은 이후로는 취소 및 환불이 불가하다는 점 유의해줘!
## **가장 효율적으로 남사친/여사친을 만날 수 있게 도와줄게!**
‘우연한’ 디너지만 그렇다고 아무나 만나라는 건 아냐. 모임 후 평가 알고리즘을 통해 서로 호감을 느낄만한 사람들끼리 식사를 같이 하게 될거야. 운이 좋다면 이성친구를 넘어 이상형을 만날수도?

우디는 협업필터링(Collaborative Filtering)이라는 알고리즘에 기반한 매칭을 시도해.

예를 들어 네가 3명에 대해 좋은 평가를 했다면 그 3명에 대해 비슷하게 평가한 사람들을 찾고, 그 사람들이 좋게 평가한 사람을 찾는 방식이야.

나와 비슷한 사람들이 다른 사람과 다르게 특히 더 좋게 평가한 사람을 찾으므로 단순히 좋게 평가되는 사람들의 모임이 아니라, “서로 호감을 느낄 사람들 집단”끼리 엮어주는 역할을 하게 되어있어.

실제는 좀 더 복잡하지만, 대형 포탈에서 영화추천 알고리즘, 스타트업 CTO, 알고리즘 대회 입상한 개발자가 짠 알고리즘이니까 좀 믿어봐도 돼. (대신 너의 평가와 호불호가 없다면 답이 없다?)

내가 더 많은 피드백을 하고 피드백을 받을 수록 더 디테일한 모임 구성이 이뤄질거야. 또한 상대방의 예상 호감도도 반영하여 양쪽의 평가가 좋은 모임으로 구성하려고 하기 때문에 서로의 호감도를 같이 반영하여 전체 호감도가 최대값이 되도록 만들지.

![스크린샷 2024-07-31 오후 3.29.04.png](https://one2-social-dining.s3.ap-northeast-2.amazonaws.com/2024_07_31_3_29_04_fb7e59bf91.png)
## **우연한 디너모임, 2시간 동안 어떻게 진행될까?**
디너모임은 특별한 형식에 구애없이 자연스럽게 식사하면서 대화를 나누는 방식이야. 전문 진행자가 레크레이션이나 특별한 진행을 하지 않으니까 I인 사람들도 부담없이 참석할 수 있지.

단, 서로 처음보는 만큼 어색함을 풀 수 있는 간단한 모임가이드가 알림톡으로 제공될거야. 가끔 대화소재가 떨어졌을 때 가이드 안의 질문리스트에서 맘에드는 질문을 던져보면 도움이 될테니 잘 활용하길 바랄게!

아래는 우디에서 추천하는 모임 진행순서야. 여러 베타테스트 모임을 통해 검증한 최적의 방식이니 참고해줘.

[디너모임 진행순서]
식당 도착 (19:00)
메뉴 주문하기 (19:00 ~ 19:10)
아이스브레이킹 (19:10 ~ 19:30)
자기소개 / 자유대화 (19:30~20:00)
자리 바꾸기 (20:00)
자유대화 / 질문타임 (20:00~21:00)
디너모임 종료  (21:00)
참석자 피드백 하기 (다음 날)

모임 후 피드백으로 너의 취향을 알려주면 다음 모임부터 반영될꺼야. 잘 맞지 않는다는 평가를 하거나 받는다 하더라도, 이건 취향의 영역이니 마음 상할 필요가 전혀 없고 오히려 다음 모임을 위해서 꼭 솔직하게 해줘야해.

그리고 혹시 이번 모임에 맘에 드는 참석자가 없더라도, 좋은 평가를 받아서 호감도가 높은 사람이 될 수 있는 기회라고 생각해줘!

## **맘에 드는 사람과 모임 후 연락을 이어가려면?**

우디는 모임 후 평균 이상의 평가를 한 사람과 다음 모임에 다시 우연히 만날 수 있어.

그렇기 때문에, 처음엔 단순히 좋은 느낌이었다면 이후 우연히 2번, 3번째 만났을 때 좀 더 여러가지 대화를 하고 점차 친해질 수 있어. 또는 처음엔 큰 호감이 없었더라도 이후에 우연히 2, 3번 만나서 대화를 하면서 자연스럽게 친해지는 계기가 될 수 있지.

그리고 우디의 프로필은 ‘전체공개프로필’과 ‘비공개프로필’로 구분되어 있는데, 비공개프로필에 핸드폰번호나 SNS를 적어서 맘에 드는 참석자들과 모임 후 연락을 이어갈 수도 있어.

모임 종료 후 상대방의 프로필에 연락처가 보인다면, 내가 좋은 평가를 받았다는 의미니까 축하해! 하지만 안 보이더라도 너무 실망할 필요는 없어. 상대방이 아직 비공개프로필에 연락처를 남기지 않았을 수 있고, 내 연락처를 보고 먼저 연락이 올 수도 있으니까 너무 맘에 담아두진 말아줘.

## 저사람이랑 밥먹고 싶다면? 북마크로 만날 확률 높이기

‘프로필 둘러보기’에서 프로필을 구경하다가 관심이 가는 우디너를 발견하면, 우측 상단의 북마크를 눌러봐. 내가 북마크한 우디너와 같은 디너모임에 배정될 확률을 높일 수 있으니, 적극적으로 활용하기를 추천할게!

상대방은 누가 북마크했는지 모르니까 부담스럽거나 걱정할 필요는 없어.

![프로필예시.png](https://one2-social-dining.s3.ap-northeast-2.amazonaws.com/_4e7794dcce.png)

## **예비우디너와 정식우디너**

우디의 유저는 예비우디너와 정식우디너로 구분 돼. 위에서 설명했듯이, 우디는 참석 후 피드백을 통해 알고리즘을 형성하는데, 참석 이력이 없거나 적으면 알고리즘이 정상적으로 작동할 수가 없어.

그래서 참석횟수를 기준으로 예비우디너와 정식우디너로 나뉘고, 예비우디너는 알고리즘의 혜택을 보지 못하기 때문에 정식우디너가 될 때까지 무료로 디너모임 참석이 가능해.

그리고 참석횟수를 채우더라도, 그 외에 다른 요건을 채우지 못하면 정식우디너가 되기까지 시간이 더 필요할 수 있어. 예비우디너로 남아서 무료로 새로운 우디너들과 어울리는 것도 나름 괜찮지.

정식우디너가 되려면? 디너모임 3회 이상 참석과 모임 후 평가점수, 매너점수 등 피드백 요건을 통과해야해.

## 퇴근 후 심심해 하는 친구에게 소개팅 대신 우디 참석권을 선물해봐!

우디 회원가입을 하면 초대권이 3장 생겨.

주변에 “퇴근하고 심심한데, 재밌는 일 없나”를 달고 사는 친구, 너무 집에만 있어서 심심해하는 친구에게 새로운 친구와 대화할 기회를 선물해줘!

내가 모임에도 데리고 나가고, 소개팅도 시켜주면 좋겠지만 쉽지 않은 거 알잖아?

초대를 받은 친구에게는 무료참석권이 생겨서 너에게 감사해할꺼야.

근데, 나한테는 보상이 없냐고? 그 친구가 모임에서 좋은 평가를 받을 때마다 나에게도 무료 참석권이 생겨. 그러니 매너좋고 괜찮은 친구에게 소개시켜줘야겠지?`}>
        </ReactMarkdown>


        <section className="footer">
            <div className="container">
                <h1>새로운 사람들과 저녁식사 어때요?<br/>지금 신청하고 우연한 만남을 경험해보세요</h1>
                <div style={{marginTop: '16px'}}>
                    <Link to={'/my'} className="cta-button">{`${getUpcomingMeet().format('M월 D일 (dd) HH:mm 저녁 같이하기')}`}</Link>
                    <p className="small-desc">※ 현재 모임은 강남역 인근에서 진행되고 있습니다.</p>
                </div>
            </div>
        </section>
    </PageWrap>
}
const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  color: #333;
  background: #f5f5f5;
  padding: 24px;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  white-space: pre-wrap;
  word-break: keep-all;
  
  img {
    max-width: 400px;
    width: 100%;
  }

  h2 {
    margin-top: 32px;
  }

  .footer {
    margin-top: 80px;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: keep-all;
    text-align: center;
    padding: 30px 20px 90px;
    gap: 10px;
    color: ${({ theme }) => theme.colors.textColor};
    background: white;
    z-index: 1;
    width: 100%;
  }
  
  .cta-button {
    margin: 16px 0 4px;
    display: inline-block;
    padding: 15px 30px;
    color: ${({ theme }) => theme.colors.backgroundColor};
    text-decoration: none;
    font-size: 1.2em;
    //transition: background 0.3s ease, transform 0.3s ease;
    //background-color: transparent;
    //background-image: linear-gradient(225deg, #E74011 0%, #EB5D40 100%);
    //border-radius: 30px;
    //box-shadow: 0 3px 35px 0 #EB5D40;

    transition: background 0.3s ease, transform 0.3s ease;
    // border: 1px solid ${({ theme }) => theme.colors.textColor};
    // background-color: ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.primary};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryDark};
    }
    //background-image: linear-gradient(225deg, ${({ theme }) => theme.colors.backgroundColor} 0%, ${({ theme }) => theme.colors.lightGray} 10%);
    border-radius: 30px;
    //box-shadow: 0 3px 35px 0 rgba(255, 105, 180, 0.5);
  }
`
