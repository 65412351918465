import {loadTossPayments} from "@tosspayments/payment-sdk";
import {SERVER_ADDRESS, TOSS_CLIENT_KEY} from "../../index";
import { v4 } from 'uuid';
import {getProduct} from "../../toolbox/product";
import {useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {isEmpty} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import queryString from "query-string";

const testData = [
    {
        "nickname": "허권",
        "geoLocation": {
            "longitude": 127.01850090538,
            "latitude": 37.5009525951681
        },
        "address": "서울 서초구 고무래로 89\t107동 803호",
        "birth": "1981-05-13"
    },
    {
        "nickname": "황선현",
        "geoLocation": {
            "longitude": 127.065454866939,
            "latitude": 37.5160104392125
        },
        "address": "서울 강남구 봉은사로111길 12\t1층",
        "birth": "1985-01-26"
    },
    {
        "nickname": "박예원",
        "geoLocation": {
            "longitude": 127.01850090538,
            "latitude": 37.5009525951681
        },
        "address": "서울 서초구 고무래로 89\t반포써밋 107-803",
        "birth": "1989-01-26"
    },
    {
        "nickname": "Lee",
        "geoLocation": {
            "longitude": 127.03177282095,
            "latitude": 37.5191015890793
        },
        "address": "서울 강남구 언주로147길 29-4\t지층2호",
        "birth": "1991-05-28"
    },
    {
        "nickname": "summer",
        "geoLocation": {
            "longitude": 126.734609462005,
            "latitude": 37.813956084663
        },
        "address": "경기 파주시 탄현면 웅지로110번길 68\t테라펜션",
        "birth": "1991-10-11"
    },
    {
        "nickname": "Hot Kimchi",
        "geoLocation": {
            "longitude": 127.064134113929,
            "latitude": 37.5166283511236
        },
        "address": "서울 강남구 영동대로114길 56\t래미안 502동 120호",
        "birth": "1969-10-07"
    },
    {
        "nickname": "다크엔젤",
        "geoLocation": {
            "longitude": 127.039349377307,
            "latitude": 37.5195258428087
        },
        "address": "서울 강남구 도산대로54길 50\t102호",
        "birth": "1965-02-14"
    },
    {
        "nickname": "김태형",
        "geoLocation": {
            "longitude": 127.070871480537,
            "latitude": 37.4891406792843
        },
        "address": "서울 강남구 개포로 516\t604동 704호",
        "birth": "1997-08-28"
    },
    {
        "nickname": "김재완",
        "geoLocation": {
            "longitude": 126.915557434129,
            "latitude": 37.6073437523445
        },
        "address": "서울 은평구 연서로 92\t403호",
        "birth": "1998-06-09"
    },
    {
        "nickname": "백지형",
        "geoLocation": {
            "longitude": 127.12366621502,
            "latitude": 37.4806047633012
        },
        "address": "서울 송파구 문정동 618\t하비오오피스텔 202동 C1014호",
        "birth": "1998-07-25"
    },
    {
        "nickname": "서상규",
        "geoLocation": {
            "longitude": 126.911884265219,
            "latitude": 37.5992418872232
        },
        "address": "서울 은평구 은평로3가길 30-8\t하이디빌리지 201호",
        "birth": "1986-04-11"
    },
    {
        "nickname": "최경현",
        "geoLocation": {
            "longitude": 126.958890462399,
            "latitude": 37.3985813679424
        },
        "address": "경기 안양시 동안구 관평로212번길 21\t304동 211호",
        "birth": "2000-04-25"
    },
    {
        "nickname": "최경근",
        "geoLocation": {
            "longitude": 127.096311574651,
            "latitude": 37.5055891083638
        },
        "address": "서울 송파구 백제고분로31길 32-9\t하남하우스 302호",
        "birth": "1990-05-01"
    },
    {
        "nickname": "J",
        "geoLocation": {
            "longitude": 126.902878107486,
            "latitude": 37.5273867773469
        },
        "address": "서울 영등포구 영신로 219\t",
        "birth": "1984-02-05"
    },
    {
        "nickname": "우독",
        "geoLocation": {
            "longitude": 126.826854458312,
            "latitude": 37.5374390152293
        },
        "address": "서울 양천구 남부순환로 328\t1002호",
        "birth": "1994-06-08"
    },
    {
        "nickname": "박민",
        "geoLocation": {
            "longitude": 127.109631685269,
            "latitude": 37.6165826807685
        },
        "address": "서울 중랑구 신내역로 165\t214동 1304호",
        "birth": "1982-09-18"
    },
    {
        "nickname": "김현서",
        "geoLocation": {
            "longitude": 127.111940722173,
            "latitude": 37.3514154458169
        },
        "address": "경기 성남시 분당구 미금로 215\t805동 1801호",
        "birth": "1998-01-03"
    },
    {
        "nickname": "인준",
        "geoLocation": {
            "longitude": 127.064153382762,
            "latitude": 37.480057578933
        },
        "address": "서울 강남구 선릉로 8\t212동 302호",
        "birth": "1997-06-27"
    },
    {
        "nickname": "캣냥🐱",
        "geoLocation": {
            "longitude": 127.055049080083,
            "latitude": 37.5010714128894
        },
        "address": "서울 강남구 선릉로72길 48\t301",
        "birth": "1989-08-07"
    },
    {
        "nickname": "이종빈",
        "geoLocation": {
            "longitude": 126.958860898711,
            "latitude": 37.5515726540579
        },
        "address": "서울 마포구 마포대로20길 26\t109동601호",
        "birth": "2000-10-06"
    },
    {
        "nickname": "안세환",
        "geoLocation": {
            "longitude": 126.905248135944,
            "latitude": 37.4980011100651
        },
        "address": "서울 영등포구 신길로13길 28-3\t1층",
        "birth": "1998-02-12"
    },
    {
        "nickname": "프리미엄 동결건조 펫간식",
        "geoLocation": {
            "longitude": 127.091279819348,
            "latitude": 37.5025961802137
        },
        "address": "서울 송파구 백제고분로28길 8-18\t2층",
        "birth": "1971-12-17"
    },
    {
        "nickname": "미노",
        "geoLocation": {
            "longitude": 126.878020542229,
            "latitude": 35.2086085468651
        },
        "address": "광주 북구 자라봉로 33\t102동709호",
        "birth": "1998-09-11"
    },
    {
        "nickname": "주종범",
        "geoLocation": {
            "longitude": 127.034068952144,
            "latitude": 37.4873602340563
        },
        "address": "서울 강남구 남부순환로351길 38-3\t201호",
        "birth": "1990-01-28"
    },
    {
        "nickname": "지우",
        "geoLocation": {
            "longitude": 127.001382412359,
            "latitude": 37.494127109551
        },
        "address": "서울 서초구 동광로47길 7\t2",
        "birth": "1989-03-05"
    },
    {
        "nickname": "하늘잉어",
        "geoLocation": {
            "longitude": 127.100730117136,
            "latitude": 37.4896147291453
        },
        "address": "서울 강남구 광평로51길 27\t401동 1008호",
        "birth": "1966-09-05"
    },
    {
        "nickname": "김상연",
        "geoLocation": {
            "longitude": 127.031630043287,
            "latitude": 37.5190490088157
        },
        "address": "서울 강남구 도산대로34길 21-5\t미르빌 204호",
        "birth": "1996-03-16"
    },
    {
        "nickname": "김유나",
        "geoLocation": {
            "longitude": 127.039580225923,
            "latitude": 37.4811230870926
        },
        "address": "서울 서초구 강남대로30길 32-33\tB동 702호",
        "birth": "1996-06-22"
    },
    {
        "nickname": "전병준",
        "geoLocation": {
            "longitude": 127.043818918752,
            "latitude": 37.6257556278015
        },
        "address": "서울 강북구 월계로37길 87-5\t101/1702",
        "birth": "2000-08-10"
    },
    {
        "nickname": "박부균",
        "geoLocation": {
            "longitude": 127.06903483437,
            "latitude": 37.4879144140576
        },
        "address": "서울 강남구 삼성로4길 17\t505동 1003호",
        "birth": "1996-09-22"
    },
    {
        "nickname": "김범주",
        "geoLocation": {
            "longitude": "127.050607073529",
            "latitude": "37.5762290631959"
        },
        "address": "서울 동대문구 전농동 690\t",
        "birth": "1999-06-14"
    },
    {
        "nickname": "석진",
        "geoLocation": {
            "longitude": 127.085060056102,
            "latitude": 37.5693726980749
        },
        "address": "서울 광진구 답십리로88길 35\tB102호",
        "birth": "1992-01-21"
    },
    {
        "nickname": "고병기",
        "geoLocation": {
            "longitude": 127.042341350466,
            "latitude": 37.4984540026144
        },
        "address": "서울 강남구 언주로75길 24\t501호",
        "birth": "1980-07-15"
    },
    {
        "nickname": "하얀호랑이",
        "geoLocation": {
            "longitude": 127.044171122327,
            "latitude": 37.4760186837456
        },
        "address": "서울 서초구 마방로10길 34-2\t502호",
        "birth": "1993-02-15"
    },
    {
        "nickname": "최지수(김원정.윤정)",
        "geoLocation": {
            "longitude": 127.042585684729,
            "latitude": 37.4955825872209
        },
        "address": "서울 강남구 역삼로34길 24\t은혜빌딩 B01호",
        "birth": "1984-01-28"
    },
    {
        "nickname": "Sol",
        "geoLocation": {
            "longitude": 126.907502240807,
            "latitude": 37.5594261460828
        },
        "address": "서울 마포구 월드컵로28길 4\t202호",
        "birth": "1987-01-11"
    },
    {
        "nickname": "허시온",
        "geoLocation": {
            "longitude": 126.975007273523,
            "latitude": 37.2725121417097
        },
        "address": "경기 수원시 권선구 상탑로39번길 27\t탑동 101호",
        "birth": "1998-02-17"
    },
    {
        "nickname": "선아나",
        "geoLocation": {
            "longitude": 127.077098519924,
            "latitude": 37.059029468657
        },
        "address": "경기 평택시 추담로 58-78\t1025-401",
        "birth": "1990-09-30"
    },
    {
        "nickname": "양진선",
        "geoLocation": {
            "longitude": 126.944491273195,
            "latitude": 37.5846147512232
        },
        "address": "서울 서대문구 통일로 395\t114동 213호",
        "birth": "1997-09-10"
    },
    {
        "nickname": "박준휘",
        "geoLocation": {
            "longitude": 127.14392267974,
            "latitude": 37.5964677495565
        },
        "address": "경기 구리시 원수택로64번길 33\t트윈팰리스 오피스텔동 920호",
        "birth": "2000-06-14"
    },
    {
        "nickname": "K.W_제임스",
        "geoLocation": {
            "longitude": 127.054577042471,
            "latitude": 37.5080698303367
        },
        "address": "서울 강남구 삼성로95길 14\t1층",
        "birth": "1978-01-14"
    },
    {
        "nickname": "시장파괴",
        "geoLocation": {
            "longitude": 127.053965331603,
            "latitude": 37.4749854831206
        },
        "address": "서울 강남구 논현로8길 62-5\t202호",
        "birth": "1988-08-23"
    },
    {
        "nickname": "마중물",
        "geoLocation": {
            "longitude": 126.929648071318,
            "latitude": 37.4914523441703
        },
        "address": "서울 관악구 봉천동 712-69\tA동402호",
        "birth": "1979-02-02"
    },
    {
        "nickname": "김선형",
        "geoLocation": {
            "longitude": 126.951638187547,
            "latitude": 37.4820210080697
        },
        "address": "서울 관악구 봉천로 466\t902",
        "birth": "1998-11-29"
    },
    {
        "nickname": "이정현",
        "geoLocation": {
            "longitude": 127.037258336381,
            "latitude": 37.4930576036066
        },
        "address": "서울 강남구 도곡로17길 24-3\t501",
        "birth": "1972-07-28"
    },
    {
        "nickname": "주혜",
        "geoLocation": {
            "longitude": 127.133553586857,
            "latitude": 37.5534818904404
        },
        "address": "서울 강동구 고덕로20길 28\t",
        "birth": "1996-04-19"
    },
    {
        "nickname": "이석범",
        "geoLocation": {
            "longitude": 127.028325815765,
            "latitude": 37.5336032834133
        },
        "address": "서울 강남구 압구정로29길 71\t13-202",
        "birth": "1994-10-28"
    },
    {
        "nickname": "김다윗",
        "geoLocation": {
            "longitude": 127.156229067152,
            "latitude": 37.6110195684339
        },
        "address": "경기 남양주시 도농로 34\t플루리움 510동 805호",
        "birth": "1998-11-11"
    },
    {
        "nickname": "ㆍ",
        "geoLocation": {
            "longitude": 127.043657164988,
            "latitude": 37.4952271994563
        },
        "address": "서울 강남구 언주로67길 27-1\t201호",
        "birth": "1985-07-06"
    },
    {
        "nickname": "꾹",
        "geoLocation": {
            "longitude": 127.019214036805,
            "latitude": 37.5057260090098
        },
        "address": "서울 서초구 주흥길 31-1\t203호",
        "birth": "1996-12-13"
    },
    {
        "nickname": "동걸",
        "geoLocation": {
            "longitude": 127.048347942149,
            "latitude": 37.4787434131571
        },
        "address": "서울 강남구 개포로 234\t312호",
        "birth": "1975-05-26"
    },
    {
        "nickname": "푸른하늘",
        "geoLocation": {
            "longitude": "127.133650976746",
            "latitude": "37.5224540542157"
        },
        "address": "강동구 성내동 449-13",
        "birth": "1967-10-01"
    },
    {
        "nickname": "이야곱",
        "geoLocation": {
            "longitude": 127.036446907368,
            "latitude": 37.5042816018723
        },
        "address": "서울 강남구 봉은사로30길 51\t102호",
        "birth": "1986-05-31"
    },
    {
        "nickname": "hj0704",
        "geoLocation": {
            "longitude": 127.283664255345,
            "latitude": 37.2303368006517
        },
        "address": "경기 용인시 처인구 양지면 남곡로33번길 2\t",
        "birth": "1992-04-15"
    },
    {
        "nickname": "서정재",
        "geoLocation": {
            "longitude": 126.909669207245,
            "latitude": 37.5822666054917
        },
        "address": "서울 서대문구 증가로31길 42-3\t401호",
        "birth": "1989-01-21"
    },
    {
        "nickname": "pooh0624",
        "geoLocation": {
            "longitude": 126.729457501314,
            "latitude": 37.4525304706656
        },
        "address": "인천 남동구 구월남로355번길 20\t신태양빌라 2동302호",
        "birth": "1978-12-25"
    },
    {
        "nickname": "유용진",
        "geoLocation": {
            "longitude": 126.867153664404,
            "latitude": 37.5361086413162
        },
        "address": "서울 양천구 목동중앙남로3길 11\t909호",
        "birth": "1995-08-09"
    },
    {
        "nickname": "안재형",
        "geoLocation": {
            "longitude": 127.052705716712,
            "latitude": 37.5034845639494
        },
        "address": "서울 강남구 테헤란로68길 28\t7층",
        "birth": "1995-08-17"
    },
    {
        "nickname": "김어진",
        "geoLocation": {
            "longitude": 127.042690475293,
            "latitude": 37.5014764820153
        },
        "address": "서울 강남구 언주로 427\t디오빌역삼 721호",
        "birth": "1991-10-20"
    },
    {
        "nickname": "이우현",
        "geoLocation": {
            "longitude": 127.042114305173,
            "latitude": 37.4920702704886
        },
        "address": "서울 강남구 도곡로28길 8\t103동",
        "birth": "2000-10-02"
    },
    {
        "nickname": "문인경",
        "geoLocation": {
            "longitude": 127.024374334943,
            "latitude": 37.5083981586493
        },
        "address": "서울 강남구 학동로4길 49\t4층",
        "birth": "1963-05-07"
    },
    {
        "nickname": "신기수",
        "geoLocation": {
            "longitude": 126.985570619312,
            "latitude": 37.5460748283766
        },
        "address": "서울 용산구 신흥로 90\t주차장",
        "birth": "1995-03-15"
    },
    {
        "nickname": ".",
        "geoLocation": {
            "longitude": 126.93116890535,
            "latitude": 37.4854658635147
        },
        "address": "서울 관악구 신림로66길 28\t603호",
        "birth": "2001-02-06"
    },
    {
        "nickname": "유예린",
        "geoLocation": {
            "longitude": 126.869151768219,
            "latitude": 37.3092107429855
        },
        "address": "경기 안산시 상록구 안산대학로 100\t202호",
        "birth": "2001-05-25"
    },
    {
        "nickname": "정혁",
        "geoLocation": {
            "longitude": 127.021453638817,
            "latitude": 37.5058320322107
        },
        "address": "서울 서초구 사평대로53길 34\t403호",
        "birth": "1990-08-24"
    },
    {
        "nickname": "강태식",
        "geoLocation": {
            "longitude": 127.049142140585,
            "latitude": 37.522871562142
        },
        "address": "서울 강남구 도산대로92길 28\t디에브스아파트",
        "birth": "1990-08-11"
    },
    {
        "nickname": "ㄱ김현민LP",
        "geoLocation": {
            "longitude": 127.031257745326,
            "latitude": 37.4913189579708
        },
        "address": "서울 강남구 강남대로 314\t",
        "birth": "1985-03-12"
    },
    {
        "nickname": "경",
        "geoLocation": {
            "longitude": 127.018307020078,
            "latitude": 37.5114116735709
        },
        "address": "서울 서초구 신반포로45길 23\t3동 307",
        "birth": "1995-10-18"
    },
    {
        "nickname": "TH",
        "geoLocation": {
            "longitude": 126.999846135135,
            "latitude": 37.4755723803846
        },
        "address": "서울 서초구 방배로 21\t111동1301",
        "birth": "1997-03-01"
    },
    {
        "nickname": "승헌",
        "geoLocation": {
            "longitude": 126.992790669594,
            "latitude": 37.4844990891688
        },
        "address": "서울 서초구 방배로19길 43\t3층",
        "birth": "1996-10-04"
    },
    {
        "nickname": "윤지형",
        "geoLocation": {
            "longitude": 127.03279727648,
            "latitude": 37.5127654513792
        },
        "address": "서울 강남구 학동로30길 21\t논현베르빌207호",
        "birth": "1986-05-21"
    },
    {
        "nickname": "Jks",
        "geoLocation": {
            "longitude": 127.00446943136,
            "latitude": 37.5116583511688
        },
        "address": "서울 서초구 신반포로23길 41\t",
        "birth": "1999-05-07"
    },
    {
        "nickname": "김찬영",
        "geoLocation": {
            "longitude": 126.736371238647,
            "latitude": 37.6693566846149
        },
        "address": "경기 고양시 일산서구 대화로156번길 56\t동방스타빌 2차 1동 402호",
        "birth": "1997-08-05"
    },
    {
        "nickname": "보영",
        "geoLocation": {
            "longitude": 126.843541651156,
            "latitude": 37.3226938945479
        },
        "address": "경기 안산시 상록구 화랑로 495\t2동",
        "birth": "1989-06-02"
    },
    {
        "nickname": "준혁",
        "geoLocation": {
            "longitude": 127.148600236458,
            "latitude": 37.5576891371776
        },
        "address": "서울 강동구 아리수로50길 50\t116-301",
        "birth": "1998-08-10"
    },
    {
        "nickname": "Chloe",
        "geoLocation": {
            "longitude": 127.003339114096,
            "latitude": 37.5387496347161
        },
        "address": "서울 용산구 한남대로27길 42\t101동 308호",
        "birth": "1992-03-02"
    },
    {
        "nickname": "최재웅",
        "geoLocation": {
            "longitude": 127.054046126104,
            "latitude": 37.5085174233038
        },
        "address": "서울 강남구 테헤란로77길 26\t101동703호",
        "birth": "1998-07-25"
    },
    {
        "nickname": "형",
        "geoLocation": {
            "longitude": 127.093493204064,
            "latitude": 37.5505745041455
        },
        "address": "서울 광진구 천호대로137길 33\tB02호",
        "birth": "1991-02-01"
    },
    {
        "nickname": "권혁진",
        "geoLocation": {
            "longitude": 127.033710629415,
            "latitude": 37.4866006068301
        },
        "address": "서울 강남구 강남대로48길 6\t유림원빌딩6층",
        "birth": "1970-05-10"
    },
    {
        "nickname": "서현기",
        "geoLocation": {
            "longitude": 127.046384009751,
            "latitude": 37.5025006142352
        },
        "address": "서울 강남구 테헤란로52길 16\t102동 1502호",
        "birth": "1998-03-31"
    },
    {
        "nickname": "길동",
        "geoLocation": {
            "longitude": 127.071367051995,
            "latitude": 37.4988556676427
        },
        "address": "서울 강남구 영동대로 210\t3동 107호",
        "birth": "1983-04-01"
    },
    {
        "nickname": "미노",
        "geoLocation": {
            "longitude": 126.878020542229,
            "latitude": 35.2086085468651
        },
        "address": "광주 북구 자라봉로 33\t102동709호",
        "birth": "1998-09-11"
    },
    {
        "nickname": "YURI",
        "geoLocation": {
            "longitude": "129.132912806454",
            "latitude": "35.1678017030381"
        },
        "address": "부산 해운대구 우동 1505\t106호",
        "birth": "1970-08-24"
    },
    {
        "nickname": "동락",
        "geoLocation": {
            "longitude": 128.490459413997,
            "latitude": 35.8632539995232
        },
        "address": "대구 달서구 달서대로 719\t106동 603호",
        "birth": "1987-11-24"
    },
    {
        "nickname": "최재호",
        "geoLocation": {
            "longitude": 127.02075517683,
            "latitude": 37.5067712746621
        },
        "address": "사평대로 53길 57 202호",
        "birth": "1993-09-07"
    },
    {
        "nickname": "J",
        "geoLocation": {
            "longitude": 126.960986884995,
            "latitude": 37.4791733118003
        },
        "address": "서울 관악구 행운2길 37-1\t408호",
        "birth": "1995-03-29"
    },
    {
        "nickname": "정희달",
        "geoLocation": {
            "longitude": 127.059684185466,
            "latitude": 37.5857464333201
        },
        "address": "서울 동대문구 망우로14가길 77-4\t201",
        "birth": "1992-12-27"
    },
    {
        "nickname": "김소진",
        "geoLocation": {
            "longitude": 127.025650823399,
            "latitude": 37.4611528806282
        },
        "address": "서울 서초구 태봉로2길 30\t607동 301호",
        "birth": "1994-10-19"
    },
    {
        "nickname": "민철",
        "geoLocation": {
            "longitude": 127.008223758536,
            "latitude": 37.4824150800019
        },
        "address": "서울 서초구 반포대로9길 59-10\t501",
        "birth": "1997-01-03"
    },
    {
        "nickname": "양경민",
        "geoLocation": {
            "longitude": 127.020126576083,
            "latitude": 37.5013246250352
        },
        "address": "서울 서초구 서운로 201\t서초푸르지오써밋 103-1303",
        "birth": "1986-12-27"
    },
    {
        "nickname": "ㄱ삐약삐약🐤",
        "geoLocation": {
            "longitude": 126.926090830361,
            "latitude": 37.5073612178503
        },
        "address": "서울 동작구 여의대방로44길 10\t101동 1705호",
        "birth": "1989-09-12"
    },
    {
        "nickname": "태우",
        "geoLocation": {
            "longitude": 127.054274484491,
            "latitude": 37.504618129293
        },
        "address": "서울 강남구 삼성로85길 38\t302호",
        "birth": "1991-07-28"
    },
    {
        "nickname": "연지",
        "geoLocation": {
            "longitude": 127.060513370102,
            "latitude": 37.5004468659061
        },
        "address": "서울 강남구 삼성로64길 5\t107동 802호",
        "birth": "2001-03-24"
    },
    {
        "nickname": "ㅎㅅ",
        "geoLocation": {
            "longitude": 127.035221996598,
            "latitude": 37.4935168408821
        },
        "address": "서울 강남구 역삼로14길 16-4\t203",
        "birth": "1994-02-14"
    },
    {
        "nickname": "jun",
        "geoLocation": {
            "longitude": 127.031507463771,
            "latitude": 37.4903503986844
        },
        "address": "서울 강남구 강남대로 302-2\t774-24",
        "birth": "1988-05-19"
    },
    {
        "nickname": "강민범",
        "geoLocation": {
            "longitude": 127.043888604174,
            "latitude": 37.5206583357764
        },
        "address": "서울 강남구 도산대로66길 43\t204호",
        "birth": "1994-03-24"
    },
    {
        "nickname": "박승준",
        "geoLocation": {
            "longitude": 126.912306802688,
            "latitude": 35.1470968478759
        },
        "address": "광주 동구 천변우로 381\t808호",
        "birth": "1998-04-03"
    },
    {
        "nickname": "MS",
        "geoLocation": {
            "longitude": 127.029843914945,
            "latitude": 37.5094175615525
        },
        "address": "서울 강남구 봉은사로11길 57\t501호",
        "birth": "1988-05-02"
    },
    {
        "nickname": "청록다람쥐",
        "geoLocation": {
            "longitude": 127.041923571223,
            "latitude": 37.4983917118778
        },
        "address": "역삼동 729-29 에이스빌 403호",
        "birth": "1967-08-02"
    },
    {
        "nickname": "조인호",
        "geoLocation": {
            "longitude": 127.012270484053,
            "latitude": 37.4905311910018
        },
        "address": "서울 서초구 반포대로22길 70\t101-401",
        "birth": "1997-02-22"
    },
    {
        "nickname": "김재혁 프로",
        "geoLocation": {
            "longitude": 127.042341350466,
            "latitude": 37.4984540026144
        },
        "address": "서울 강남구 언주로75길 24\t202호",
        "birth": "1991-10-19"
    },
    {
        "nickname": "이효빈",
        "geoLocation": {
            "longitude": 127.025952317395,
            "latitude": 37.5612312466825
        },
        "address": "서울 성동구 난계로 114-31\t107동 401호",
        "birth": "1994-06-30"
    },
    {
        "nickname": "채송화",
        "geoLocation": {
            "longitude": 127.040827240026,
            "latitude": 37.4943273846799
        },
        "address": "서울 강남구 도곡로25길 25\t101",
        "birth": "1992-06-20"
    },
    {
        "nickname": "김정훈",
        "geoLocation": {
            "longitude": 127.032504708306,
            "latitude": 37.5016516357539
        },
        "address": "서울 강남구 테헤란로19길 29-5\t로마빌라 501호",
        "birth": "1981-11-13"
    },
    {
        "nickname": "Jiyu",
        "geoLocation": {
            "longitude": 127.02172130811,
            "latitude": 37.567345090329
        },
        "address": "서울 중구 난계로13길 32\t",
        "birth": "1981-10-10"
    },
    {
        "nickname": ".",
        "geoLocation": {
            "longitude": 127.039037157303,
            "latitude": 37.5027814109807
        },
        "address": "서울 강남구 역삼동 678-6\t",
        "birth": "1986-06-25"
    },
    {
        "nickname": ".",
        "geoLocation": {
            "longitude": 127.107925656922,
            "latitude": 37.3338583776316
        },
        "address": "경기 용인시 수지구 용구대로2787번길 9-15\t이지뷰30동302호",
        "birth": "1982-10-23"
    },
    {
        "nickname": "김태영",
        "geoLocation": {
            "longitude": 127.042305481385,
            "latitude": 37.4996205482034
        },
        "address": "서울 강남구 테헤란로38길 40-9\t동성빌라105호",
        "birth": "1999-07-22"
    },
    {
        "nickname": "김영표",
        "geoLocation": {
            "longitude": 126.946865796584,
            "latitude": 37.5103042588159
        },
        "address": "서울 동작구 만양로8길 50\t108동 1501호",
        "birth": "1993-09-27"
    },
    {
        "nickname": "민은지",
        "geoLocation": {
            "longitude": 126.854644273406,
            "latitude": 37.4997566508176
        },
        "address": "서울 구로구 경인로35나길 14-40\t바동 101호",
        "birth": "1996-08-29"
    },
    {
        "nickname": "유다경",
        "geoLocation": {
            "longitude": 127.032148354517,
            "latitude": 37.4728897547784
        },
        "address": "서울 서초구 바우뫼로18길 20\t양재리본타워1 단지102동204호",
        "birth": "1981-11-05"
    },
    {
        "nickname": "민재",
        "geoLocation": {
            "longitude": 126.900076883603,
            "latitude": 37.5177939016069
        },
        "address": "서울 영등포구 문래로 164\t2동",
        "birth": "1999-09-07"
    },
    {
        "nickname": "윤성배? 내 이름!",
        "geoLocation": {
            "longitude": 127.040505144179,
            "latitude": 37.4982296651926
        },
        "address": "서울 강남구 테헤란로30길 47\t",
        "birth": "1987-05-22"
    },
    {
        "nickname": "👾",
        "geoLocation": {
            "longitude": 126.733916775723,
            "latitude": 37.4521669453512
        },
        "address": "인천 남동구 백범로101번길 36\t성빈빌라",
        "birth": "1991-11-07"
    },
    {
        "nickname": "수지",
        "geoLocation": {
            "longitude": 127.413224639568,
            "latitude": 36.5770731450032
        },
        "address": "충북 청주시 서원구 남이면 팔봉길 44\t293",
        "birth": "1994-08-29"
    },
    {
        "nickname": "김동우",
        "geoLocation": {
            "longitude": 126.901630452178,
            "latitude": 37.5278538150557
        },
        "address": "서울 영등포구 영신로55길 8\t102동 1104호",
        "birth": "1996-01-15"
    },
    {
        "nickname": "설준",
        "geoLocation": {
            "longitude": 126.91562960287,
            "latitude": 37.515983756
        },
        "address": "서울 영등포구 영등포로62길 22\t101호",
        "birth": "1988-07-17"
    },
    {
        "nickname": "다나",
        "geoLocation": {
            "longitude": 127.057249781197,
            "latitude": 37.5241014728573
        },
        "address": "서울 강남구 영동대로138길 12\t103동2401호",
        "birth": "1990-10-23"
    },
    {
        "nickname": "안리나",
        "geoLocation": {
            "longitude": 127.07114247316,
            "latitude": 37.5324722455229
        },
        "address": "서울 광진구 자양번영로3길 65\t102동 1002호",
        "birth": "1993-10-27"
    },
    {
        "nickname": "세올",
        "geoLocation": {
            "longitude": 127.002888989931,
            "latitude": 37.2705039814538
        },
        "address": "경기 수원시 팔달구 갓매산로76번길 10\t906호",
        "birth": "1990-06-10"
    },
    {
        "nickname": "미스터뚜",
        "geoLocation": {
            "longitude": 127.009469355244,
            "latitude": 37.3737207868095
        },
        "address": "경기 의왕시 학의동 1085-5\t201호",
        "birth": "1979-06-11"
    },
    {
        "nickname": "정용준",
        "geoLocation": {
            "longitude": 127.029273123095,
            "latitude": 37.5315341736395
        },
        "address": "서울 강남구 압구정로 201\t82동304호",
        "birth": "2001-06-04"
    },
    {
        "nickname": "RI",
        "geoLocation": {
            "longitude": 127.040520027302,
            "latitude": 37.4994033108271
        },
        "address": "서울 강남구 언주로81길 39\t301호",
        "birth": "1975-09-17"
    },
    {
        "nickname": "동규",
        "geoLocation": {
            "longitude": 126.664524238589,
            "latitude": 37.5323370382095
        },
        "address": "인천 서구 봉오재3로 14\t301동306호",
        "birth": "2000-05-21"
    },
    {
        "nickname": "윤",
        "geoLocation": {
            "longitude": 126.822268220771,
            "latitude": 37.6221084964388
        },
        "address": "경기 고양시 덕양구 능곡로 30-12\t101동 1002호",
        "birth": "2002-05-17"
    },
    {
        "nickname": "홍성현",
        "geoLocation": {
            "longitude": 126.899950487559,
            "latitude": 37.6525018505548
        },
        "address": "경기 고양시 덕양구 삼송로 238\t302동 529호",
        "birth": "2000-01-17"
    },
    {
        "nickname": "태한",
        "geoLocation": {
            "longitude": 127.068688654615,
            "latitude": 37.210333890299
        },
        "address": "경기 화성시 동탄시범한빛길 38\t211동 504호",
        "birth": "1995-11-15"
    },
    {
        "nickname": "정성철",
        "geoLocation": {
            "longitude": 127.051134145386,
            "latitude": 37.5109387278621
        },
        "address": "서울 강남구 봉은사로68길 17-9\t302호",
        "birth": "1983-10-05"
    },
    {
        "nickname": "원준",
        "geoLocation": {
            "longitude": 127.088272736661,
            "latitude": 37.5036192145196
        },
        "address": "서울 송파구 삼전로6길 3-16\t301호",
        "birth": "2001-08-29"
    },
    {
        "nickname": "한울타리",
        "geoLocation": {
            "longitude": 127.029733323803,
            "latitude": 37.4946490234479
        },
        "address": "서울 강남구 강남대로 354\t1106",
        "birth": "1961-01-20"
    },
    {
        "nickname": "김진현",
        "geoLocation": {
            "longitude": 127.028429115415,
            "latitude": 37.5026005449532
        },
        "address": "서울 강남구 봉은사로18길 61\tundefined",
        "birth": "1994-10-21"
    },
    {
        "nickname": "김승표",
        "geoLocation": {
            "longitude": 126.897336803029,
            "latitude": 37.6502419227362
        },
        "address": "경기 고양시 덕양구 동송로 30\tundefined",
        "birth": "1997-08-10"
    },
    {
        "nickname": "안유미",
        "geoLocation": {
            "longitude": 127.046061383778,
            "latitude": 37.5220582073353
        },
        "address": "서울 강남구 도산대로78길 34\t304호",
        "birth": "1966-07-17"
    },
    {
        "nickname": "제시카",
        "geoLocation": {
            "longitude": 126.968278864796,
            "latitude": 37.5404958843676
        },
        "address": "서울 용산구 원효로89길 9-2\t101호",
        "birth": "1971-06-07"
    },
    {
        "nickname": "한겨레",
        "geoLocation": {
            "longitude": 127.029018922972,
            "latitude": 37.2688538072464
        },
        "address": "경기 수원시 팔달구 인계로101번길 15\t드라마타운",
        "birth": "1991-04-10"
    },
    {
        "nickname": "🤜",
        "geoLocation": {
            "longitude": 127.036908155839,
            "latitude": 37.4937989720515
        },
        "address": "서울 강남구 논현로67길 34-3\t서담빌리지A동 501호",
        "birth": "1988-03-12"
    },
    {
        "nickname": "조 정길",
        "geoLocation": {
            "longitude": 126.838306270744,
            "latitude": 37.4877511353241
        },
        "address": "서울 구로구 오리로 1160\t천왕빌딩 605호",
        "birth": "1965-11-04"
    },
    {
        "nickname": "Victoria_Park",
        "geoLocation": {
            "longitude": 127.024700460079,
            "latitude": 37.5167909448234
        },
        "address": "서울 강남구 도산대로12길 17-9\t202호",
        "birth": "1979-11-21"
    },
    {
        "nickname": "♥︎나무니♥︎",
        "geoLocation": {
            "longitude": 127.040404211183,
            "latitude": 37.5610414900756
        },
        "address": "서울 성동구 마조로7길 8-6\t",
        "birth": "1962-04-23"
    },
    {
        "nickname": "김현정",
        "geoLocation": {
            "longitude": 127.104821314643,
            "latitude": 37.4678110775324
        },
        "address": "서울 강남구 밤고개로26길 50\t602동 604호",
        "birth": "1970-04-27"
    },
    {
        "nickname": "💕",
        "geoLocation": {
            "longitude": 127.019635031063,
            "latitude": 37.5080475595176
        },
        "address": "서울 서초구 사평대로53길 93-27\t301호",
        "birth": "1966-05-08"
    },
    {
        "nickname": "이화선/53/서초",
        "geoLocation": {
            "longitude": 126.989330818485,
            "latitude": 37.4791002523419
        },
        "address": "서울 서초구 도구로1길 1\t서원빌라 302호",
        "birth": "1969-12-28"
    },
    {
        "nickname": "김락균",
        "geoLocation": {
            "longitude": 127.100730117136,
            "latitude": 37.4896147291453
        },
        "address": "서울 강남구 광평로51길 27\t삼익아파트 401동 612호",
        "birth": "1980-07-06"
    },
    {
        "nickname": "life is",
        "geoLocation": {
            "longitude": 127.060270130156,
            "latitude": 37.5951978136245
        },
        "address": "서울 동대문구 휘경로2길 22-10\t3층",
        "birth": "1967-01-19"
    },
    {
        "nickname": "황혜숙",
        "geoLocation": {
            "longitude": 127.041222257298,
            "latitude": 37.4836731012049
        },
        "address": "서울 서초구 강남대로30길 77-8\t현대빌라 60동 102호",
        "birth": "1965-02-21"
    },
    {
        "nickname": "윤기현",
        "geoLocation": {
            "longitude": 126.876153028161,
            "latitude": 37.5460968990967
        },
        "address": "서울 양천구 목동중앙북로 120-6\t102호",
        "birth": "1994-09-04"
    },
    {
        "nickname": "ㄱ🔱 W ARTcompany",
        "geoLocation": {
            "longitude": 126.739114419326,
            "latitude": 37.3907578271975
        },
        "address": "경기 시흥시 월곶중앙로70번길 11\tundefined",
        "birth": "1977-07-04"
    },
    {
        "nickname": "재혁",
        "geoLocation": {
            "longitude": 126.939686254398,
            "latitude": 37.4687755334577
        },
        "address": "서울 관악구 신림동 244-170\t다담빌101동301호",
        "birth": "1967-07-02"
    },
    {
        "nickname": "김종호",
        "geoLocation": {
            "longitude": 126.859674301276,
            "latitude": 37.5145947056437
        },
        "address": "서울 양천구 목동동로 33\tundefined",
        "birth": "1961-02-05"
    },
    {
        "nickname": "베베",
        "geoLocation": {
            "longitude": 127.044624695699,
            "latitude": 37.5064348110822
        },
        "address": "서울 강남구 선릉로103길 25\t601호",
        "birth": "1998-01-03"
    },
    {
        "nickname": "안영민",
        "geoLocation": {
            "longitude": 127.040463515854,
            "latitude": 37.6018277386458
        },
        "address": "서울 성북구 화랑로 76\t",
        "birth": "1985-12-18"
    },
    {
        "nickname": "조진환",
        "geoLocation": {
            "longitude": 126.766526487422,
            "latitude": 37.6961814765787
        },
        "address": "경기 고양시 일산서구 현중로 61\tundefined",
        "birth": "1980-01-18"
    },
    {
        "nickname": "스웩",
        "geoLocation": {
            "longitude": 127.038556384674,
            "latitude": 37.5203509726038
        },
        "address": "서울 강남구 논현동 100-28\t302",
        "birth": "1978-04-19"
    },
    {
        "nickname": "루이스(이상민)",
        "geoLocation": {
            "longitude": 126.971502735904,
            "latitude": 37.4074522973392
        },
        "address": "경기 안양시 동안구 관양동 1715-6\t301호",
        "birth": "1961-09-03"
    },
    {
        "nickname": "성보경",
        "geoLocation": {
            "longitude": 127.000282240472,
            "latitude": 37.4315592356584
        },
        "address": "경기 과천시 부림동 96\t713동",
        "birth": "1977-12-10"
    },
    {
        "nickname": "박정훈",
        "geoLocation": {
            "longitude": 127.01561884415,
            "latitude": 37.4920307929916
        },
        "address": "서울 서초구 서초동 1660-24\t103호",
        "birth": "1994-12-29"
    },
    {
        "nickname": "하태현 BlackBird",
        "geoLocation": {
            "longitude": 127.045081067864,
            "latitude": 37.4697709155765
        },
        "address": "서울 서초구 양재동 368-10\t",
        "birth": "1962-05-21"
    },
    {
        "nickname": "철민",
        "geoLocation": {
            "longitude": 127.039660216386,
            "latitude": 37.5049672229298
        },
        "address": "서울 강남구 역삼동 673-18\t301호",
        "birth": "1994-02-07"
    },
    {
        "nickname": "전력투구",
        "geoLocation": {
            "longitude": 126.912675593611,
            "latitude": 37.5670539549764
        },
        "address": "서울 마포구 성산동 131-18\t도원빌라 2동 202호",
        "birth": "1980-10-31"
    },
    {
        "nickname": "文俊水",
        "geoLocation": {
            "longitude": 127.026903634148,
            "latitude": 37.5077477148501
        },
        "address": "서울 강남구 논현동 181-7\t404호",
        "birth": "1987-07-13"
    },
    {
        "nickname": "Diana",
        "geoLocation": {
            "longitude": 127.040509736961,
            "latitude": 37.4994046658655
        },
        "address": "서울 강남구 역삼동 725-41\t201호",
        "birth": "1977-08-08"
    },
    {
        "nickname": "짱구",
        "geoLocation": {
            "longitude": 126.498855645019,
            "latitude": 37.4889659951233
        },
        "address": "인천 중구 운서동 3099-4\t",
        "birth": "1992-02-11"
    },
    {
        "nickname": "Rana",
        "geoLocation": {
            "longitude": 126.900457452463,
            "latitude": 37.4525665571498
        },
        "address": "서울 금천구 시흥동 992-20\t620",
        "birth": "1988-12-09"
    },
    {
        "nickname": "Won",
        "geoLocation": {
            "longitude": 127.039993435226,
            "latitude": 37.4986715128261
        },
        "address": "서울 강남구 역삼동 725-55\t305호",
        "birth": "1991-04-12"
    },
    {
        "nickname": "J.J",
        "geoLocation": {
            "longitude": 127.026812152825,
            "latitude": 37.5631010729763
        },
        "address": "서울 성동구 하왕십리동 1050\t",
        "birth": "1978-05-22"
    },
    {
        "nickname": "전정수",
        "geoLocation": {
            "longitude": 127.052454442241,
            "latitude": 37.511598404885
        },
        "address": "서울 강남구 삼성동 118-14\t103호",
        "birth": "1983-03-17"
    },
    {
        "nickname": "우우",
        "geoLocation": {
            "longitude": 127.032023625741,
            "latitude": 37.4924090618276
        },
        "address": "서울 강남구 역삼동 832-33\t711",
        "birth": "1992-10-03"
    },
    {
        "nickname": "김예슬",
        "geoLocation": {
            "longitude": 127.017261789167,
            "latitude": 37.4858187462281
        },
        "address": "서울 서초구 서초동 1603-80\t202호",
        "birth": "1994-01-05"
    },
    {
        "nickname": "ㅎ",
        "geoLocation": {
            "longitude": 127.02641729356,
            "latitude": 37.5112639051518
        },
        "address": "서울 강남구 논현동 136-6\tB04호",
        "birth": "1997-01-02"
    },
    {
        "nickname": "빛나",
        "geoLocation": {
            "longitude": 127.025348123438,
            "latitude": 37.5223785585809
        },
        "address": "서울 강남구 신사동 553-7\t",
        "birth": "1987-03-30"
    },
    {
        "nickname": "라온제나Ent.",
        "geoLocation": {
            "longitude": 127.023699253392,
            "latitude": 37.4866519639231
        },
        "address": "서울 서초구 서초동 1438-9\t4층 401호 에덴하우스",
        "birth": "1987-01-31"
    },
    {
        "nickname": "문따",
        "geoLocation": {
            "longitude": 126.851726434901,
            "latitude": 37.5244696224807
        },
        "address": "서울 양천구 신정동 940-38\t4층",
        "birth": "1981-05-28"
    },
    {
        "nickname": "SH",
        "geoLocation": {
            "longitude": 127.153197222717,
            "latitude": 37.2866224279945
        },
        "address": "경기 용인시 기흥구 동백동 574\t1604-503",
        "birth": "1982-01-03"
    },
    {
        "nickname": "승주",
        "geoLocation": {
            "longitude": 126.865106401922,
            "latitude": 37.5471813114793
        },
        "address": "서울 양천구 목동 625-6\t301호",
        "birth": "1990-11-01"
    },
    {
        "nickname": "진이",
        "geoLocation": {
            "longitude": 127.147331370471,
            "latitude": 37.438113489979
        },
        "address": "경기 성남시 중원구 중앙동 3001\t106동 1603호",
        "birth": "1978-11-11"
    },
    {
        "nickname": "다온",
        "geoLocation": {
            "longitude": 127.115047104678,
            "latitude": 37.5134361314754
        },
        "address": "서울 송파구 방이동 151-4\t101호",
        "birth": "1981-03-26"
    },
    {
        "nickname": "서지현",
        "geoLocation": {
            "longitude": 127.037847071448,
            "latitude": 37.5072627679525
        },
        "address": "서울 강남구 역삼동 655-11\t402호",
        "birth": "1989-12-25"
    },
    {
        "nickname": "이한성",
        "geoLocation": {
            "longitude": 126.965184231874,
            "latitude": 37.4710006614136
        },
        "address": "서울 관악구 봉천동 180-33\t701호",
        "birth": "1991-03-01"
    },
    {
        "nickname": ".",
        "geoLocation": {
            "longitude": 127.067933134647,
            "latitude": 37.5450153349337
        },
        "address": "서울 광진구 화양동 33-47\t2층",
        "birth": "1997-03-22"
    },
    {
        "nickname": "반야",
        "geoLocation": {
            "longitude": "127.025886283886",
            "latitude": "37.5103562607348"
        },
        "address": "강남구 논현동 138-10",
        "birth": "1986-02-05"
    },
    {
        "nickname": "최휘빈",
        "geoLocation": {
            "longitude": 127.036763076031,
            "latitude": 37.4961103701562
        },
        "address": "서울 강남구 역삼동 748-23\t102호",
        "birth": "1996-03-10"
    },
    {
        "nickname": "정대용",
        "geoLocation": {
            "longitude": 126.913795371986,
            "latitude": 37.480648575687
        },
        "address": "서울 관악구 신림동 1483-4\t203호 성강채",
        "birth": "1992-03-17"
    },
    {
        "nickname": "人香萬里",
        "geoLocation": {
            "longitude": 127.029297352877,
            "latitude": 37.4977268810805
        },
        "address": "서울 강남구 역삼동 825-20\t428",
        "birth": "1981-01-08"
    },
    {
        "nickname": "희철",
        "geoLocation": {
            "longitude": 127.086092865003,
            "latitude": 37.5072940406228
        },
        "address": "서울 송파구 잠실동 239-1\t늘푸른집 303",
        "birth": "1996-03-08"
    },
    {
        "nickname": "준형",
        "geoLocation": {
            "longitude": "126.773104918712",
            "latitude": "37.690500968355"
        },
        "address": "고양시 일산서구 일산동 1945-1",
        "birth": "1996-07-19"
    },
    {
        "nickname": "태영",
        "geoLocation": {
            "longitude": 127.028986799148,
            "latitude": 37.5079162505451
        },
        "address": "서울 강남구 논현동 179-2\t201호",
        "birth": "1999-07-22"
    },
    {
        "nickname": "조혜수",
        "geoLocation": {
            "longitude": 127.030156367516,
            "latitude": 37.5064735388581
        },
        "address": "서울 강남구 논현동 205-8\t1212호",
        "birth": "1994-06-27"
    },
    {
        "nickname": "Bryan",
        "geoLocation": {
            "longitude": 127.062186441493,
            "latitude": 37.5662781673123
        },
        "address": "서울 동대문구 장안동 580\tundefined",
        "birth": "1986-02-24"
    },
    {
        "nickname": "이명현",
        "geoLocation": {
            "longitude": 127.095746786537,
            "latitude": 37.500913620295
        },
        "address": "서울 송파구 삼전동 177-11\t101",
        "birth": "1982-12-24"
    },
    {
        "nickname": "안지환",
        "geoLocation": {
            "longitude": 127.054777117988,
            "latitude": 37.5886354128267
        },
        "address": "서울 동대문구 휘경동 341-1\t303호",
        "birth": "1998-01-09"
    },
    {
        "nickname": "현종",
        "geoLocation": {
            "longitude": 126.740247876142,
            "latitude": 37.5420532198363
        },
        "address": "인천 계양구 용종동 214-2\t203-901",
        "birth": "2004-03-31"
    },
    {
        "nickname": "박규철",
        "geoLocation": {
            "longitude": 126.70000818328,
            "latitude": 37.2506776069325
        },
        "address": "경기 화성시 송산면 독지리 727-3\t",
        "birth": "1998-03-20"
    },
    {
        "nickname": "Good zz Go",
        "geoLocation": {
            "longitude": 126.806768219975,
            "latitude": 35.1837616868559
        },
        "address": "광주 광산구 흑석동 811\t106-203",
        "birth": "1992-11-08"
    },
    {
        "nickname": "나른한코끼리",
        "geoLocation": {
            "longitude": 127.082612734524,
            "latitude": 37.4842754486616
        },
        "address": "서울 강남구 일원동 718\t108-106",
        "birth": "1986-03-12"
    },
    {
        "nickname": "Jung-suk RYOU India",
        "geoLocation": {
            "longitude": 127.058399460382,
            "latitude": 37.50549518273
        },
        "address": "서울 강남구 대치동 957-10\t501호",
        "birth": "1964-07-09"
    },
    {
        "nickname": "임연택",
        "geoLocation": {
            "longitude": 127.187060704045,
            "latitude": 37.4775701522905
        },
        "address": "경기 광주시 남한산성면 산성리 415-1\t",
        "birth": "1965-02-01"
    },
    {
        "nickname": "🧁",
        "geoLocation": {
            "longitude": 127.030829798387,
            "latitude": 37.5296596044876
        },
        "address": "서울 강남구 압구정동 458\t73동 503호",
        "birth": "1979-03-15"
    },
    {
        "nickname": "장세진",
        "geoLocation": {
            "longitude": 127.082322072208,
            "latitude": 37.5053197437121
        },
        "address": "서울 송파구 잠실동 312-23\t501호",
        "birth": "1998-06-02"
    },
    {
        "nickname": "두리",
        "geoLocation": {
            "longitude": 127.013978698107,
            "latitude": 37.5577505575927
        },
        "address": "서울 중구 신당동 333-240\t1층",
        "birth": "1991-05-27"
    },
    {
        "nickname": "호랑",
        "geoLocation": {
            "longitude": 126.881209940932,
            "latitude": 37.4557580051288
        },
        "address": "경기 광명시 소하동 1278-2\t401호",
        "birth": "2001-02-12"
    },
    {
        "nickname": "노은영",
        "geoLocation": {
            "longitude": 126.936572327322,
            "latitude": 37.5117695289456
        },
        "address": "서울 동작구 노량진동 279-1\t202호",
        "birth": "1982-12-27"
    },
    {
        "nickname": "조운비",
        "geoLocation": {
            "longitude": 126.926291575542,
            "latitude": 37.5020782091381
        },
        "address": "서울 동작구 대방동 391-249\t402",
        "birth": "1991-10-09"
    },
    {
        "nickname": "이서인",
        "geoLocation": {
            "longitude": 127.045447018102,
            "latitude": 37.4716820890283
        },
        "address": "서울 서초구 양재동 377-1\t화성빌딩501호",
        "birth": "1967-07-31"
    },
    {
        "nickname": "20180912",
        "geoLocation": {
            "longitude": 126.795496351818,
            "latitude": 37.3263064625047
        },
        "address": "경기 안산시 단원구 원곡동 820-22\t408호",
        "birth": "1981-02-28"
    },
    {
        "nickname": "손민식",
        "geoLocation": {
            "longitude": 126.901808997555,
            "latitude": 37.480512991154
        },
        "address": "서울 관악구 신림동 1664-20\t1층",
        "birth": "1982-02-26"
    },
    {
        "nickname": "기범",
        "geoLocation": {
            "longitude": 128.537191611258,
            "latitude": 35.8082342485857
        },
        "address": "대구 달서구 도원동 1438\t314/103",
        "birth": "2000-03-03"
    },
    {
        "nickname": "***",
        "geoLocation": {
            "longitude": 126.723553821316,
            "latitude": 37.4979475720033
        },
        "address": "인천 부평구 부평동 374-1\t1820호",
        "birth": "1979-08-25"
    },
    {
        "nickname": "uji_stop",
        "geoLocation": {
            "longitude": 127.052665311981,
            "latitude": 37.5023149866226
        },
        "address": "서울 강남구 대치동 900-17\t402",
        "birth": "1959-06-12"
    },
    {
        "nickname": "..",
        "geoLocation": {
            "longitude": 127.09224307638,
            "latitude": 37.4718220519952
        },
        "address": "서울 강남구 자곡동 617\t",
        "birth": "1996-02-08"
    },
    {
        "nickname": "오정원",
        "geoLocation": {
            "longitude": 127.04990780693,
            "latitude": 37.5059490375922
        },
        "address": "서울 강남구 삼성동 141-8\t삼성롯데캐슬클라쎄1804호",
        "birth": "1986-01-01"
    },
    {
        "nickname": "이태훈",
        "geoLocation": {
            "longitude": 126.696760757772,
            "latitude": 37.5997133243536
        },
        "address": "인천 서구 원당동 802-4\t202호",
        "birth": "2000-01-13"
    },
    {
        "nickname": "chance & choice",
        "geoLocation": {
            "longitude": 126.978744186396,
            "latitude": 37.4714560958314
        },
        "address": "서울 관악구 남현동 602-284\t302호",
        "birth": "1978-03-04"
    },
    {
        "nickname": ".",
        "geoLocation": {
            "longitude": 127.026066456878,
            "latitude": 37.5093699814847
        },
        "address": "서울 강남구 논현동 159-10\t(학동로6길46)",
        "birth": "2001-12-12"
    },
    {
        "nickname": "이승환",
        "geoLocation": {
            "longitude": 127.088688007958,
            "latitude": 37.4712795724748
        },
        "address": "서울 강남구 자곡동 619\t501동 1733호",
        "birth": "1984-05-20"
    },
    {
        "nickname": "안수완",
        "geoLocation": {
            "longitude": 126.916873768703,
            "latitude": 37.3557629670761
        },
        "address": "경기 군포시 산본동 1152-2\t873동201호",
        "birth": "2001-08-24"
    },
    {
        "nickname": "김준희  (여행문의많이주세요)",
        "geoLocation": {
            "longitude": 126.910342559913,
            "latitude": 37.5229759681115
        },
        "address": "서울시 영등포구 버드나루로7길 3 경동미르웰여의도 오피스텔 1006호",
        "birth": "1982-06-02"
    },
    {
        "nickname": "준명 오상현",
        "geoLocation": {
            "longitude": 127.04304310893,
            "latitude": 37.5083943628839
        },
        "address": "서울 강남구 역삼동 685-23\t케아하우스 17차",
        "birth": "1968-02-01"
    },
    {
        "nickname": "김수진",
        "geoLocation": {
            "longitude": 127.023350941226,
            "latitude": 37.4853753946959
        },
        "address": "서울 서초구 서초동 1436-14\t102동802호",
        "birth": "1970-04-10"
    },
    {
        "nickname": "웅",
        "geoLocation": {
            "longitude": 127.100906239448,
            "latitude": 37.4245532001003
        },
        "address": "경기 성남시 수정구 고등동 612-9\t",
        "birth": "1991-03-30"
    },
    {
        "nickname": "민석",
        "geoLocation": {
            "longitude": 127.077478826829,
            "latitude": 37.3379326376399
        },
        "address": "경기도 용인시 수지구 동천로 246 아이비카운티 2차 C동201호",
        "birth": "1997-06-12"
    },
    {
        "nickname": "신현우",
        "geoLocation": {
            "longitude": 127.031571953332,
            "latitude": 37.2587777004646
        },
        "address": "경기 수원시 팔달구 권광로124번길 13\t1022호",
        "birth": "2000-08-09"
    },
    {
        "nickname": "성민",
        "geoLocation": {
            "longitude": 127.04718779295,
            "latitude": 37.5165602922999
        },
        "address": "서울 강남구 학동로68길 29\t힐스테이트 1단지 101동702호",
        "birth": "2000-04-13"
    },
    {
        "nickname": "사부작",
        "geoLocation": {
            "longitude": 126.904959452356,
            "latitude": 37.5648519986234
        },
        "address": "서울 마포구 성산동 593-11\t612호",
        "birth": "1984-08-20"
    },
    {
        "nickname": "치즈와퍼",
        "geoLocation": {
            "longitude": "127.032932009945",
            "latitude": "37.4986518880181"
        },
        "address": "강남구 역삼동 823-26\t우리집",
        "birth": "1992-07-23"
    },
    {
        "nickname": "DOPA McKinnon",
        "geoLocation": {
            "longitude": 126.918453198201,
            "latitude": 37.5461767554881
        },
        "address": "서울 마포구 합정동 357-22\tB03",
        "birth": "1990-03-19"
    },
    {
        "nickname": "호랭이",
        "geoLocation": {
            "longitude": 127.042965578713,
            "latitude": 37.5148096317235
        },
        "address": "서울 강남구 학동로56길 36\t201호",
        "birth": "1967-07-16"
    },
    {
        "nickname": "환",
        "geoLocation": {
            "longitude": 127.186440760482,
            "latitude": 37.8826872102995
        },
        "address": "경기 포천시 어룡동 304-12\t203동101호",
        "birth": "1997-09-24"
    },
    {
        "nickname": "노경륜",
        "geoLocation": {
            "longitude": 127.105794693568,
            "latitude": 37.4878478418441
        },
        "address": "서울 강남구 광평로56길 11\t601동 112호",
        "birth": "1993-10-04"
    },
    {
        "nickname": "최브라이언",
        "geoLocation": {
            "longitude": "127.0334938",
            "latitude": "37.510418"
        },
        "address": "강남구 논현동 222-12 203호\tundefined",
        "birth": "1984-10-24"
    },
    {
        "nickname": "이상규",
        "geoLocation": {
            "longitude": 127.069139134413,
            "latitude": 37.5002337297672
        },
        "address": "서울 강남구 대치동 63\t6동 503호",
        "birth": "1979-04-20"
    },
    {
        "nickname": "장시훈",
        "geoLocation": {
            "longitude": "126.91802525266",
            "latitude": "37.486281537141"
        },
        "address": "관악구 신림동 479-34",
        "birth": "1995-09-08"
    },
    {
        "nickname": "양혁",
        "geoLocation": {
            "longitude": 127.102101820568,
            "latitude": 37.4670982362638
        },
        "address": "서울 강남구 헌릉로571길 20\t110",
        "birth": "1995-11-18"
    },
    {
        "nickname": "JJONG^^",
        "geoLocation": {
            "longitude": "127.103296904493",
            "latitude": "37.428814657394"
        },
        "address": "성남시 수정구 고등동 400-11\t판교유엔빌 U동1호",
        "birth": "1976-02-01"
    },
    {
        "nickname": "안녕하세요",
        "geoLocation": {
            "longitude": 126.930469237626,
            "latitude": 37.4977916832702
        },
        "address": "서울 동작구 국사봉5길 3\t101호",
        "birth": "1991-02-06"
    },
    {
        "nickname": "쓰리",
        "geoLocation": {
            "longitude": "127.046981455754",
            "latitude": "37.4737512895768"
        },
        "address": "서울 서초구 양재동 286-9\tundefined",
        "birth": "1995-09-07"
    },
    {
        "nickname": "Liz",
        "geoLocation": {
            "longitude": "127.04238658366",
            "latitude": "37.496050011"
        },
        "address": "강남구 역삼동 775-19",
        "birth": "1970-11-24"
    },
    {
        "nickname": "진솔",
        "geoLocation": {
            "longitude": 126.840006908063,
            "latitude": 37.5588203160036
        },
        "address": "서울 강서구 공항대로 291\t1208호",
        "birth": "1989-04-11"
    },
    {
        "nickname": "h",
        "geoLocation": {
            "longitude": 127.03607367348,
            "latitude": 37.4929624645205
        },
        "address": "서울 강남구 도곡로13길 26\t",
        "birth": "1994-02-05"
    },
    {
        "nickname": "남동금",
        "geoLocation": {
            "longitude": "127.193578062111",
            "latitude": "37.5624883471865"
        },
        "address": "경기 하남시 망월동 1131\t202호",
        "birth": "1979-10-08"
    },
    {
        "nickname": "이성웅",
        "geoLocation": {
            "longitude": "127.029768983775",
            "latitude": "37.4970271316319"
        },
        "address": "강남구 역삼동 825-24",
        "birth": "1981-01-04"
    },
    {
        "nickname": "정은규",
        "geoLocation": {
            "longitude": "127.094032069299",
            "latitude": "37.590076672722"
        },
        "address": "중랑구 망우동 525-5",
        "birth": "1982-08-18"
    },
    {
        "nickname": "붕붕",
        "geoLocation": {
            "longitude": "127.028340978891",
            "latitude": "37.4953221474958"
        },
        "address": "서초구 서초동 1328-7\t1809호",
        "birth": "1979-06-05"
    },
    {
        "nickname": "성주",
        "geoLocation": {
            "longitude": "127.115464699108",
            "latitude": "37.5096869171551"
        },
        "address": "서울 송파구 방이동 120-5",
        "birth": "1999-01-01"
    },
    {
        "nickname": "김용민",
        "geoLocation": {
            "longitude": 127.935680210678,
            "latitude": 37.3378064437944
        },
        "address": "강원 원주시 무실로 244\t2층",
        "birth": "1982-10-20"
    },
    {
        "nickname": "최고",
        "geoLocation": {
            "longitude": 127.115473172937,
            "latitude": 37.5280326766603
        },
        "address": "서울 송파구 강동대로5길 13\t501호",
        "birth": "2000-12-04"
    },
    {
        "nickname": "kistone",
        "geoLocation": {
            "longitude": 127.104470715166,
            "latitude": 37.4885591040326
        },
        "address": "서울 강남구 광평로 295\t동관 414호",
        "birth": "1976-03-22"
    },
    {
        "nickname": "최종원",
        "geoLocation": {
            "longitude": "126.932097633851",
            "latitude": "37.5249360133871"
        },
        "address": "서울 영등포구 여의도동 30-2\t",
        "birth": "1984-09-03"
    },
    {
        "nickname": "장원석",
        "geoLocation": {
            "longitude": 127.041924759652,
            "latitude": 37.5569137627749
        },
        "address": "서울 성동구 살곶이8길 11\t401호",
        "birth": "1999-02-14"
    },
    {
        "nickname": "ㄱ　　윤현우",
        "geoLocation": {
            "longitude": 127.043749420894,
            "latitude": 37.5060486073053
        },
        "address": "서울 강남구 역삼동 698-6\tundefined",
        "birth": "1988-12-04"
    },
    {
        "nickname": "천세준",
        "geoLocation": {
            "longitude": 127.02673345542,
            "latitude": 37.5119791413522
        },
        "address": "서울 강남구 강남대로128길 66\t401호",
        "birth": "1989-07-16"
    },
    {
        "nickname": "𝐶𝑟𝑒𝑎𝑡𝑜𝑟",
        "geoLocation": {
            "longitude": 127.026198111221,
            "latitude": 37.5064617788727
        },
        "address": "서울 강남구 논현동 184-14\t1",
        "birth": "1993-09-13"
    },
    {
        "nickname": "ㅎㅜㄴ",
        "geoLocation": {
            "longitude": "126.903289418146",
            "latitude": "37.4532140337802"
        },
        "address": "금천구 시흥동 886-13\tundefined",
        "birth": "2000-03-23"
    },
    {
        "nickname": "써니 Song",
        "geoLocation": {
            "longitude": "126.838325358303",
            "latitude": "37.5715220580963"
        },
        "address": "서울 강서구 마곡동 14-6\t",
        "birth": "1968-07-20"
    },
    {
        "nickname": "홈케어",
        "geoLocation": {
            "longitude": 127.047936645535,
            "latitude": 37.5031717841689
        },
        "address": "서울 강남구 테헤란로52길 15\t209호",
        "birth": "1987-04-17"
    },
    {
        "nickname": "🤴",
        "geoLocation": {
            "longitude": "127.028340978891",
            "latitude": "37.4953221474958"
        },
        "address": "서울 서초구 서초동 1328-7\t강남대로 365 1809",
        "birth": "1993-09-13"
    },
    {
        "nickname": "^~^",
        "geoLocation": {
            "longitude": 126.857366968238,
            "latitude": 37.2836004819196
        },
        "address": "경기 안산시 상록구 본오동 970-10\t",
        "birth": "1998-04-14"
    },
    {
        "nickname": "인하",
        "geoLocation": {
            "longitude": 126.983614309346,
            "latitude": 37.4892328804823
        },
        "address": "서울 서초구 서초대로1길 30\t105",
        "birth": "1992-03-15"
    },
    {
        "nickname": "Hee",
        "geoLocation": {
            "longitude": 127.033730333795,
            "latitude": 37.5108040626957
        },
        "address": "서울 강남구 논현동 222-7\t202호",
        "birth": "1983-02-17"
    },
    {
        "nickname": "류현준",
        "geoLocation": {
            "longitude": 127.020110899657,
            "latitude": 37.483773006463
        },
        "address": "서울 서초구 남부순환로333길 15-6\t303호",
        "birth": "1997-03-05"
    },
    {
        "nickname": "이보라",
        "geoLocation": {
            "longitude": 127.039297305874,
            "latitude": 37.518593141678
        },
        "address": "서울 강남구 선릉로135길 16\t302호",
        "birth": "1984-12-01"
    },
    {
        "nickname": "심상범",
        "geoLocation": {
            "longitude": "127.030156367516",
            "latitude": "37.5064735388581"
        },
        "address": "강남구 논현동 205-8",
        "birth": "1997-09-07"
    },
    {
        "nickname": "이원준",
        "geoLocation": {
            "longitude": "127.03786193249",
            "latitude": "37.4957052908827"
        },
        "address": "서울 강남구 역삼동 747-5\t101동 903호",
        "birth": "2000-09-21"
    },
    {
        "nickname": "재효",
        "geoLocation": {
            "longitude": "127.02764350187",
            "latitude": "37.5119302776496"
        },
        "address": "서울 강남구 논현동 135-12\t",
        "birth": "1999-10-04"
    },
    {
        "nickname": "John",
        "geoLocation": {
            "longitude": 127.097356569169,
            "latitude": 37.3384624431927
        },
        "address": "경기 용인시 수지구 동천로63번길 10\t동천마을현대2차 206",
        "birth": "1982-10-01"
    },
    {
        "nickname": "김민주",
        "geoLocation": {
            "longitude": "127.046173251056",
            "latitude": "37.524059110059"
        },
        "address": "강남구 청담동 77",
        "birth": "1981-01-10"
    },
    {
        "nickname": "윤홍욱(Danny Yoon)",
        "geoLocation": {
            "longitude": 127.041907070919,
            "latitude": 37.4984103685629
        },
        "address": "서울 강남구 언주로75길 28-1\t에이스빌 204호",
        "birth": "1984-02-12"
    },
    {
        "nickname": "Jackjaewon",
        "geoLocation": {
            "longitude": 127.030303331768,
            "latitude": 37.5119085417241
        },
        "address": "서울 강남구 논현로123길 12-1\t202",
        "birth": "1992-05-18"
    },
    {
        "nickname": "헤엄치는오리",
        "geoLocation": {
            "longitude": "127.031813286326",
            "latitude": "37.4899764886786"
        },
        "address": "강남구 역삼동 838",
        "birth": "1990-02-28"
    },
    {
        "nickname": "John",
        "geoLocation": {
            "longitude": 127.017509720596,
            "latitude": 37.5084662302284
        },
        "address": "서울 서초구 주흥15길 30\t401",
        "birth": "1988-02-01"
    },
    {
        "nickname": "배승준",
        "geoLocation": {
            "longitude": 127.00446943136,
            "latitude": 37.5116583511688
        },
        "address": "서울 서초구 신반포로23길 41\t신반포2차아파트 107동 505호",
        "birth": "1999-06-11"
    },
    {
        "nickname": "권알",
        "geoLocation": {
            "longitude": 127.020640675895,
            "latitude": 37.5115409433608
        },
        "address": "서울 서초구 강남대로 557\tundefined",
        "birth": "1987-07-31"
    },
    {
        "nickname": "박서하",
        "geoLocation": {
            "longitude": 127.043603570498,
            "latitude": 37.5142957356426
        },
        "address": "서울 강남구 선릉로126길 14-18\t501호",
        "birth": "1997-01-17"
    },
    {
        "nickname": "ㄱ나랑 별 보러 가지 않을래?",
        "geoLocation": {
            "longitude": 127.036689408603,
            "latitude": 37.4957772007809
        },
        "address": "서울 강남구 역삼로19길 7-1\t샤론빌 404호",
        "birth": "1982-09-27"
    },
    {
        "nickname": "동",
        "geoLocation": {
            "longitude": 126.84148645452,
            "latitude": 37.5222244517912
        },
        "address": "서울 양천구 오목로5길 2\t안성주택b02호",
        "birth": "1997-06-28"
    },
    {
        "nickname": "kevinkang",
        "geoLocation": {
            "longitude": 127.042829674028,
            "latitude": 37.5056037577097
        },
        "address": "서울 강남구 역삼동 691-9\t아리채 103호",
        "birth": "1990-12-20"
    },
    {
        "nickname": "테스트유저",
        "geoLocation": {
            "longitude": "127.076015601403",
            "latitude": "37.5454231561559"
        },
        "address": "서울 광진구 화양동 499-34\t",
        "birth": "1999-02-14"
    },
    {
        "nickname": "연준",
        "geoLocation": {
            "longitude": 126.969867309367,
            "latitude": 37.5848123543319
        },
        "address": "서울시 종로구 자하문로 99-3 파크사이드빌 304호\t",
        "birth": "2000-04-17"
    },
    {
        "nickname": "raykim",
        "geoLocation": {
            "longitude": "127.047527",
            "latitude": "37.471448"
        },
        "address": "서울 서초구 동산로16길 22\t402호",
        "birth": "1981-03-28"
    },
    {
        "nickname": "yoo.sh",
        "geoLocation": {
            "longitude": 127.110907538887,
            "latitude": 37.5028288714781
        },
        "address": "서울 송파구 가락로19길 5-1\t한울 101호",
        "birth": "1968-12-05"
    },
    {
        "nickname": "Juls",
        "geoLocation": {
            "longitude": "127.170134458955",
            "latitude": "37.5956491049311"
        },
        "address": "경기 남양주시 다산동 6251\t7601동 1503호",
        "birth": "1991-07-23"
    },
    {
        "nickname": ".",
        "geoLocation": {
            "longitude": "127.039227333959",
            "latitude": "37.5059336955901"
        },
        "address": "강남구 역삼동 663-11",
        "birth": "2000-12-04"
    },
    {
        "nickname": "김효영",
        "geoLocation": {
            "longitude": "126.997667728847",
            "latitude": "37.5625353854314"
        },
        "address": "서울 중구 충무로4가 306\tB동 2501호",
        "birth": "1990-03-12"
    },
    {
        "nickname": "신민서",
        "geoLocation": {
            "longitude": "127.114275217292",
            "latitude": "37.5101548841336"
        },
        "address": "서울 송파구 방이동 113-17\t",
        "birth": "2000-07-23"
    },
    {
        "nickname": "윤댕",
        "geoLocation": {
            "longitude": "127.06845934364",
            "latitude": "37.2027888943124"
        },
        "address": "경기 화성시 반송동 98\t",
        "birth": "1982-07-26"
    },
    {
        "nickname": "진리앤제롬",
        "geoLocation": {
            "longitude": "127.033729274482",
            "latitude": "37.5024166067619"
        },
        "address": "서울 강남구 역삼동 639-6\t",
        "birth": "1988-09-30"
    }
]

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const TestPage = ({}) => {
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const location = useLocation();
    const navigate = useNavigate();
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : {};
    const mapRef = useRef();



    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/bookmarks/test', {
            payload: testData,
        }).then((res) => {
            if (res.data) {
                console.log(res.data);
                initMap(res.data);
            }
        });
    }, []);


    const initMap = (data) => {
        console.log('INIT START');
        if (!mapRef.current || !window?.kakao) {
            return;
        }
        console.log('INIT REAL START');
        const kakao = window.kakao;
        const mapContainer = mapRef.current;
        let defaultLatlng = new kakao.maps.LatLng(37.4923585043421, 127.030707488212);

        if (parsed?.party && data[Number(parsed.party)]) {
            let p = data[Number(parsed.party)];
            defaultLatlng = new kakao.maps.LatLng(p[0].geoLocation.latitude, p[0].geoLocation.longitude);
        }

        const mapOption = {
            center: defaultLatlng,
            level: 7,
        };


        let map = new kakao.maps.Map(mapContainer, mapOption);

        for (let i in data) {
            let p = data[i];
            for (let member of p) {
                console.log(parsed?.party)
                if (parsed?.party) {
                    if (i == parsed.party) {
                        let custom = new kakao.maps.CustomOverlay({
                            map: map,
                            zIndex: 2,
                            position: new kakao.maps.LatLng(member.geoLocation.latitude, member.geoLocation.longitude),
                            content: '<a href="/test"><div class="customoverlay">' +
                                `${i} - ${member.birth}` +
                                '</div></a>',
                        });
                    }
                } else {
                    let custom = new kakao.maps.CustomOverlay({
                        map: map,
                        zIndex: 2,
                        position: new kakao.maps.LatLng(member.geoLocation.latitude, member.geoLocation.longitude),
                        content: `<a href="/test?party=${i}"><div class="customoverlay">` +
                            `${i} - ${member.birth}` +
                            '</div></a>',
                    });
                }
            }
        }
        var zoomControl = new kakao.maps.ZoomControl();
        map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

        console.log(map);
    }

    return (
        <Container>
            <div style={{width: '100%', height: '100%'}} className="map" ref={mapRef}/>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #141414;
  color: #fff;
  height: 100vh;
  
  .customoverlay {
    border: 2px solid black;
    padding: 2px 5px;
    color: white;
    background: green;
  }
`;

const Header = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const PlansContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PlanCard = styled.div`
  flex-grow: 1;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  margin: 10px;

  &:hover {
    transform: scale(1.05);
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

const PlanTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const PlanPrice = styled.p`
  font-size: 1.25rem;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PlanNotice = styled.p`
  font-size: 1rem;
  color: #ff0000;
  margin-top: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 0.875rem;
  }
`;