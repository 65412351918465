import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {filter, find, isEmpty, sortBy} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {genderToString} from "../../toolbox/format";
import moment from "moment-timezone";
import {authAction} from "../../redux/authReducer";
import {userAction} from "../../redux/userReducer";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {parseJwt} from "../../toolbox/jwt";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {GET_PARTY_DETAIL, GET_ADMIN_PARTY, GET_RESTAURANTS} from "../../query/partyQuery";
import {flatEntity} from "../../toolbox/query";
import {getUpcomingMeet, getNextMeetTimes} from "../../toolbox/time";
import {UserProfile} from "../components/UserProfile";
import {Party, PartyAdmin, PartyReq} from "../components/Party";


// const RESTAURANTS = ["라브리크 서울", "아올 강남", "띠엘로", "중식당표량", "후추포인트 강남"]

export const PartyAdminPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [startDate, setStartDate] = useState(null);
    const [parties, setParties] = useState(null);

    const {data: queryData} = useQuery(GET_ADMIN_PARTY, {
        fetchPolicy: "cache-first",
        skip: startDate === null,
        variables: {startDate: startDate},
    });

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location, {replace: true}));
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (queryData?.parties?.data) {
            const payload = flatEntity(queryData.parties.data);
            setParties(sortBy(payload, (p) => p.meetedAt).reverse());
        }
    }, [queryData]);

    useEffect(() => {
        setStartDate(moment.tz('Asia/Seoul').subtract(6, 'month').toISOString());
    }, []);

    const calcLeft = (meetedAt) => {
        const meet = moment.tz(meetedAt, 'Asia/Seoul');
        meet.endOf('day');
        const now = moment.tz('Asia/Seoul');
        if (now < meet) {
            let diff = meet.diff(now);
            let duration = moment.duration(diff);
            return Math.floor(duration.asDays());
        }
        return 0;
    }

    console.log(parties);
    return (
        <Container>
            <MeetingsSection>
                {parties && parties.map(p => <>
                    <PartyAdmin p={p} />
                </>)}
            </MeetingsSection>
        </Container>
    );
};

const Line = styled.div`
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
`
// Styled-Components를 사용하여 스타일 정의
const Container = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  color: #333;
  min-height: 100vh;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background-color: ${({ isDisable, isActive }) => (isDisable? '#cccccc': isActive ? '#007bff' : '#fff')};
  color: ${({ isDisable, isActive }) => (isDisable? '#999999': isActive ? '#fff' : '#000')};
  border: ${({ isDisable }) => (isDisable? '#999999': '1px solid #007bff')};
  cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ isDisable, isActive }) => (isDisable? '': isActive ? '#0056b3' : '#e0e0e0')};
  }
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
  
  > :last-child {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
      background-color: #0056b3;
    }

    @media only screen and (min-width: 768px) {
      margin-top: 0;
      margin-left: auto;
    }
  }
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const Bio = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 8px;
  p {
    margin: 0;
    white-space: pre-wrap; /* 여러 줄의 텍스트를 지원하기 위해 추가 */
  }
`;

const ProfileInfo = styled.div`
  text-align: center;

  @media only screen and (min-width: 768px) {
    text-align: left;
  }
`;

const Nickname = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Gender = styled.p`
  font-size: 1rem;
`;

const PhoneNumber = styled.p`
  font-size: 1rem;
`;

const SNSLink = styled.p`
  font-size: 1rem;
`;

const MembershipInfo = styled.p`
  font-size: 1rem;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  > .purchase-button {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    border: none;
    padding: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

const MeetingsSection = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 440px;



  > .purchase-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #218838;
    }
  }
`;

const LogoutButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; 

  &:hover {
    background-color: #c82333;
  }
`;

const MeetingTitle = styled.h2`
  margin-top: 24px;
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const MeetingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MeetingItem = styled.div`
  font-size: 1rem;
  padding: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #007bff;
  }

  //&:last-child {
  //  border-bottom: none;
  //}
`;
