import {loadTossPayments} from "@tosspayments/payment-sdk";
import {TOSS_CLIENT_KEY} from "../../index";
import { v4 } from 'uuid';
import {getProduct} from "../../toolbox/product";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {isEmpty} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {useLocation, useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";

export const PurchasePage = ({}) => {
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            alert("로그인이 필요합니다.")
            const to = getLoginLink(location);
            navigate(to, {replace: true});
        }
    }, [tokenInfo]);

    async function handlePayment(productId) {
        const tossPayments = await loadTossPayments(TOSS_CLIENT_KEY);
        const product = getProduct(productId);

        if (!product) {
            alert('준비중인 상품입니다.');
            return null;
        }

        const orderId = `${tokenInfo.id}_${product.id}_${v4()}`;
        console.log(orderId);
        await tossPayments.requestPayment('카드', {
            orderId: orderId,
            amount: product.price,
            orderName: product.name,
            successUrl: window.location.origin + '/payment_success',
            failUrl: window.location.origin + '/payment_fail',
        }).then((value) => {
            console.log(value);
        }).catch((reason => {
            alert(reason);
        }));
    }

    return (
        <Container>
            <Header>원하는 멤버십을 선택하세요.</Header>
            <p>우연한 디너모임에 참석하는 것에 대한 비용이며, 식대는 각자 주문한 메뉴를 현장에서 더치페이로 결제합니다.</p>
            <p style={{marginTop: '-8px'}}>(메뉴 당 2만원 내외, 주류 포함 시 증가할 수 있음.)</p>
            <p>디너모임은 매주 목요일 오후 7시 강남역 인근 식당에서 4~6인으로 진행됩니다.</p>
            <PlansContainer>
                <PlanCard onClick={() => handlePayment('ADD-FORK-BETA')}>
                    <PlanTitle>우연한 디너 1회 참석권</PlanTitle>
                    <PlanPrice line={true}>40,000원</PlanPrice>
                    <PlanPrice>20,000원</PlanPrice>
                </PlanCard>
                <PlanCard onClick={() => handlePayment('ADD-3FORK-BETA')}>
                    <PlanTitle>우연한 디너 3회 참석권</PlanTitle>
                    <PlanPrice line={true}>75,000원</PlanPrice>
                    <PlanPrice>35,000원</PlanPrice>
                </PlanCard>
                <PlanCard onClick={() => handlePayment('ADD-12FORK-BETA')}>
                    <PlanTitle>우연한 디너 12회 참석권</PlanTitle>
                    <PlanPrice line={true}>240,000원</PlanPrice>
                    <PlanPrice>120,000원</PlanPrice>
                </PlanCard>
                {/*<PlanCard onClick={() => handlePayment('ADD-3FORK-NO')}>*/}
                {/*    <PlanTitle>우연한 디너 3회권</PlanTitle>*/}
                {/*    <PlanPrice>75,000원</PlanPrice>*/}
                {/*    <PlanNotice>Coming Soon</PlanNotice>*/}
                {/*</PlanCard>*/}
                {/*<PlanCard onClick={() => handlePayment('ADD-12FORK-NO')}>*/}
                {/*    <PlanTitle>우연한 디너 12회권</PlanTitle>*/}
                {/*    <PlanPrice>240,000원</PlanPrice>*/}
                {/*    <PlanNotice>Coming Soon</PlanNotice>*/}
                {/*</PlanCard>*/}
            </PlansContainer>
        </Container>
    );
}

const Container = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  color: ${({ theme }) => theme.colors.textColor};
  height: 100vh;
  gap: 12px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const PlansContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PlanCard = styled.div`
  max-width: 400px;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  margin: 10px;

  &:hover {
    transform: scale(1.05);
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

const PlanTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const PlanPrice = styled.p`
  font-size: 1.25rem;

  ${(props) => props.line && css`
    text-decoration: line-through;
  `}
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PlanNotice = styled.p`
  font-size: 1rem;
  color: #ff0000;
  margin-top: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 0.875rem;
  }
`;
