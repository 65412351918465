import {SERVER_ADDRESS} from "../index";
import axios from "axios";
import {store} from "../redux/store";
import {loadingAction} from "../redux/loadingReducer";

export const openKakaoChat = (action, label) => {
    // if (label) {
    //     ReactGA.event({
    //         category: "DooriveOpenChat",
    //         action: action,
    //         label: label, // optional
    //     });
    // } else {
    //     ReactGA.event({
    //         category: "DooriveOpenChat",
    //         action: action,
    //     });
    // }
    window.Kakao.Channel.chat({channelPublicId: '_VximYG'});
    window.fbq('track', 'Contact');
}

export const kakaoRestLogin = (code, callback) => {
    store.dispatch(loadingAction.loading(true));
    const url = SERVER_ADDRESS + '/api/auth/kakao/callback?code=' + code;
    axios.get(url)
        .then(res => {
            const {jwt, user} = res.data;
            store.dispatch(loadingAction.loading(false));
            callback(jwt, user);
            // requestUserInfo(callback);
        })
        .catch((reason => {
            store.dispatch(loadingAction.loading(false));
            alert(reason);
            console.log(reason);
        }));
}

export const getLoginLink = (location) => {
    if (location.pathname.startsWith("/login")) {
        return location.pathname + location.search + location.hash;
    }
    return "/login?prev=" + location.pathname + "?" + encodeURIComponent(location.search.substr(1)) + encodeURIComponent(location.hash);
}