import {loadingAction} from "../../redux/loadingReducer";
import {SERVER_ADDRESS} from "../../index";
import axios from "axios";
import {cloneDeep, filter} from "lodash";
import styled from "styled-components";
import {_FormComponent, HelpBtn} from "./FormComponent";
import {useDispatch} from "react-redux";
import {useRef} from "react";

export const PhotoComponent = ({id, isSingle, title, form, onChange, description, ...props}) => {
    const imageRef = useRef();
    const multiImageRef = useRef();
    const dispatch = useDispatch();

    const handleImage = (e) => {
        const data = new FormData();
        for (let file of e.target.files) {
            data.append('files', file);
        }
        e.target.value = null;
        dispatch(loadingAction.loading(true));
        axios.post(SERVER_ADDRESS + '/api/upload', data, {
            onUploadProgress: progressEvent => {
            },
        }).then(async (res) => {
            if (isSingle) {
                onChange({id, value: res.data[0] ?? null});
            } else {
                const result = form[id].concat(res.data);
                onChange({id, value: result});
            }
            dispatch(loadingAction.loading(false));
        }).catch((e) => {
            alert(e);
        }).finally(() => {
        });
    }

    const deletePicture = (picture) => {
        let pictures = cloneDeep(form[id]);
        pictures = filter(pictures, (p) => p.id !== picture.id);
        onChange({id, value: pictures});
    }

    const isPictureExist = () => {
        if (isSingle && form[id]) {
            return true;
        } else if (!isSingle && form[id] && form[id].length > 0) {
            return true;
        }
        return false;
    }

    return <_PhotoComponent {...props}>
        {title &&
            <div className="title">{title}
                {description && <HelpBtn contents={description} />}
            </div>}
        <div className="content" onClick={() => {
            if (isSingle) {
                imageRef.current.click();
            } else {
                multiImageRef.current.click();
            }
        }}>
            {!isPictureExist() &&
                <div className="photo-input">
                    + 사진을 첨부해주세요
                </div>}
            {isPictureExist() &&
                <div className="attached">
                    {isSingle && form[id] && <div className="image">
                        <div style={{width: '200px', height: '200px'}}>
                            <_CircleImage src={form[id].url ?? "/defaultProfile2.png"} />
                        </div>
                    </div>}
                    {!isSingle && form[id].map((p) =>
                        <div key={p.id} className="image --multi">
                            <div className="close" onClick={(e) => {
                                deletePicture(p);
                                e.stopPropagation();
                            }}>
                                <img src="/closeIcon.svg"/>
                            </div>
                            <div style={{width: '200px', height: '200px'}}>
                                <_CircleImage src={form[id].url ?? "/defaultProfile2.png"} />
                            </div>
                        </div>)}
                </div>
            }
        </div>
        <input style={{display: 'none'}} type="file" accept="image/jpeg, image/png" onChange={handleImage} ref={imageRef}/>
        <input style={{display: 'none'}} type="file" accept="image/jpeg, image/png" onChange={handleImage} ref={multiImageRef} multiple={true}/>
    </_PhotoComponent>
}

const _PhotoComponent = styled(_FormComponent)`
  > .content {
    user-select: none;
    cursor: pointer;
    text-align: center;
    color: var(--gray-500, #C9C9C9);
  }

  .attached {
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;

    .--multi {
      cursor: pointer;
    }
    .image {
      position: relative;
      border-radius: 8px;
      border: 1px solid var(--gray-scale-gray-200, #F5F5F5);
      background: var(--gray-scale-gray-100, #FAFAFA);
      
      > .close {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        top: -12px;
        right: -12px;
      }
      
      > .selected {
        z-index: 1;
        box-sizing: border-box;
        position: absolute;
        padding: 3px;
        left: 0;
        top: 0;
        font-size: 12px;
        border: 1px solid black;
        color: black;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
      }
    }
  }
`

const _CircleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #AAAAAA;
`;

