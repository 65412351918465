import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {GET_PARTY_DETAIL} from "../../query/partyQuery";
import {useMutation, useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import moment from "moment-timezone";
import {loadingAction} from "../../redux/loadingReducer";
import {genderToString, 비속어체크} from "../../toolbox/format";
import {useForm} from "../../toolbox/hook";
import {CREATE_COMMENT, DELETE_COMMENT} from "../../query/mutationQuery";
import {find, get, isEmpty} from "lodash";
import {GET_BOOKMARKS} from "../../query/userQuery";
import {getLoginLink} from "../../toolbox/kakao";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {UserProfile} from "../components/UserProfile";

const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  //font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px 12px;
  color: #333;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: keep-all;
  gap: 18px;
  //height: 100vh;

  .meeting-details {
    background: white;
    padding: 20px 20px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }

  .meeting-info {
    text-align: center;
    margin-bottom: 20px;
  }

  .meeting-info h2 {
    font-size: 1.5em;
    color: #4a4a4a;
  }

  .meeting-info p {
    font-size: 1em;
  }

  .attendees {
    text-align: center;
  }

  .attendees h3 {
    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .attendee-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .attendee {
    background: #f3f3f3;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 120px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .attendee-list a {
    display: flex;
  }

  .attendee img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .attendee p {
    margin: 0;
    font-size: 1em;
    color: #333;
    white-space: pre-wrap;
    word-break: break-all;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .recommend {
  }

  .recommend-title {
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }
`
export const BookmarkMorePage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    // const [seenBookmarks, setSeenBookmarks] = useState(null);
    // const [notSeenBookmarks, setNotSeenBookmarks] = useState(null);
    const [recommends, setRecommends] = useState(null);

    // const {data: queryData, refetch} = useQuery(GET_BOOKMARKS, {
    //     fetchPolicy: "cache-first",
    //     skip: isEmpty(tokenInfo),
    //     variables: {id: tokenInfo?.id},
    // });

    // useEffect(() => {
    //     if (queryData?.usersPermissionsUser?.data) {
    //         const payload = flatEntity(queryData.usersPermissionsUser.data);
    //         let bookmark = get(payload, "bookmarkSends");
    //         let seen = get(payload, "partyMembers");
    //         let seenUser = [];
    //
    //         for (let lastParty of seen) {
    //             for (let lastPartyMember of lastParty.party.members) {
    //                 seenUser.push(lastPartyMember.user.id);
    //             }
    //         }
    //
    //         for (let b of bookmark) {
    //             b.seen = find(seenUser, u => u === b.id) !== undefined;
    //         }
    //
    //         setSeenBookmarks(bookmark.filter(b => b.seen));
    //         setNotSeenBookmarks(bookmark.filter(b => !b.seen));
    //         // dispatch(loadingAction.loading(false));
    //     } else {
    //         // dispatch(loadingAction.loading(true));
    //     }
    // }, [queryData]);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/party-members/getRecommend', {
        }).then((res) => {
            if (res.data) {
                setRecommends(res.data);
            }
        });
    }, []);

    // if (seenBookmarks === null) {
    //     return <div>
    //
    //     </div>
    // }

    return <PageWrap>
        <div className="meeting-details">
            <div className="recommend-title">
                <h3>프로필 둘러보기</h3>
            </div>
            <div className="attendees">
                <div className="attendee-list">
                    {recommends && recommends.map(user =>
                        <UserProfile u={user} />
                    )}
                    {(recommends && recommends.length === 0) && <div>
                        추천 멤버가 없습니다. 다음에 다시 시도해주세요.
                    </div>}
                </div>
            </div>
        </div>
    </PageWrap>
}
