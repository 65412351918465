import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {GET_PARTY_DETAIL} from "../../query/partyQuery";
import {useMutation, useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import moment from "moment-timezone";
import {loadingAction} from "../../redux/loadingReducer";
import {genderToString, 비속어체크} from "../../toolbox/format";
import {useForm} from "../../toolbox/hook";
import {CREATE_COMMENT, DELETE_COMMENT} from "../../query/mutationQuery";
import {find, get, isEmpty} from "lodash";
import {GET_BOOKMARKS} from "../../query/userQuery";
import {getLoginLink} from "../../toolbox/kakao";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {UserProfile} from "../components/UserProfile";
import {RecommendUsers} from "../components/RecommendUsers";

const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  //font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px 12px;
  color: #333;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: keep-all;
  gap: 18px;
  //height: 100vh;

  .meeting-details {
    background: white;
    padding: 20px 20px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }

  .meeting-info {
    text-align: center;
    margin-bottom: 20px;
  }

  .meeting-info h2 {
    font-size: 1.5em;
    color: #4a4a4a;
  }

  .meeting-info p {
    font-size: 1em;
  }

  .attendees {
    text-align: center;
  }

  .attendees h3 {
    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .attendee-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .attendee {
    background: #f3f3f3;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 120px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .attendee img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .attendee p {
    margin: 0;
    font-size: 1em;
    color: #333;
    white-space: pre-wrap;
    word-break: break-all;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .recommend {
  }

  .recommend-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;


    :first-child {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    :nth-child(2) {
      margin-left: auto;
    }
  }

  .recommend-title a {
    text-decoration: underline;
  }
`
export const BookmarkPage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [seenBookmarks, setSeenBookmarks] = useState(null);
    const [notSeenBookmarks, setNotSeenBookmarks] = useState(null);
    const [recommends, setRecommends] = useState(null);

    const {data: queryData, refetch} = useQuery(GET_BOOKMARKS, {
        fetchPolicy: "cache-first",
        skip: isEmpty(tokenInfo),
        variables: {id: tokenInfo.id},
    });

    useEffect(() => {
        if (userInfo) {
            // const payload = flatEntity(queryData.usersPermissionsUser.data);
            let bookmark = userInfo.bookmarkSends;
            let seen = userInfo.partyMembers;
            let seenUser = [];

            for (let lastParty of seen) {
                if (!lastParty.party.building) {
                    for (let lastPartyMember of lastParty.party.members) {
                        seenUser.push(lastPartyMember.user.id);
                    }
                }
            }
            setSeenBookmarks(bookmark.filter(b => find(seenUser, u => u === b.receiver.id) !== undefined));
            setNotSeenBookmarks(bookmark.filter(b => find(seenUser, u => u === b.receiver.id) === undefined));
            // dispatch(loadingAction.loading(false));
        } else {
            // dispatch(loadingAction.loading(true));
        }
    }, [userInfo]);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/party-members/getRecommend', {
        }).then((res) => {
            if (res.data) {
                if (res.data.length > 3) {
                    res.data.length = 3;
                }
                setRecommends(res.data);
            }
        });
    }, []);

    if (seenBookmarks === null) {
        return <div>

        </div>
    }

    return <PageWrap>
        <div className="meeting-details">
            <div className="meeting-info">
                <h1>내가 북마크한 프로필</h1>
                {/*<p><strong>모임 시간:</strong> {moment.tz(partyDetail.meetedAt, 'Asia/Seoul').format('M월 D일 HH:mm')} {timeLeft? `(${timeLeft} 남음)`: ''}</p>*/}
                {/*<p><strong>모임 장소:</strong> {partyDetail.address} {partyDetail.addressDetail} <a href={"https://map.naver.com/p/search/" + encodeURIComponent(partyDetail.address)} rel="noopener noreferrer" target="_blank">[네이버 지도]</a></p>*/}
            </div>
            <div className="attendees">
                <div style={{marginBottom: '32px'}}>
                    {/*<h3>아직 안 만난 북마크 유저</h3>*/}
                    <div className="attendee-list">
                        {(notSeenBookmarks.length === 0 && seenBookmarks.length === 0) && <div>
                            <p>북마크를 추가해주세요</p>
                            {/*{seenBookmarks.length === 0? <p>북마크를 추가해주세요</p>: <p>북마크를 모두 만났어요<br/>북마크를 추가해주세요</p>}*/}
                        </div>}
                        {notSeenBookmarks.map(b =>
                            <UserProfile u={b.receiver} />
                        )}
                    </div>
                </div>
                {seenBookmarks.length ?
                    <div style={{marginBottom: '32px'}}>
                        <h3>이미 식사를 같이 했어요</h3>
                        <div className="attendee-list">
                            {seenBookmarks.map(b =>
                                <UserProfile u={b.receiver} />
                            )}
                        </div>
                    </div>: null}
            </div>
        </div>
        <RecommendUsers style={{maxWidth: '579px', width: '98%'}} />
    </PageWrap>
}
