import {createSlice} from "@reduxjs/toolkit";
import {parseJwt} from "../toolbox/jwt";

const initialState = {
    token: undefined,
    tokenInfo: undefined,
    refresh: undefined,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        update: (state, action) => {
            state.token = action.payload;
            state.tokenInfo = parseJwt(action.payload);
            state.refresh = false;
            if (state.tokenInfo.id) {
                state.tokenInfo.id = String(state.tokenInfo.id);
            }
            return state;
        },
        logout: (state, action) => {
            return initialState;
        },
    }
})

export let authAction = authSlice.actions;
export let authReducer = authSlice.reducer;

