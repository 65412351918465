import {gql} from "@apollo/client";


export const GET_ADMIN_PARTY = gql`
query getAdminParties($startDate: DateTime!) {
  parties(filters: {meetedAt: {gt: $startDate}}) {
    data {
      id
      attributes {
        meetedAt
        restaurantName
        building
        partyType
        members {
          data {
            id
            attributes {
              isHost
              review
              createdAt
              toss {
                data {
                  id
                  attributes {
                    status
                  }
                }
              }
              user {
                data {
                  id
                  attributes {
                    nickname
                    birth
                    phone
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`

export const GET_QUESTION = gql`
query getArticle {
  article(id: 1) {
    data {
      id
      attributes {
        title
        text
      }
    }
  }
}`

export const GET_PUBLIC_PARTIES = gql`
query getParties($startDate: DateTime!) {
  parties(filters: {partyType: "PUBLIC", meetedAt: {gt: $startDate}}) {
    data {
      id
      attributes {
        meetedAt
        address
        addressDetail
        restaurantName
        placeLink
        memberDetail
        comments {
          data {
            id
            attributes {
              body
              createdAt
              user {
                data {
                  id
                }
              }
            }
          }
        }
        members {
          data {
            id
            attributes {
              isHost
              review
              user {
                data {
                  id
                  attributes {
                    nickname
                    gender
                    profileImage {
                      data {
                        id
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const GET_RESTAURANTS = gql`
query getRestaurants {
  restaurants {
    data {
      id
      attributes {
        placeLink
        thumbnail {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}`

export const GET_RESTAURANT = gql`
query getRestaurant($id:ID!) {
  restaurant(id: $id) {
    data {
      id
      attributes {
        name
        article
      }
    }
  }
}`

export const GET_PARTY_DETAIL = gql`
query getPartyDetail($id:ID!) {
  party(id: $id) {
    data {
      id
      attributes {
        meetedAt
        address
        addressDetail
        restaurantName
        placeLink
        memberDetail
        partyType
        chat {
          data {
            id
            attributes {
              messages
            }
          }
        }
        comments {
          data {
            id
            attributes {
              body
              createdAt
              party {
                data {
                  id
                }
              }
              user {
                data {
                  id
                }
              }
            }
          }
        }
        members {
          data {
            id
            attributes {
              isHost
              review
              user {
                data {
                  id
                  attributes {
                    nickname
                    gender
                    profileImage {
                      data {
                        id
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PARTY_MEMBER = gql`
query getPartyMember($id:ID!) {
  partyMember(id: $id) {
    data {
      id
      attributes {
        user {
          data {
            id
            attributes {
              nickname
              profile
              gender
              profileImage {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              partyMembers {
                data {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
