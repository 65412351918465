import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {GET_PARTY_DETAIL} from "../../query/partyQuery";
import {useMutation, useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import moment from "moment-timezone";
import {loadingAction} from "../../redux/loadingReducer";
import {genderToString, 비속어체크} from "../../toolbox/format";
import {useForm} from "../../toolbox/hook";
import {CREATE_COMMENT, DELETE_COMMENT} from "../../query/mutationQuery";
import {isEmpty} from "lodash";
import {Comment} from "../components/Comment";
import {UserProfile} from "../components/UserProfile";
import {PositiveButton} from "../my/PartyReqPage";
import {getLoginLink} from "../../toolbox/kakao";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {Dialog} from "@mui/material";
import {calcFixTime} from "../../toolbox/time";

const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  //font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px 12px;
  color: #333;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  gap: 18px;
  //height: 100vh;

  .meeting-details {
    background: white;
    padding: 20px 20px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }

  .meeting-info {
    text-align: center;
    margin-bottom: 20px;
  }

  .meeting-info h2 {
    font-size: 1.5em;
    color: #4a4a4a;
  }

  .meeting-info p {
    font-size: 1em;
  }

  .attendees {
    text-align: center;
  }

  .attendees h3 {
    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .attendee-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: center;
    //gap: 20px;
  }

  .guide {
    margin-top: 40px;
    display: flex;
    color: blue;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }
  //.attendee {
  //  background: #f3f3f3;
  //  padding: 10px;
  //  border-radius: 10px;
  //  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  //  text-align: center;
  //  width: 120px;
  //  position: relative;
  //  display: flex;
  //  flex-direction: column;
  //  flex-grow: 1;
  //}
  //
  //.attendee-list a {
  //  display: flex;
  //}
  //
  //.attendee img {
  //  border-radius: 50%;
  //  width: 100px;
  //  height: 100px;
  //  object-fit: cover;
  //  margin-bottom: 10px;
  //}
  //
  //.attendee p {
  //  margin: 0;
  //  font-size: 1em;
  //  color: #333;
  //  white-space: pre-wrap;
  //  word-break: break-all;
  //  flex-grow: 1;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //}
  //
  //.host {
  //  border: 2px solid #3498db;
  //}
  //
  //.host-label {
  //  position: absolute;
  //  top: 5px;
  //  right: 5px;
  //  background: #3498db;
  //  color: white;
  //  padding: 2px 5px;
  //  border-radius: 3px;
  //  font-size: 0.8em;
  //}

  .comments-section {
    text-align: left;

    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }
  
  .comments-list {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .comment-form {
    display: flex;
    flex-direction: column;
  }

  .comment-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .comment-submit {
    padding: 10px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .comment-submit:hover {
    background: #2980b9;
  }
`
export const PartyModifyPage = () => {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const partyId = params?.id;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [partyDetail, setPartyDetail] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const [openInvite, setOpenInvite] = useState(false);

    const {data: queryData, refetch} = useQuery(GET_PARTY_DETAIL, {
        fetchPolicy: "cache-first",
        skip: partyId === undefined,
        variables: {id: partyId},
    });

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (queryData?.party?.data) {
            const payload = flatEntity(queryData.party.data);
            setPartyDetail(payload);
        }
    }, [queryData]);

    useEffect(() => {
        const timer = setInterval(() => {
            renderTimeLeft();
        }, 1000);
        renderTimeLeft();
    }, [partyDetail]);

    const renderTimeLeft = () => {
        if (partyDetail) {
            const fix = calcFixTime(moment.tz(partyDetail.meetedAt, 'Asia/Seoul'));
            const now = moment.tz('Asia/Seoul');
            if (now < fix) {
                let diff = fix.diff(now);
                let duration = moment.duration(diff);

                const days = Math.floor(duration.asDays());
                const hours = duration.hours();
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                const formattedDays = days ? `${days}일 ` : '';
                const formattedHours = (days || hours) ? `${hours}시간 ` : '';
                const formattedMinutes = (days || hours || minutes) ? `${minutes}분 ` : '';
                const formattedSeconds = (days || hours || minutes || seconds) ? `${seconds}초 ` : '';

                const left = `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`.trim();
                setTimeLeft(left);
            } else {
                setTimeLeft(null);
            }
        }
    }

    const handleKick = (m) => {
        dispatch(loadingAction.loading(true));
        axios.post(SERVER_ADDRESS + '/api/parties/customKick', {
            partyId: partyDetail.id,
            memberId: m.id,
        }).then(res => {
            handleChange();
        });
    }

    const handleChange = () => {
        refetch().then(r => {
            dispatch(loadingAction.loading(false));
        });
    }

    const isHost = (member) => {
        return String(member?.user?.id) === String(partyDetail?.memberDetail?.creatorId);
    }
    if (!partyDetail) {
        return <div>

        </div>
    }

    return <>
        <PageWrap>
            <div className="meeting-details">
                <div className="meeting-info">
                    <h2>모임 정보</h2>
                    <p><strong>모임 확정:</strong> {calcFixTime(moment.tz(partyDetail.meetedAt, 'Asia/Seoul')).format('M월 D일 HH:mm')} {timeLeft? `(${timeLeft} 남음)`: ''}</p>
                    <p><strong>모임 시간:</strong> {moment.tz(partyDetail.meetedAt, 'Asia/Seoul').format('M월 D일 HH:mm')}</p>
                    <p><strong>모임 장소:</strong> 추후 확정</p>
                </div>
                <div className="attendees">
                    <h3>참석자</h3>
                    <div className="attendee-list">
                        {partyDetail.members.map(member =>
                            <UserProfile key={member.id} u={member.user} isHost={isHost(member)} showGender={true} showAge={true}>
                                {!isHost(member) && <PositiveButton onClick={() => handleKick(member)} style={{marginTop: '4px'}}>
                                    내 모임에서 제외하기
                                </PositiveButton>}
                            </UserProfile>
                        )}
                    </div>
                </div>
                <div className="guide">
                    <PositiveButton onClick={() => setOpenInvite(true)}>참석자 추가하기</PositiveButton>
                    <a href="https://copper-abrosaurus-467.notion.site/e54eb17c1906431793996892aa73089d?pvs=4" rel="noopener noreferrer" target="_blank">
                        <PositiveButton>디너모임 가이드</PositiveButton>
                    </a>
                </div>
            </div>
        </PageWrap>
        <MemberInviteDialog party={partyDetail} onChange={handleChange} open={openInvite} onClose={() => setOpenInvite(false)} />
    </>
}


const MemberInviteDialog = ({party, open, onClose, onChange}) => {
    const dispatch = useDispatch();
    const [inviteMembers, setInviteMembers] = useState(null);

    useEffect(() => {
        refetch();
    }, [party]);

    const refetch = () => {
        axios.post(SERVER_ADDRESS + '/api/parties/customInviteList', {
            partyId: party.id,
        }).then(res => {
            setInviteMembers(res.data);
        });
    }

    const handleInvite = (u) => {
        dispatch(loadingAction.loading(true));
        axios.post(SERVER_ADDRESS + '/api/parties/customInvite', {
            partyId: party.id,
            memberId: u.memberId,
        }).then(res => {
            onChange();
        });
    }

    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DialogContainer>
            <h3>멤버 추가하기</h3>
            {inviteMembers && inviteMembers.length === 0 && <div style={{textAlign:'center'}}>아직 초대 가능한 멤버가 없습니다.</div>}
            {inviteMembers && inviteMembers.map((u) => {
                return <UserProfile key={u.memberId} u={u} showGender={true} showAge={true}>
                    <PositiveButton onClick={() => handleInvite(u)} style={{marginTop: '4px'}}>내 모임에 추가하기</PositiveButton>
                </UserProfile>
            })}
        </DialogContainer>
    </Dialog>
}

const DialogContainer = styled.div`
  word-break: keep-all;
  font-family: "Elice Digital Baeum", sans-serif;
  padding: 16px;
  line-height: 160%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  white-space: pre-wrap;
  h3 {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
`