import styled from "styled-components";
import {useEffect, useState} from "react";
import moment from "moment-timezone";
import {getNextFixTime, getUpcomingMeet} from "../../toolbox/time";

export const Timer = ({to}) => {
    const [day, setDay] = useState(null);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);
    const [second, setSecond] = useState(null);

    useEffect(() => {
        const timer = setInterval(() => {
            renderTimeLeft();
        }, 1000);
        renderTimeLeft();
    }, []);

    const renderTimeLeft = () => {
        let now = moment.tz('Asia/Seoul');
        let to = getNextFixTime();

        let diff = to.diff(now);
        let duration = moment.duration(diff);

        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        setDay(days);
        setHour(hours);
        setMinute(minutes)
        setSecond(seconds);
    }

    return <_Timer>
        <div>
            <div className="number">{day}</div>
            <div>일</div>
        </div>
        <div>
            <div className="number">{hour}</div>
            <div>시간</div>
        </div>
        <div>
            <div className="number">{minute}</div>
            <div>분</div>
        </div>
        <div>
            <div className="number">{second}</div>
            <div>초</div>
        </div>
    </_Timer>
}

const _Timer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: auto;
  flex-shrink: 1;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.textColor};
  //color: rgb(199, 199, 204);

  padding: 10px 5% 0;
  margin-bottom: 16px;
  width: 100%;
  
  @media only screen and (min-width: 768px) {
    width: 500px;
    padding: 10px 0 0;
  }

  > :first-child {
    //border-left: 1px solid rgba(255, 153, 153, 0.3);
    border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
  }
  > :last-child {
    //border: 1px solid rgba(255, 153, 153, 0.3);
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  > div {
    .number {
      color: ${({ theme }) => theme.colors.textColor};
      font-size: 1.6em;
      font-weight: 700;
      //color: rgb(235, 93, 64);
    }
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
    //border-top: 1px solid rgba(255, 153, 153, 0.3);
    //border-bottom: 1px solid rgba(255, 153, 153, 0.3);
    //border-left: 1px solid rgba(255, 153, 153, 0.3);
    border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
  }
`
