import React from "react";
import {useDispatch} from "react-redux";
import qs from 'query-string';
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";

const PageWrap = styled.div`
  padding: 60px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
`
const Login = styled.div`
    cursor: pointer;
`

const Agreement = styled.div`
  padding: 20px;
  > h3 {
    margin-bottom: 20px;
  }
  > textarea {
    width: 100%;
  }
  > .button-wrap {
    cursor: pointer;
    margin-top: 20px;
    padding: 8px;
    > .submit {
      border-radius: 8px;
      height: 30px;
      line-height: 30px;
      background-color: black;
      color: white;
    }
  }
`

export function LoginPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const prev = parsed?.prev ?? '/';

    return (<PageWrap>
        <Login onClick={() => {
            // window.location.href = `https://kauth.kakao.com/oauth/authorize?${qs.stringify({
            //     response_type: 'code',
            //     client_id: 'd0144edc3eac3d86c98a30bc79dc9400',
            //     redirect_uri: `${window.location.origin}/login_callback`,
            //     state: prev,
            // })}`;
            window.location.replace(`https://kauth.kakao.com/oauth/authorize?${qs.stringify({
                response_type: 'code',
                client_id: 'd0144edc3eac3d86c98a30bc79dc9400',
                redirect_uri: `${window.location.origin}/login_callback`,
                state: prev,
            })}`);
        }}>
            <img src="/login/kakao_login_medium_narrow.png"/>
        </Login>
        <br/>
    </PageWrap>);
};
