import styled from "styled-components";
import {ClickAwayListener, Divider, Popper} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {useEffect, useState} from "react";
import {ChatList} from "../page/my/ChatList";

export const NotiPopper = ({open, anchorEl, onClose, chatRooms}) => {
    const hasLoggedIn = useSelector(({auth}) => !isEmpty(auth.tokenInfo));
    const location = useLocation();
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        setOpened(open);
    }, [open])

    useEffect(() => {
        onClose();
    }, [location])

    return <ClickAwayListener onClickAway={() => {
        if (opened) {
            onClose();
        }
    }}>
            <Popper style={{zIndex: '1300', minWidth: '200px', maxWidth: '300px'}} open={open} anchorEl={anchorEl} placement="bottom-end">
            {hasLoggedIn && <>
                <div style={{background: 'white', boxShadow: '0 2px 36px 0 rgba(0,0,0,.1)', borderRadius: '4px'}}>
                    <ChatList chatRooms={chatRooms} max={5}/>
                </div>
                </>}
            </Popper>
        </ClickAwayListener>
}
